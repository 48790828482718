type Props = {
  prolificRedirectUrl: string;
};

export default function TaskCompleteNotice(props: Props) {
  const { prolificRedirectUrl } = props;

  return (
    <>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex md:min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="flex flex-col px-8 py-6 bg-gray-100 rounded-lg shadow-inner space-y-4">
            <h3 className="text-md font-bold text-gray-800">
              🏆 TASK COMPLETED 🏆
            </h3>
            <div className="text-gray-700">
              Thank you for participating in our study. Please click the link
              below to go back to Prolific.
            </div>
            <a
              href={prolificRedirectUrl}
              className="px-4 py-2 inline-flex justify-center w-full text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Go back to Prolific
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
