import { Route, Routes } from "react-router-dom";

import Preview from "./preview";

export const DialogueRoutes = () => {
  return (
    <Routes>
      <Route path="preview/:dialogueId" element={<Preview />}></Route>
    </Routes>
  );
};
