type Props = {
  text: string;
};

export default function SearchResults(props: Props) {
  const { text } = props;
  return (
    <div className="flex items-center justify-end flex-1 my-2">
      <p className="whitespace-pre-line p-2 m-2">{text}</p>
    </div>
  );
}
