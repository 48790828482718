import BotMessage from "src/components/messages/bot-message";
import UserMessage from "src/components/messages/user-message";
import { MessageType } from "src/types/models";

type Props = {
  staticContext: string[];
};

export default function DefaultStaticDialogueContext(props: Props) {
  const { staticContext } = props;
  const isHumanFirst = staticContext.length % 2 !== 0;
  const isBotFirst = !isHumanFirst;
  return (
    <div>
      {staticContext.map((message, index) => {
        const content =
          (index % 2 === 0 && isHumanFirst) ||
          (index % 2 !== 0 && isBotFirst) ? (
            <UserMessage
              message={{
                id: index,
                text: message,
                type: MessageType.USER_MESSAGE,
              }}
            />
          ) : (
            <BotMessage
              message={{
                id: index,
                text: message,
                type: MessageType.BOT_MESSAGE,
              }}
              showReaction={false}
            />
          );
        return (
          <div key={`static-message-${index}`} className="my-4">
            {content}
          </div>
        );
      })}
    </div>
  );
}
