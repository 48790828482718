import { useState } from "react";
import Drawer from "src/features/dialogues/components/simple-drawer";
import { Message } from "src/types/models";
// eslint-disable-next-line import/order
import AceEditor from "react-ace";
// eslint-disable-next-line import/order
import "ace-builds/src-noconflict/mode-json";
// eslint-disable-next-line import/order
import "ace-builds/src-noconflict/theme-github";

export default function MessageLabelDrawer({ message }: { message: Message }) {
  const [showLabels, setShowLabels] = useState<boolean>(false);
  const labels = message.labels || [];
  const labelSummary = summarizeLabels(labels.map((l) => l.data) || []);
  if (labels.length === 0) {
    return <div className="text-right text-gray-600 text-xs">No Labels</div>;
  }
  return (
    <>
      <div className="text-right mb-4">
        <PreviewSumamry summarizedLabels={labelSummary} />
        <button
          className="items-center mx-2 px-3 py-0.5 rounded-lg text-sm font-medium text-indigo-800 bg-indigo-100 border border-transparent hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => setShowLabels(!showLabels)}>
          Labels: {labels.length}
          <br />
          Questions: {Object.keys(labelSummary).length}
        </button>
      </div>
      <Drawer isOpen={showLabels} setIsOpen={setShowLabels}>
        <h2 className="font-extrabold text-center text-gray-900 mt-2">
          Summary
        </h2>
        <table className="table-auto border-collapse border border-gray-200">
          <thead>
            <tr>
              <th className="border border-gray-200 px-4 py-2">Label</th>

              <th className="border border-gray-200 px-4 py-2">Values</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(labelSummary).map((key) => (
              <tr key={key}>
                <td className="border border-gray-200 px-4 py-2">{key}</td>
                <td className="border border-gray-200 px-4 py-2">
                  <pre>{JSON.stringify(labelSummary[key])}</pre>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2 className="font-extrabold text-center">Raw JSON</h2>
        <div className="h-auto">
          <AceEditor
            readOnly
            mode="json"
            theme="github"
            value={JSON.stringify(
              message?.labels?.map((l) => l.data),
              null,
              2
            )}
            name="preview"
            editorProps={{ $blockScrolling: true }}
            setOptions={{ useWorker: false }}
          />
        </div>
      </Drawer>
    </>
  );
}

function summarizeLabels(params: any[]) {
  const labelSummary: { [key: string]: { [key: string]: number } } = {};
  params.forEach((param: any) => {
    Object.keys(param).forEach((key: string) => {
      if (labelSummary[key]) {
        if (labelSummary[key][param[key]]) {
          labelSummary[key][param[key]] += 1;
        } else {
          labelSummary[key][param[key]] = 1;
        }
      } else {
        labelSummary[key] = { [param[key]]: 1 };
      }
    });
  });
  return labelSummary;
}

function PreviewSumamry({
  summarizedLabels,
}: {
  summarizedLabels: { [x: string]: any };
}) {
  const previewRows = Object.keys(summarizedLabels)
    .slice(0, 2)
    .map((key) => {
      const value = summarizedLabels[key];
      return (
        <div className="mb-2">
          {key}: {JSON.stringify(value)}
        </div>
      );
    });
  return (
    <div className="m-2">
      <div className="text-gray-600">Label Summary:</div>
      <pre className="m-2">{previewRows}</pre>
    </div>
  );
}
