import { ForwardedRef, forwardRef, useState } from "react";
import { Bookmark, Message, MessagePreference } from "src/types/models";

import DialoguePreviewMessage from "./dialogue-preview-message";
import MessageBookmark from "./message-bookmark";

interface Props {
  message: Message;
  isSelected: boolean;
  messageSelection?: MessagePreference;
  initialBookmark?: Bookmark;
}

const MessageWithBookmark = forwardRef(
  (
    { message, isSelected, messageSelection, initialBookmark }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div
        className="flex items-center"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <DialoguePreviewMessage
          message={message}
          isSelected={isSelected}
          messageSelection={messageSelection}
          ref={ref}
        />
        <MessageBookmark
          message={message}
          initialBookmark={initialBookmark}
          isHovered={isHovered}
          onBookmarkCreation={() => setIsHovered(false)}
        />
      </div>
    );
  }
);

export default MessageWithBookmark;
