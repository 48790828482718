import { LocalAnnotationData } from "src/features/annotation-tasks/types";
import { Task, TaskType } from "src/types/models";

export function isAnnotationTask(task: Task) {
  return (
    task.type === TaskType.ANNOTATION ||
    task.type === TaskType.STATIC_ANNOTATION
  );
}

export function isMessageAnnotated(
  messageAnnotationData: LocalAnnotationData | undefined,
  requiredAnnotationFields: Record<string, boolean> | null
) {
  if (!Boolean(messageAnnotationData)) {
    return false;
  }

  if (requiredAnnotationFields === null) {
    return true;
  }

  let isAnnotated = true;
  for (const field in requiredAnnotationFields) {
    if (!Boolean(messageAnnotationData?.[field])) {
      isAnnotated = false;
      break;
    }
  }
  return isAnnotated;
}
