import { Route, Routes } from "react-router-dom";

import Preview from "./preview";

export const InteractiveSxsConversationRoutes = () => {
  return (
    <Routes>
      <Route path=":conversationId" element={<Preview />} />
    </Routes>
  );
};
