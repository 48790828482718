import { BotResponseOption } from "src/types/models";

export function getSearchResultsToDisplay(
  option: BotResponseOption
): string | undefined {
  if (!option.extras) {
    return;
  }

  for (const extra of option.extras) {
    if (extra.type === "web_retrieval" && extra.search_results_to_display) {
      return extra.search_results_to_display;
    }
  }
}
