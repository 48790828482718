import TaskCompleteNotice from "src/components/task/task-complete-notice";
import TaskInstruction from "src/components/task/task-instruction";
import Card from "src/components/ui/card";
import PageHeader from "src/components/ui/page-header";
import { Task } from "src/types/models";
import { shallow } from "zustand/shallow";

import useQuizStore from "../hooks/use-quiz-store";
import { AnswerCheckingState, Quiz, QuizScore } from "../types";
import Footer from "./footer";
import Header from "./header";
import Option from "./option";
import Question from "./question";

type Props = {
  task: Task;
  quiz: Quiz;
  nextQuestionIndex: number;
  initialQuizScores: QuizScore | null;
};

const MAX_NUM_OPTIONS = 6;

export default function QuizPage(props: Props) {
  const { task, quiz: quiz_, nextQuestionIndex, initialQuizScores } = props;
  const [
    quiz,
    selectedOption,
    currentQuestionIndex,
    answerCheckingState,
    canCompleteQuiz,
    selectOption,
    setInitialData,
  ] = useQuizStore(
    (state) => [
      state.quiz,
      state.selectedOption,
      state.currentQuestionIndex,
      state.answerCheckingState,
      state.canCompleteQuiz,
      state.selectOption,
      state.setInitialData,
    ],
    shallow
  );

  if (!quiz) {
    setInitialData(quiz_, nextQuestionIndex, initialQuizScores);
    return null;
  }

  if (canCompleteQuiz) {
    return (
      <TaskCompleteNotice prolificRedirectUrl={task.prolific_redirect_url} />
    );
  }

  const currentQuestion = quiz!.content.questions[currentQuestionIndex];
  const options = [];
  for (let i = 0; i < MAX_NUM_OPTIONS; i++) {
    const optionKey = `option_${i}` as keyof typeof currentQuestion;
    if (currentQuestion[optionKey]) {
      options.push(currentQuestion[optionKey] as string);
    }
  }

  return (
    <>
      <PageHeader heading={quiz!.content.title} subHeading="Quiz" />
      <main className="max-w-3xl px-4 pb-12 mx-auto sm:px-6 lg:px-8 prose text-sm">
        <TaskInstruction
          markdownInstruction={task.markdown_instruction}
          instruction={task.instruction}
        />
        <div className="grid w-full max-w-3xl gap-4 mx-auto my-4 sm:grid-cols-1 gap-y-4">
          <Card
            key={currentQuestionIndex}
            header={<Header />}
            footer={<Footer taskId={task.id} />}>
            <Question content={currentQuestion} />
            <div className="text-base font-bold">
              Please select the better AI response:
            </div>
            <div className="flex flex-col gap-y-4">
              {options.map((option, index) => {
                return (
                  <Option
                    key={index}
                    text={option}
                    isSelected={selectedOption === index + 1}
                    isChecked={
                      answerCheckingState === AnswerCheckingState.CHECKED
                    }
                    isCorrect={Boolean(
                      currentQuestion[
                        `is_correct_${
                          index + 1
                        }` as keyof typeof currentQuestion
                      ]
                    )}
                    explanation={
                      currentQuestion[
                        `explanation_${
                          index + 1
                        }` as keyof typeof currentQuestion
                      ] as string
                    }
                    showExplanation={quiz.show_explanation}
                    onClick={() => {
                      selectOption(index + 1);
                    }}
                  />
                );
              })}
            </div>
          </Card>
        </div>
      </main>
    </>
  );
}
