import {
  AnnotationForm,
  LocalAnnotationData,
} from "src/features/annotation-tasks/types";
import { ComparisonScaleOptions } from "src/features/interactive-sxs-tasks/components/comparison-scale/types";
import {
  BotResponseOption,
  Message,
  Scenario,
  SerializedPolicyBatchResponse,
} from "src/types/models";

export type ModelOptionLabel = "A" | "B" | "C" | "D";

export enum TaskState {
  NEED_CONFIDENTIALITY_AGREEMENT,
  NOT_STARTED,
  STARTING,
  SELECT_SCENARIO,
  CONVERSATION_IN_PROGRESS,
  CONVERSATION_COMPLETED,
  FINISHED,
  ERROR,
}

export enum InteractiveConversationState {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
}

export type RetrievalOption = {
  label: ModelOptionLabel;
  text: string;
  annotationForm?: string;
  searchResults?: string;
};

export type RetrievalAnnotationForm = {
  questions_for_response_without_search_results: string;
  questions_for_response_with_search_results: string;
};

export type SingleScenarioConfig = {
  id: number;
  config: number;
  instruction: string;
  scenario_tags: number[];
  min_num_ai_turns: number;
  max_num_ai_turns: number;
  num_ai_turns: number;
  index: number;
  options_heading: string;
};

export type MultipleScenariosConfig = {
  id: number;
  name: string;
  single_scenario_configs: SingleScenarioConfig[];
  should_shuffle: boolean;
};

export type InteractiveTask = {
  public_id: string;
  uuid: string;
  prolific_redirect_url: string;
  instructions: string;
  markdown_instruction?: string;
  num_turns: number;
  show_both_options_are_good: boolean;
  require_scenario: boolean;
  alternative_mode: boolean;
  require_alternative_response: boolean;
  max_refresh_attempts: number;
  annotation_forms: AnnotationForm[];
  model_options: ModelOptionLabel[];
  num_conversations: number;
  options_heading: string;
  automatically_select_scenario: boolean;
  ai_starts_chat: boolean;
  comparison_scale_option: ComparisonScaleOptions;
  retrieval_annotation_form: RetrievalAnnotationForm | null;
  multiple_scenarios_config: MultipleScenariosConfig | null;
  single_model_mode: boolean;
};

export type ScenarioCheckpoint = {
  scenario: Scenario;
  turn: number;
};
export type ScenarioCheckpoints = ScenarioCheckpoint[];

export type InteractiveConversation = {
  id: number;
  state: InteractiveConversationState;
  index?: number;
  scenario?: Scenario;
  scenario_checkpoints: ScenarioCheckpoints;
  history: Message[];
  num_ai_turns?: number;
  multiple_scenarios_config?: MultipleScenariosConfig;
};

export type TaskData = {
  conversations: InteractiveConversation[];
  policies: SerializedPolicyBatchResponse;
} & InteractiveTask;

export type SelectOptionHandler = (args: {
  option: BotResponseOption | null;
  both_are_good: boolean;
  both_are_bad: boolean;
  alternative?: string;
  iannotation_data?: LocalAnnotationData;
}) => void;
