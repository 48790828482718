import Messages from "src/components/messages/messages";

import useSelectOption from "../hooks/use-select-option";
import useStaticSxsStore from "../hooks/use-static-sxs-store";
import { StaticConversation as StaticConversationType } from "../types";
import Explanation from "./explanation";
import Reference from "./reference";
import SideBySideOptions from "./side-by-side-options";

type Props = {
  currentConversation: StaticConversationType;
};

export default function StaticConversation(props: Props) {
  const { currentConversation } = props;
  const { selectOption, showExplanation, submitExplanation } =
    useSelectOption();
  const taskData = useStaticSxsStore((state) => state.taskData!);
  const reference = currentConversation.extras?.find(
    (r: any) => r.type === "reference"
  );
  return (
    <div className="flex flex-col flex-1 p-5 mx-auto overflow-y-hidden bg-white border shadow-lg">
      {reference && (
        <Reference text={reference.data.text} url={reference.data.url} />
      )}
      <Messages
        messages={currentConversation.context}
        shouldShowReaction={() => false}
      />
      {currentConversation && (
        <div className="mt-4">
          <SideBySideOptions
            select={selectOption}
            firstOption={
              !showExplanation ? currentConversation.firstOption : null
            }
            secondOption={
              !showExplanation ? currentConversation.secondOption : null
            }
            showBothOptionsAreGood={taskData.show_both_options_are_good}
            taskType={taskData.type}
            buttonExplanation={taskData.button_explanation}
          />
        </div>
      )}
      {showExplanation && <Explanation onSubmit={submitExplanation} />}
    </div>
  );
}
