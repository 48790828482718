import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import classNames from "src/utils/class-names";

export interface MessageFormProp {
  handleSubmitMessage: (message: string) => Promise<void>;
  disabled: boolean;
  handleTypingIndicator?: (isTyping: boolean) => void;
  initialValue?: string;
  placeholder?: string;
}

export default function MessageForm({
  handleTypingIndicator,
  handleSubmitMessage,
  disabled,
  initialValue,
  placeholder,
}: MessageFormProp) {
  const [message, setMessage] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const submitMessage = async () => {
    if (message.length === 0) {
      return;
    }
    setMessage("");
    await handleSubmitMessage(message);
  };

  useEffect(() => {
    setMessage(initialValue || "");
  }, [initialValue]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === "Enter" && !e.shiftKey) {
      e.preventDefault();
      submitMessage();
      return;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setMessage(e.target.value);
    if (handleTypingIndicator) {
      const isTyping = e.target.value.length > 0;
      handleTypingIndicator(isTyping);
    }
  };

  useEffect(() => {
    if (textAreaRef.current && !disabled) {
      textAreaRef.current.focus();
    }
  }, [textAreaRef, disabled, initialValue]);

  const onSubmitHandler = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent
  ) => {
    e.preventDefault();
    submitMessage();
  };

  return (
    <form
      className="flex flex-col justify-end flex-none"
      onSubmit={onSubmitHandler}>
      <div className="overflow-hidden border border-gray-300 rounded-lg shadow-sm focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500">
        <label htmlFor="message" className="sr-only">
          {placeholder ? placeholder : "Add your message"}
        </label>
        <TextareaAutosize
          name="message"
          id="message"
          value={message}
          className={classNames(
            "block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm",
            disabled ? "bg-gray-100" : ""
          )}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
          ref={textAreaRef}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      </div>
      <div className="flex justify-end flex-1">
        <button
          className="px-4 py-2 mt-4 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          disabled={disabled}
          onClick={onSubmitHandler}>
          Submit Message
        </button>
      </div>
    </form>
  );
}
