import { ApiClient } from "src/api";

export async function checkAnswer(
  apiClient: ApiClient,
  payload: {
    task_id: number;
    question_index: number;
    answer: string;
  }
): Promise<boolean> {
  const response = await apiClient.post("/quizzes/check-answer/", payload);
  return response["is_correct"];
}
