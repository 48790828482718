import { ArrowRightIcon } from "@heroicons/react/outline";
import classNames from "src/utils/class-names";

type Props = {
  isEnabled: boolean;
  onClick: () => void;
};

export default function NextButton(props: Props) {
  const { isEnabled, onClick } = props;
  return (
    <button
      type="button"
      disabled={!isEnabled}
      onClick={onClick}
      className={classNames(
        "inline-flex items-center mx-2 px-3 py-2 rounded-lg text-sm font-medium border border-transparent",
        isEnabled
          ? "cursor-pointer bg-indigo-600 text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-800 focus:ring-offset-2"
          : "cursor-not-allowed bg-gray-100 text-black"
      )}>
      Next
      <ArrowRightIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
    </button>
  );
}
