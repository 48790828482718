import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

// Record string data in the query string so it can persist as part of the URL
const useUrlState = (
  key: string
): [string | undefined, (value: string | undefined) => void] => {
  const [search, setSearch] = useSearchParams();

  const setValue = useCallback(
    (value: string | undefined) => {
      const newSearch = new URLSearchParams(search);
      if (value === undefined) {
        newSearch.delete(key);
      } else {
        newSearch.set(key, value);
      }
      setSearch(newSearch, { replace: true });
    },
    [search, setSearch, key]
  );

  return [search.get(key) || undefined, setValue];
};

export default useUrlState;
