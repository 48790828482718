import { Route, Routes } from "react-router-dom";

import Home from "./home";
import NotFound from "./not-found";

export const MiscRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
