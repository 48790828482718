interface PageHeaderProps {
  heading: string;
  subHeading?: string;
  publicId?: string;
}

export default function PageHeader({
  heading,
  subHeading,
  publicId,
}: PageHeaderProps) {
  const subHeadingComponents: string[] = [];
  if (subHeading) {
    subHeadingComponents.push(subHeading);
  }
  if (publicId) {
    subHeadingComponents.push(`(Task ID: ${publicId})`);
  }

  return (
    <header className="py-8 bg-gray-50">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-3xl font-extrabold text-center text-gray-900 sm:text-4xl">
            {subHeadingComponents.length > 0 && (
              <span className="block mb-2 text-xs font-semibold tracking-wide text-center text-indigo-600 uppercase">
                {subHeadingComponents.join(" ")}
              </span>
            )}
            {heading}
          </h2>
        </div>
      </div>
    </header>
  );
}
