import { useSearchParams } from "react-router-dom";
import OptionButton from "src/components/side-by-side/option-button";
import OptionText from "src/components/side-by-side/option-text";
import SideBySideActionContainer from "src/components/side-by-side/side-by-side-action-container";
import Spinner from "src/components/ui/spinner";
import { BotResponseOption } from "src/types/models";

import { StaticTaskType } from "../types";
import SideBySideOptionButton from "./side-by-side-option-button";

interface BothOptionButtonProps {
  select: (
    option: BotResponseOption,
    both: boolean,
    alternative?: string
  ) => void;
  firstOption: BotResponseOption;
  secondOption: BotResponseOption;
}

function AudioControls2({
  url,
  onSelect,
  text,
}: {
  url: string;
  onSelect: () => Promise<void>;
  text: string;
}) {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="text-lg">{text}</div>
      <div className="flex flex-row ">
        <audio controls className="mt-2">
          <source src={url} />
          Your browser does not support the audio element.
        </audio>
        <div className="w-1/3 ml-2">
          <SideBySideOptionButton onClick={onSelect} text={`Select ${text}`} />
        </div>
      </div>
    </div>
  );
}

function BothOptionButton({
  select,
  firstOption,
  secondOption,
}: BothOptionButtonProps) {
  return (
    <div className="h-full my-2">
      <button
        type="button"
        onClick={() =>
          select(Math.random() > 0.5 ? firstOption : secondOption, true)
        }
        className="inline-flex items-center justify-start w-full px-6 py-3 text-sm text-left text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
        Two choices have the same quality (either equally good or equally bad)
      </button>
    </div>
  );
}

interface SideBySideOptionsProps {
  select: (option: BotResponseOption, both: boolean) => Promise<void>;
  loading?: boolean;
  firstOption: BotResponseOption | null;
  secondOption: BotResponseOption | null;
  showBothOptionsAreGood: boolean;
  buttonExplanation: string | null;
  taskType?: StaticTaskType;
}

export default function SideBySideOptions({
  select,
  loading,
  firstOption,
  secondOption,
  showBothOptionsAreGood,
  buttonExplanation = null,
  taskType = 1,
}: SideBySideOptionsProps) {
  const searchParams = useSearchParams()[0];
  const showModelNames = Boolean(searchParams.get("showModelNames"));

  return (
    <>
      {loading && <Spinner />}
      {firstOption && secondOption && (
        <div className="flex flex-col gap-y-4">
          <p
            className="mb-2 text-sm text-gray-700"
            dangerouslySetInnerHTML={{
              __html: buttonExplanation || "What response has better quality?",
            }}
          />
          <div className="grid grid-cols-2 gap-4">
            <div className="h-full flex flex-col gap-y-2">
              {showModelNames && (
                <span className="flex items-center justify-center w-16 rounded bg-yellow-100 px-2 py-0.5 text-xs font-medium text-yellow-800">
                  Model {firstOption.model}
                </span>
              )}
              {taskType === 2 ? (
                <AudioControls2
                  url={firstOption.text}
                  text={"Option A"}
                  onSelect={() => select(firstOption, false)}
                />
              ) : (
                <OptionText
                  option={"A"}
                  modelName={showModelNames ? firstOption.model : null}
                  text={firstOption.text}
                />
              )}
            </div>
            <div className="h-full flex flex-col gap-y-2">
              {showModelNames && (
                <span className="flex items-center justify-center w-16 rounded bg-yellow-100 px-2 py-0.5 text-xs font-medium text-yellow-800">
                  Model {secondOption.model}
                </span>
              )}
              {taskType === 2 ? (
                <AudioControls2
                  url={secondOption.text}
                  text={"Option B"}
                  onSelect={() => select(secondOption, false)}
                />
              ) : (
                <OptionText
                  option={"B"}
                  modelName={showModelNames ? secondOption.model : null}
                  text={secondOption.text}
                />
              )}
            </div>
          </div>

          {taskType !== 2 && (
            <SideBySideActionContainer>
              <div className="grid grid-cols-1 gap-y-4 md:grid-cols-2">
                <OptionButton
                  text={"A"}
                  key={`option-a`}
                  onClick={() => select(firstOption, false)}
                />
                <OptionButton
                  text={"B"}
                  key={`option-b`}
                  onClick={() => select(secondOption, false)}
                />
              </div>
            </SideBySideActionContainer>
          )}

          {showBothOptionsAreGood && (
            <BothOptionButton
              select={select}
              firstOption={firstOption}
              secondOption={secondOption}
            />
          )}
        </div>
      )}
    </>
  );
}
