import NewlineSafeReactMarkdown from "src/components/text/newline-safe-react-markdown";

type Props = {
  text: string;
  url: string;
};

export default function Reference(props: Props) {
  const { text, url } = props;
  return (
    <div className="p-4 bg-gray-100 rounded-md flex flex-col gap-y-4 text-sm">
      <h2 className="text-xl font-bold tracking-tight text-gray-900">
        Reference
      </h2>
      <p>
        <span className="font-bold">Reference Text: </span>
        <NewlineSafeReactMarkdown className="text-sm">
          {text}
        </NewlineSafeReactMarkdown>
      </p>
      <p>
        <span className="font-bold">Reference URL: </span>
        <a
          href={url}
          className="text-sm font-semibold leading-7 text-indigo-600">
          {url}
        </a>
      </p>
    </div>
  );
}
