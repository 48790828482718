import { Route, Routes } from "react-router-dom";
import { RequireAuthProvider } from "src/libs/auth";

import Login from "./login";
import SetUserDetails from "./set-user-details";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />}></Route>
      <Route
        path="set-details"
        element={
          <RequireAuthProvider loginUrl="/auth/login/">
            <SetUserDetails />
          </RequireAuthProvider>
        }></Route>
    </Routes>
  );
};
