import { DotsCircleHorizontalIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import DialoguesNavigationHeader from "src/components/dialogue/dialogues-navigation-header";
import BotMessage from "src/components/messages/bot-message";
import UserMessage from "src/components/messages/user-message";
import TaskInstruction from "src/components/task/task-instruction";
import PageHeader from "src/components/ui/page-header";
import useDialoguesStore from "src/hooks/use-dialogues-store";
import useMessages from "src/hooks/use-messages";
import { parseForm } from "src/libs/forms";
import {
  filterBotMessages,
  filterHumanMessages,
  isBotMessage,
} from "src/libs/messages";
import {
  ActivityState,
  Message,
  MessageType,
  SerializedPolicyBatchResponse,
  Task,
} from "src/types/models";
import { shallow } from "zustand/shallow";

import {
  useAnnotationData,
  useAnnotationForms,
  useNextDialogueHandler,
} from "../hooks/use-annotation";
import { LocalAnnotationData } from "../types";
import { isMessageAnnotated } from "../utils";
import AnnotatableBotMessage from "./annotatable-bot-message";
import AnnotatableHumanMessage from "./annotatable-human-message";
import AnnotationAction from "./annotation-action";

interface AnnotationTaskProps {
  task: Task;
  policies: SerializedPolicyBatchResponse;
}

interface MessagesProps {
  messages: Message[];
  selectedMessage: Message | null;
  setSelectedMessageRef: (element: HTMLDivElement | null) => void;
  handleSelectMessage: (message: Message) => void;
  requiredAnnotationFields: Record<string, boolean> | null;
  requiredHumanMessageAnnotationFields: Record<string, boolean> | null;
  messagesAnnotationData: { [messageId: number]: LocalAnnotationData };
  shouldAnnotateHumanMessages: boolean;
  shouldAnnotateBotMessages: boolean;
  shouldAnnotateLastMessageOnly: boolean;
}

function Messages({
  messages,
  selectedMessage,
  setSelectedMessageRef,
  handleSelectMessage,
  requiredAnnotationFields,
  messagesAnnotationData,
  requiredHumanMessageAnnotationFields,
  shouldAnnotateHumanMessages,
  shouldAnnotateBotMessages,
  shouldAnnotateLastMessageOnly,
}: MessagesProps) {
  return (
    <>
      {messages.map((message, index) => {
        const isSelected = message.id === selectedMessage?.id;
        const shouldUseHumanMessageForm =
          !isBotMessage(message) && shouldAnnotateHumanMessages;
        const canAnnotate =
          !shouldAnnotateLastMessageOnly || index === messages.length - 1;

        const isAnnotated = isMessageAnnotated(
          messagesAnnotationData[message.id],
          shouldUseHumanMessageForm
            ? requiredHumanMessageAnnotationFields
            : requiredAnnotationFields
        );

        if (
          message.type === MessageType.BOT_MESSAGE ||
          message.type === MessageType.HUMAN_BOT_MESSAGE
        ) {
          return canAnnotate && shouldAnnotateBotMessages ? (
            <AnnotatableBotMessage
              message={message}
              key={message.id}
              isAnnotated={isAnnotated}
              isSelected={selectedMessage?.id === message.id}
              handleClick={(e) => handleSelectMessage(message)}
              ref={isSelected ? setSelectedMessageRef : null}
            />
          ) : (
            <BotMessage
              message={message}
              key={message.id}
              showReaction={false}
            />
          );
        }

        return canAnnotate && shouldAnnotateHumanMessages ? (
          <AnnotatableHumanMessage
            message={message}
            key={message.id}
            isAnnotated={isAnnotated}
            isSelected={selectedMessage?.id === message.id}
            handleClick={(e) => handleSelectMessage(message)}
            ref={isSelected ? setSelectedMessageRef : null}
          />
        ) : (
          <UserMessage message={message} key={message.id} />
        );
      })}
    </>
  );
}

export default function AnnotationTask({
  task,
  policies,
}: AnnotationTaskProps) {
  const [dialogues, currentDialogue, currentActivity, currentDialogueIndex] =
    useDialoguesStore(
      (state) => [
        state.dialogues,
        state.currentDialogue,
        state.currentActivity,
        state.currentDialogueIndex,
        state.setCurrentDialogueIndex,
        state.setCurrentActivity,
      ],
      shallow
    );

  // annotation forms
  const {
    messageAnnotationForm,
    dialogueAnnotationForm,
    humanMessageAnnotationForm,
    messagesAnnotationFormRequiredFields,
    humanMessagesAnnotationFormRequiredFields,
  } = useAnnotationForms(task);

  // annotation data
  const {
    messagesAnnotationData,
    dialogueAnnotationData,
    isSavingAnnotationData,
    messageAnnotationInputChangeHandler,
    dialogueAnnotationInputChangeHandler,
  } = useAnnotationData(task, currentDialogue!);

  // dialogue handlers
  const { nextDialogueHandler, completeDialogueHandler, skipDialogueHandler } =
    useNextDialogueHandler(task);

  const shouldAnnotateHumanMessages = task.annotate_human_messages;
  const shouldAnnotateBotMessages = !!messageAnnotationForm;

  const currentDialogueId = currentDialogue!.id;
  const messages = useMessages(currentDialogueId)[0];
  const [hasScrolled, setHasScrolled] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [selectedMessageRef, setSelectedMessageRef] =
    useState<HTMLDivElement | null>();

  const annotatableMessages = useMemo(() => {
    if (task.annotate_last_message_only) {
      const lastMessage = messages[messages.length - 1];
      return lastMessage ? [lastMessage] : [];
    }

    if (shouldAnnotateHumanMessages) {
      return shouldAnnotateBotMessages
        ? messages
        : filterHumanMessages(messages);
    }
    return filterBotMessages(messages);
  }, [
    task.annotate_last_message_only,
    messages,
    shouldAnnotateHumanMessages,
    shouldAnnotateBotMessages,
  ]);

  const numAnnotatableMessages = annotatableMessages.length;
  const messageIdsWithAnnotation = Object.keys(messagesAnnotationData).map(
    parseInt
  );
  let nextMessageIndexToAnnotate: null | number = null;
  let numMessagesFullyAnnotated = 0;

  if (messageIdsWithAnnotation.length === 0) {
    nextMessageIndexToAnnotate = numAnnotatableMessages > 0 ? 0 : null;
  } else {
    annotatableMessages.forEach((message, index) => {
      if (message.id in messagesAnnotationData) {
        const requiredFields =
          isBotMessage(message) || !shouldAnnotateHumanMessages
            ? messagesAnnotationFormRequiredFields
            : humanMessagesAnnotationFormRequiredFields;

        if (
          isMessageAnnotated(messagesAnnotationData[message.id], requiredFields)
        ) {
          numMessagesFullyAnnotated += 1;
        } else if (nextMessageIndexToAnnotate === null) {
          nextMessageIndexToAnnotate = index;
        }
      } else if (nextMessageIndexToAnnotate === null) {
        nextMessageIndexToAnnotate = index;
      }
    });
  }

  const areAllMessagesAnnotated =
    numAnnotatableMessages > 0 &&
    numAnnotatableMessages === numMessagesFullyAnnotated;
  const isDialogueCompleted =
    areAllMessagesAnnotated &&
    (!dialogueAnnotationForm || dialogueAnnotationData);
  const isLastDialogue =
    !task.use_adhoc_assignment_strategy &&
    currentDialogueIndex === dialogues.length - 1;

  const selectNextMessage = useCallback(() => {
    if (nextMessageIndexToAnnotate !== null) {
      setSelectedMessage(annotatableMessages[nextMessageIndexToAnnotate]);
    }
  }, [annotatableMessages, nextMessageIndexToAnnotate]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey && (e.code === "Space" || e.code === "KeyN")) {
        e.preventDefault();
        selectNextMessage();
      }
    },
    [selectNextMessage]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (!hasScrolled && selectedMessageRef) {
      selectedMessageRef?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setHasScrolled(true);
    }
  }, [hasScrolled, selectedMessageRef]);

  useEffect(() => {
    if (
      isDialogueCompleted &&
      currentActivity?.state !== ActivityState.COMPLETED
    ) {
      completeDialogueHandler();
    }
  }, [isDialogueCompleted, currentActivity, completeDialogueHandler]);

  let annotationAction = null;

  if (areAllMessagesAnnotated) {
    let callToAction = null;
    let title = "Almost there 🚀🚀";
    let description = null;
    let icon = (
      <DotsCircleHorizontalIcon
        className="w-5 h-5 text-indigo-500"
        aria-hidden="true"
      />
    );
    if (isDialogueCompleted) {
      callToAction = isLastDialogue ? (
        <a
          href={task.prolific_redirect_url}
          className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Go back to Prolific
        </a>
      ) : (
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium bg-green-400 border border-transparent rounded-md shadow-sm text-gray hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={nextDialogueHandler}>
          Next Conversation
        </button>
      );
      title = isLastDialogue
        ? "Last Conversation Completed 🏆"
        : "Conversation Completed 🏆";
      description = isLastDialogue
        ? "Thank you for participating in our study. Please click the link below to go back to Prolific."
        : "Please click the button below to advance to the next conversation. Or you can continue adjusting labels for this conversation, if necessary.";
      icon = (
        <CheckCircleIcon
          className="w-5 h-5 text-green-500"
          aria-hidden="true"
        />
      );
    }
    const dialogueForm = dialogueAnnotationForm ? (
      <form
        className="text-sm annotation-form"
        id={`dialogue-form-${currentDialogueId}`}
        key={`dialogue-form-${currentDialogueId}`}
        data-dialogue-id={currentDialogueId}>
        <fieldset>
          <div className="mb-4 text-md">
            Please label the whole conversation
          </div>
          {parseForm(dialogueAnnotationForm.value, {
            formData: dialogueAnnotationData,
            onChange: dialogueAnnotationInputChangeHandler,
          })}
        </fieldset>
      </form>
    ) : null;
    annotationAction = (
      <AnnotationAction
        isSavingData={isSavingAnnotationData}
        icon={icon}
        numMessagesWithLabels={numMessagesFullyAnnotated}
        totalMessages={numAnnotatableMessages}
        title={title}
        description={description}
        callToAction={callToAction}
        dialogueForm={dialogueForm}
      />
    );
  } else {
    const tips = (
      <span>
        <span className="mr-1 font-bold">Tips:</span>
        <span>
          You can use
          <span className="p-1 mx-1 font-mono text-xs text-black bg-white rounded-lg">
            CTRL + Space
          </span>
          (or
          <span className="p-1 mx-1 font-mono text-xs text-black bg-white rounded-lg">
            CTRL + n
          </span>
          in MacOS) to select the next{" "}
          {shouldAnnotateHumanMessages ? "" : "bot "}message that still needs
          labels.
        </span>
      </span>
    );
    const callToAction = (
      <button
        type="button"
        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={selectNextMessage}>
        Next Message
      </button>
    );
    annotationAction = (
      <AnnotationAction
        isSavingData={isSavingAnnotationData}
        icon={
          <DotsCircleHorizontalIcon
            className="w-5 h-5 text-indigo-500"
            aria-hidden="true"
          />
        }
        numMessagesWithLabels={numMessagesFullyAnnotated}
        totalMessages={numAnnotatableMessages}
        title="In Progress 🚀"
        description={
          selectedMessage
            ? tips
            : `Please click on a ${
                shouldAnnotateHumanMessages ? "" : "bot "
              }message or the button below to continue.`
        }
        callToAction={selectedMessage ? null : callToAction}
      />
    );
  }

  let annotationFormForSelectedMessage = null;
  if (selectedMessage) {
    if (isBotMessage(selectedMessage) || !shouldAnnotateHumanMessages) {
      annotationFormForSelectedMessage = messageAnnotationForm;
    } else {
      annotationFormForSelectedMessage = humanMessageAnnotationForm;
    }
  }

  let messageForm = null;
  if (selectedMessage && annotationFormForSelectedMessage) {
    messageForm = (
      <div>
        <form
          className="mt-4 annotation-form overflow-y-auto max-h-96 px-4"
          id={`message-form-${selectedMessage.id}`}
          key={`message-form-${selectedMessage.id}`}
          data-message-id={selectedMessage.id}>
          <div className="mb-4 font-bold text-md">
            {annotationFormForSelectedMessage.description}
          </div>
          {parseForm(annotationFormForSelectedMessage.value, {
            formData: messagesAnnotationData[selectedMessage.id],
            onChange: messageAnnotationInputChangeHandler(selectedMessage.id),
          })}
        </form>
      </div>
    );
  }

  const messageFormContainerStyle: CSSProperties = selectedMessageRef
    ? {
        position: "relative",
        top: Math.max(selectedMessageRef.offsetTop - 100, 0),
      }
    : {};

  return (
    <>
      <PageHeader
        heading={task.public_name}
        subHeading="Conversation Labels"
        publicId={task.public_id}
      />
      <main className="px-4 pb-12 mx-auto sm:px-6 lg:px-8 max-w-screen-2xl">
        <div className="mx-auto divide-y-2 divide-gray-200">
          <TaskInstruction
            instruction={task.instruction}
            markdownInstruction={task.markdown_instruction}
            policies={policies}
          />
        </div>
        <div className="grid w-full grid-cols-2 gap-4 mb-4">
          <div className="w-full mx-auto mt-4">
            <DialoguesNavigationHeader
              currentDialogueIndex={currentDialogueIndex}
              numDialogues={dialogues.length}
              skipDialogueHandler={skipDialogueHandler}
              hideDialogueProgressMarker={task.use_adhoc_assignment_strategy}
            />
            <div className="relative flex flex-col flex-1 p-5 overflow-y-hidden">
              <div className="px-4 overflow-y-auto" id="messages">
                <Messages
                  messages={messages}
                  messagesAnnotationData={messagesAnnotationData}
                  shouldAnnotateHumanMessages={shouldAnnotateHumanMessages}
                  shouldAnnotateBotMessages={shouldAnnotateBotMessages}
                  shouldAnnotateLastMessageOnly={
                    task.annotate_last_message_only
                  }
                  selectedMessage={selectedMessage}
                  handleSelectMessage={setSelectedMessage}
                  setSelectedMessageRef={setSelectedMessageRef}
                  requiredAnnotationFields={
                    messagesAnnotationFormRequiredFields
                  }
                  requiredHumanMessageAnnotationFields={
                    humanMessagesAnnotationFormRequiredFields ?? null
                  }
                />
              </div>
            </div>
          </div>
          <div className="w-full mt-4 text-sm">
            <div className="py-6 text-xl text-gray-900">Labels</div>
            <div style={messageFormContainerStyle}>
              {annotationAction}
              {(annotationFormForSelectedMessage ||
                (selectedMessage && policies.length > 0)) && (
                <div className="flex flex-col flex-1 w-full p-5 overflow-y-auto rounded-b-lg bg-white border shadow-sm gap-8">
                  {annotationFormForSelectedMessage && (
                    <div className="px-4">{messageForm}</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
