import { ApiClient } from "src/api";

import { TaskData } from "../types";

import { NUM_RETRY_ATTEMPTS } from ".";

type APIChatResponse = {
  text: string;
  extras?: any[];
  state?: any;
};

export async function chat(
  apiClient: ApiClient,
  model: string,
  preflightId: number,
  text: string | null,
  onRecoverableError: (errorMessage: string | null) => void,
  onUnrecoverableErrorHandler: () => void,
  state: string | null = null
): Promise<APIChatResponse[]> {
  try {
    const response = await apiClient.post(
      "/interactive-tasks/chat/",
      {
        model,
        preflight_id: preflightId,
        text,
        state,
      },
      {
        retry: {
          retryDelay(attempt: number) {
            // 5, 5, 8, 16, 32
            if (attempt < 2) {
              return 5 * 1000;
            }
            return 2 ** (attempt + 1) * 1000; // 1000, 2000,
          },
          retryOn(attempt: number, error: string | null, res: Response) {
            if (attempt >= NUM_RETRY_ATTEMPTS) {
              return false;
            }

            if (error !== null || res.status >= 400) {
              onRecoverableError(
                `Technical error. Retrying... Attempt ${
                  attempt + 1
                } / ${NUM_RETRY_ATTEMPTS} ...`
              );
              return true;
            }

            return false;
          },
        },
      }
    );
    onRecoverableError(null);
    return response;
  } catch (error) {
    onUnrecoverableErrorHandler();
    return [];
  }
}

export async function preflight(
  apiClient: ApiClient,
  conversation_id: number,
  onUnrecoverableErrorHandler: () => void
) {
  try {
    const response = await apiClient.post("/interactive-tasks/preflight/", {
      conversation_id,
    });
    return response.preflight_id;
  } catch (error) {
    return onUnrecoverableErrorHandler();
  }
}

export async function start(
  apiClient: ApiClient,
  taskId: string
): Promise<TaskData> {
  return apiClient.post("/interactive-tasks/start/", { task_id: taskId });
}

export function log(apiClient: ApiClient, payload: any) {
  return apiClient.post("/interactive-tasks/log/", payload);
}

export async function undo(
  apiClient: ApiClient,
  conversationId: number
): Promise<void> {
  apiClient.post("/interactive-tasks/undo/", {
    conversation_id: conversationId,
  });
}
