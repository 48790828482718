import { shallow } from "zustand/shallow";

import useApi from "../api";
import useQuizStore from "../hooks/use-quiz-store";
import { AnswerCheckingState } from "../types";
import Check from "./check";
import NextButton from "./next-btn";

type Props = {
  taskId: number;
};

export default function Footer(props: Props) {
  const { taskId } = props;
  const api = useApi();
  const [
    selectedOption,
    currentQuestionIndex,
    answerCheckingState,
    setAnswerCheckingState,
    nextQuestion,
    updateScore,
  ] = useQuizStore(
    (state) => [
      state.selectedOption,
      state.currentQuestionIndex,
      state.answerCheckingState,
      state.setAnswerCheckingState,
      state.nextQuestion,
      state.updateScore,
    ],
    shallow
  );

  return (
    <div className="flex w-full justify-end">
      <Check
        isEnabled={
          selectedOption !== null &&
          answerCheckingState === AnswerCheckingState.NOT_CHECKED
        }
        onClick={async () => {
          setAnswerCheckingState(AnswerCheckingState.CHECKING);
          const isCorrect = await api.quiz.checkAnswer({
            task_id: taskId,
            question_index: currentQuestionIndex,
            answer: selectedOption!.toString(),
          });
          setAnswerCheckingState(AnswerCheckingState.CHECKED);
          updateScore(isCorrect);
        }}
      />
      <NextButton
        isEnabled={answerCheckingState === AnswerCheckingState.CHECKED}
        onClick={() => nextQuestion()}
      />
    </div>
  );
}
