import { useMemo } from "react";
import MessageForm from "src/components/messages/message-form";
import { filterBotMessages } from "src/libs/messages";
import { shallow } from "zustand/shallow";

import useInteractiveSideBySideStore from "../../hooks/use-interactive-sxs-store";
import useSendMessage from "../../hooks/use-send-message";

export default function SubmitMessageActionBox() {
  const [taskData, getCurrentConversation, getNumRequiredAiMessages] =
    useInteractiveSideBySideStore(
      (state) => [
        state.taskData,
        state.getCurrentConversation,
        state.getNumRequiredAiMessages,
      ],
      shallow
    );
  const [isLoadingOptions, hasEnoughOptions, isSelectingScenario] =
    useInteractiveSideBySideStore(
      (state) => [
        state.isLoadingOptions,
        state.hasEnoughOptions,
        state.isSelectingScenario,
      ],
      shallow
    );
  const isSelectingOption = hasEnoughOptions();
  const sendMessage = useSendMessage();
  const currentConversation = getCurrentConversation();
  const numBotMessages = useMemo(
    () => filterBotMessages(currentConversation?.history || []).length,
    [currentConversation]
  );

  if (!taskData || !currentConversation) {
    return null;
  }

  const comparisonScaleEnabled = taskData.comparison_scale_option !== -1;
  const progressText = `Progress: ${numBotMessages} / ${getNumRequiredAiMessages()} AI messages.`;

  return (
    <>
      {isSelectingOption && comparisonScaleEnabled ? null : (
        <MessageForm
          handleSubmitMessage={(text: string) =>
            sendMessage(currentConversation.history, text)
          }
          disabled={
            isLoadingOptions || isSelectingOption || isSelectingScenario()
          }
        />
      )}
      <p className="ml-2 mt-4 flex flex-1 items-end justify-end text-xs font-medium italic text-orange-500">
        {progressText}
      </p>
    </>
  );
}
