import { TaskState } from "src/features/interactive-sxs-tasks/types";

import useInteractiveSideBySideStore from "../../hooks/use-interactive-sxs-store";
import NextConversationActionBox from "./next-conversation-action-box";
import SubmitMessageActionBox from "./submit-message-action-box";

export default function ActionBox() {
  const taskState = useInteractiveSideBySideStore((state) => state.taskState);
  let actionBox = null;
  if (taskState === TaskState.CONVERSATION_COMPLETED) {
    actionBox = <NextConversationActionBox />;
  } else if (taskState === TaskState.CONVERSATION_IN_PROGRESS) {
    actionBox = <SubmitMessageActionBox />;
  }
  return actionBox;
}
