import DialoguesNavigationHeader from "src/components/dialogue/dialogues-navigation-header";
import TaskInstruction from "src/components/task/task-instruction";
import PageHeader from "src/components/ui/page-header";
import Spinner from "src/components/ui/spinner";
import { shallow } from "zustand/shallow";

import useSkipConversation from "../hooks/use-skip-conversation";
import useStaticSxsStore from "../hooks/use-static-sxs-store";
import { TaskState } from "../types";
import CompleteAction from "./complete-action";
import StaticConversation from "./static-conversation";

export default function TaskContent() {
  const [taskData, taskState, getCurrentConversationIndex] = useStaticSxsStore(
    (state) => [
      state.taskData!,
      state.taskState,
      state.getCurrentConversationIndex,
    ],
    shallow
  );
  const { skipConversation } = useSkipConversation();
  const currentConversationIndex = getCurrentConversationIndex();
  let content = null;

  switch (taskState) {
    case TaskState.IN_PROGRESS:
    case TaskState.LOADING_NEXT_CONVERSATION:
      if (currentConversationIndex !== null) {
        const currentConversation = taskData.convos[currentConversationIndex];
        content = (
          <>
            <DialoguesNavigationHeader
              numDialogues={taskData.ratings_per_time}
              currentDialogueIndex={currentConversationIndex}
              skipDialogueHandler={skipConversation}
            />
            {taskState === TaskState.IN_PROGRESS && (
              <StaticConversation
                key={currentConversation.id}
                currentConversation={currentConversation}
              />
            )}
            {taskState === TaskState.LOADING_NEXT_CONVERSATION && <Spinner />}
          </>
        );
      }
      break;
    case TaskState.FINISHED:
      content = (
        <CompleteAction prolificRedirectUrl={taskData.prolific_redirect_url} />
      );
      break;
    default:
      content = null;
  }

  return (
    <>
      <PageHeader
        heading={taskData.public_name}
        publicId={taskData.public_id}
      />
      <main className="px-4 pb-12 mx-auto sm:px-6 lg:px-8 max-w-screen-2xl">
        <TaskInstruction
          instruction={taskData.instructions}
          markdownInstruction={taskData.markdown_instruction}
          policies={taskData.policies}
        />
        <div className="grid w-full max-w-7xl gap-4 mx-auto my-4 sm:grid-cols-1">
          <div className="w-full mb-6">{content}</div>
        </div>
      </main>
    </>
  );
}
