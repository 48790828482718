import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { Fragment } from "react";

export default function StartTaskError({
  error,
  taskId,
  resolution,
}: {
  error: any;
  taskId?: string;
  resolution?: string;
}) {
  let errorContent = "";
  if (!error) {
    errorContent = "An unknown error occured";
  } else if (typeof error === "string") {
    errorContent = error;
  } else if (Array.isArray(error)) {
    errorContent = error.join(", ");
  } else if ("message" in error && typeof error.message === "string") {
    errorContent = error.message;
  } else if (typeof error === "object") {
    errorContent = JSON.stringify(error);
  }
  return (
    <Transition.Root as={Fragment} show>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6 md:max-w-md">
                <div>
                  <div className="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full">
                    <ExclamationCircleIcon
                      className="w-6 h-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900">
                      Error starting task
                    </Dialog.Title>
                    <div className="my-4">
                      <p className="text-sm text-gray-700">{errorContent}</p>

                      <p className="text-sm text-gray-700 mt-4">
                        {taskId && (
                          <span>
                            Task ID: <code>{taskId}</code>
                          </span>
                        )}
                      </p>
                      <p className="text-sm text-gray-700 mt-4">
                        {resolution ||
                          "Please contact your researcher on how to proceed."}
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
