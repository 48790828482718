import { createWithEqualityFn } from "zustand/traditional";

import {
  AnswerCheckingState,
  ChoicesQuestion,
  Quiz,
  QuizScore,
} from "../types";

type QuizStateSlice = {
  quiz: Quiz | null;
  currentQuestionIndex: number;
  selectedOption: number | null;
  answerCheckingState: AnswerCheckingState;
  scores: QuizScore | null;
  canCompleteQuiz: boolean;
  setQuiz: (quiz: Quiz) => void;
  setCurrentQuestionIndex: (index: number) => void;
  nextQuestion: () => void;
  getNumQuestions: () => number;
  getCurrentQuestion: () => ChoicesQuestion | null;
  selectOption: (optionIndex: number) => void;
  setAnswerCheckingState: (state: AnswerCheckingState) => void;
  updateScore: (isCorrect: boolean) => void;
  setInitialData: (
    quiz: Quiz,
    currentQuestionIndex: number,
    scores: QuizScore | null
  ) => void;
};

const useQuizStore = createWithEqualityFn<QuizStateSlice>(
  (set, get) => ({
    quiz: null,
    currentQuestionIndex: 0,
    selectedOption: null,
    answerCheckingState: AnswerCheckingState.NOT_CHECKED,
    scores: null,
    canCompleteQuiz: false,
    setQuiz: (quiz) => set({ quiz }),
    setCurrentQuestionIndex: (index) => set({ currentQuestionIndex: index }),
    nextQuestion: () => {
      const { currentQuestionIndex, quiz } = get();
      if (quiz) {
        if (currentQuestionIndex < quiz.content.questions.length - 1) {
          set({
            selectedOption: null,
            answerCheckingState: AnswerCheckingState.NOT_CHECKED,
            currentQuestionIndex: currentQuestionIndex + 1,
          });
        } else {
          set({ canCompleteQuiz: true });
        }
      }
    },
    getNumQuestions: () => {
      const { quiz } = get();
      return quiz ? quiz.content.questions.length : 0;
    },
    getCurrentQuestion: () => {
      const { quiz, currentQuestionIndex } = get();
      return quiz ? quiz.content.questions[currentQuestionIndex] : null;
    },
    selectOption: (optionIndex) => {
      set({ selectedOption: optionIndex });
    },
    setAnswerCheckingState: (state) => {
      set({ answerCheckingState: state });
    },
    updateScore: (isCorrect) => {
      set((state) => {
        if (state.scores === null) {
          return {
            scores: {
              correct: isCorrect ? 1 : 0,
              incorrect: isCorrect ? 0 : 1,
            },
          };
        } else {
          return {
            scores: {
              correct: state.scores.correct + (isCorrect ? 1 : 0),
              incorrect: state.scores.incorrect + (isCorrect ? 0 : 1),
            },
          };
        }
      });
    },
    setInitialData: (quiz, currentQuestionIndex, scores) => {
      set({
        quiz,
        currentQuestionIndex,
        scores,
      });
    },
  }),
  Object.is
);

export default useQuizStore;
