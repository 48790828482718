import parse, { Element, HTMLReactParserOptions } from "html-react-parser";

function isRequiredInput(domNode: Element): boolean {
  return domNode.attribs.required !== undefined;
}

export function updateRequiredFields(
  formString: string,
  addRequiredField: (fieldName: string, condition?: string) => void
): void {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.attribs) {
        if (domNode.name === "div" && domNode.attribs.when) {
          const condition = domNode.attribs.when;
          for (let childNode of domNode.children) {
            if (childNode instanceof Element && isRequiredInput(childNode)) {
              // for each required field inside the div, we pass along the condition
              addRequiredField(childNode.attribs.name, condition);
            }
          }
        } else if (isRequiredInput(domNode)) {
          addRequiredField(domNode.attribs.name);
        }
        return domNode;
      }
    },
  };
  parse(formString, options);
}
