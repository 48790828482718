import { Message, MessageType } from "src/types/models";

export function isBotMessage(message: Message): Boolean {
  return (
    (message.type === MessageType.BOT_MESSAGE ||
      message.type === MessageType.HUMAN_BOT_MESSAGE) &&
    !message.is_typing_placeholder
  );
}

export function filterBotMessages(messages: Message[]): Message[] {
  return messages.filter(isBotMessage);
}

export function filterHumanMessages(messages: Message[]): Message[] {
  return messages.filter((m) => !isBotMessage(m));
}
