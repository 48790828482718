import { useSearchParams } from "react-router-dom";
import BotMessage from "src/components/messages/bot-message";
import UserMessage from "src/components/messages/user-message";
import Spinner from "src/components/ui/spinner";
import { filterBotMessages } from "src/libs/messages";
import { MessageType } from "src/types/models";
import { shallow } from "zustand/shallow";

import useInteractiveSideBySideStore from "../../hooks/use-interactive-sxs-store";
import useRefreshOptions from "../../hooks/use-refresh-options";
import useSelectOption from "../../hooks/use-select-option";
import useSelectScenario from "../../hooks/use-select-scenario";
import useUndo from "../../hooks/use-undo";
import ActionBox from "../controls/action-box";
import UndoButton from "../controls/undo-button";
import ScenarioContainer from "../conversation/scenario-container";
import RetrievalOptions from "../retrieval/retrieval-options";
import SxsOptions from "../sxs-options";
import OptionsHeading from "../sxs-options/options-heading";
import ChatErrorMessage from "./chat-error-message";

export default function ConversationBox() {
  const searchParams = useSearchParams()[0];
  const debug = Boolean(searchParams.get("debug"));
  const undo = useUndo();
  const selectOption = useSelectOption();
  const refreshOptions = useRefreshOptions();
  const selectScenario = useSelectScenario();

  const [
    taskData,
    getCurrentConversation,
    getScenarioAtTurn,
    getIsScenarioRequired,
  ] = useInteractiveSideBySideStore(
    (state) => [
      state.taskData,
      state.getCurrentConversation,
      state.getScenarioAtTurn,
      state.getIsScenarioRequired,
    ],
    shallow
  );
  const [isLoadingOptions, hasEnoughOptions] = useInteractiveSideBySideStore(
    (state) => [state.isLoadingOptions, state.hasEnoughOptions],
    shallow
  );
  const chatError = useInteractiveSideBySideStore((state) => state.chatError);
  const currentConversation = getCurrentConversation();
  const isScenarioRequired = getIsScenarioRequired();

  if (!taskData || !currentConversation) {
    return null;
  }

  const history = currentConversation.history;
  const numBotMessages = filterBotMessages(history).length;
  const canSelectOptions = hasEnoughOptions();
  const canUndo = !isLoadingOptions && !canSelectOptions && numBotMessages > 0;
  const comparisonScaleEnabled = taskData.comparison_scale_option !== -1;

  let nextScenario = getScenarioAtTurn(0) as any;
  let currentOptionsHeading = taskData.options_heading;

  return (
    <div className="flex flex-1 flex-col border bg-white p-5 shadow-lg gap-y-4">
      {isScenarioRequired && (
        <ScenarioContainer
          taskUUID={taskData.uuid}
          scenario={nextScenario}
          handleSelectScenario={(selectedScenario) =>
            selectScenario(selectedScenario, 0)
          }
        />
      )}
      {history.map((message, index) => {
        const isBotMessage = message.type === MessageType.BOT_MESSAGE;
        const MessageComponent = isBotMessage ? BotMessage : UserMessage;
        let scenarioDisplay = null;
        if (isScenarioRequired && index > 0) {
          nextScenario = getScenarioAtTurn(index + 1);
          if (nextScenario?.options_heading) {
            currentOptionsHeading = (nextScenario as any).options_heading;
          }
          scenarioDisplay = (
            <ScenarioContainer
              taskUUID={taskData.uuid}
              scenario={nextScenario}
              handleSelectScenario={(selectedScenario) =>
                selectScenario(selectedScenario, index + 1)
              }
            />
          );
        }
        return (
          <div key={message.id}>
            <MessageComponent message={message} />
            {scenarioDisplay && <div className="mt-8"> {scenarioDisplay} </div>}
          </div>
        );
      })}
      <div>
        {canUndo && <UndoButton undo={undo} />}
        {isLoadingOptions && <Spinner />}
      </div>
      {canSelectOptions && (
        <>
          <OptionsHeading heading={currentOptionsHeading} />
          {taskData.retrieval_annotation_form ? (
            <RetrievalOptions
              handleSelectOption={selectOption}
              handleRefreshOptions={refreshOptions}
            />
          ) : (
            <SxsOptions
              handleSelectOption={selectOption}
              handleRefreshOptions={refreshOptions}
              comparisonScaleEnabled={comparisonScaleEnabled}
            />
          )}
        </>
      )}
      {chatError && <ChatErrorMessage message={chatError} />}
      <ActionBox />
      {debug && currentConversation && (
        <div className="text-xs">
          <pre>
            {JSON.stringify(
              currentConversation.multiple_scenarios_config,
              null,
              2
            )}
          </pre>
        </div>
      )}
    </div>
  );
}
