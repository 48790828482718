import React, { ForwardedRef, forwardRef, MouseEventHandler } from "react";
import NewlineSafeReactMarkdown from "src/components/text/newline-safe-react-markdown";
import { Message } from "src/types/models";
import resolveStaticUrl from "src/utils/static-url";

import AnnotatableMessage from "./annotatable-message";

interface AnnotatableBotMessageProps {
  message: Message;
  isSelected: boolean;
  isAnnotated: boolean;
  handleClick?: MouseEventHandler<HTMLDivElement>;
}

const AnnotatableBotMessage = forwardRef(
  (props: AnnotatableBotMessageProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { message, isAnnotated, isSelected, handleClick } = props;
    const messageText = (
      <NewlineSafeReactMarkdown>{message.text}</NewlineSafeReactMarkdown>
    );

    return (
      <div
        className="relative flex items-center justify-start flex-1 my-4"
        ref={ref}>
        <img
          className="inline-block w-8 h-8 rounded-full"
          src={resolveStaticUrl("img/bot_icon.png")}
          alt="bot-icon"
        />
        <div
          className="relative ml-2 hover:cursor-pointer"
          onClick={(e) => (handleClick ? handleClick(e) : null)}>
          <AnnotatableMessage isSelected={isSelected} isAnnotated={isAnnotated}>
            {messageText}
          </AnnotatableMessage>
        </div>
      </div>
    );
  }
);

export default AnnotatableBotMessage;
