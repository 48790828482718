import { CheckIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useApiClient } from "src/api";
import { parseForm } from "src/libs/forms";
import { Dialogue, DialogueFeedbackForm } from "src/types/models";

interface DialogueFeedbackFormProps {
  currentDialogue: Dialogue;
}

export default function DialogueFeedbackFormComponent({
  currentDialogue,
}: DialogueFeedbackFormProps) {
  const apiClient = useApiClient();
  const [feedbackForm, setFeedbackForm] = useState<DialogueFeedbackForm | null>(
    null
  );
  const [feedbackData, setFeedbackData] = useState<{
    [k: string]: FormDataEntryValue;
  } | null>(null);
  const isFormSubmitted = !!feedbackData;

  useEffect(() => {
    if (!feedbackForm) {
      const getFeedbackForm = async () => {
        await apiClient
          .get(`/dialogues/${currentDialogue.id}/feedback-form/`)
          .then(({ form, data }) => {
            setFeedbackForm(form);
            setFeedbackData(data);
          });
      };
      getFeedbackForm();
    }
  }, [feedbackForm, apiClient, currentDialogue.id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = Object.fromEntries(new FormData(form));
    await apiClient
      .post(`/dialogues/${currentDialogue.id}/feedback/`, {
        feedback_data: formData,
      })
      .then(() => {
        setFeedbackData(formData);
      });
  };

  if (!feedbackForm) {
    return null;
  }

  return (
    <div className="p-4 mt-4 bg-gray-100 rounded-sm shadow-sm">
      <div className="flex justify-between flex-1">
        <div className="text-xl text-gray-900">Feedback</div>
        {isFormSubmitted && (
          <span className="flex items-center" role="status">
            <CheckIcon className="w-4 h-4 text-indigo-500" aria-hidden="true" />
            <span className="text-xs text-gray-700">Saved</span>
          </span>
        )}
      </div>
      <form className="flex flex-col mt-4 text-sm" onSubmit={handleSubmit}>
        <fieldset disabled={isFormSubmitted}>
          {parseForm(feedbackForm.value, {
            formData: feedbackData,
            isDisabled: isFormSubmitted,
          })}
          {!isFormSubmitted && (
            <div className="flex mt-4">
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Submit
              </button>
            </div>
          )}
        </fieldset>
      </form>
    </div>
  );
}
