import { ChangeEventHandler, ReactElement } from "react";
import NewlineSafeReactMarkdown from "src/components/text/newline-safe-react-markdown";
import { LocalAnnotationData } from "src/features/annotation-tasks/types";
import { parseForm } from "src/libs/forms";

import { RetrievalOption } from "../../types";

type Props = {
  option: RetrievalOption;
  annotationData: LocalAnnotationData;
  onChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
  >;
};

export default function OptionTab(props: Props): ReactElement {
  const { option, annotationData, onChange } = props;
  return (
    <>
      <NewlineSafeReactMarkdown className="text-md font-bold mb-4">
        {option.text}
      </NewlineSafeReactMarkdown>
      {option.annotationForm && (
        <form>
          {parseForm(option.annotationForm, {
            formData: annotationData,
            onChange,
            extras: {
              searchResults: option.searchResults,
            },
          })}
        </form>
      )}
    </>
  );
}
