type Props = {
  header?: JSX.Element;
  footer?: JSX.Element;
  children: React.ReactNode;
};
export default function Card(props: Props) {
  const { header, children, footer } = props;
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-lg">
      {header && <div className="px-4 py-5 sm:px-6">{header}</div>}
      <div className="p-4 sm:p-6 flex flex-col gap-y-4">{children}</div>
      {footer && <div className="p-4 sm:px-6">{footer}</div>}
    </div>
  );
}
