import { Element, attributesToProps } from "html-react-parser";
import { ChangeEventHandler, ReactElement } from "react";

import { FormData } from "./types";

type ParseChoiceInputArgs = {
  formData: FormData;
  isDisabled: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export default function parseChoiceInput(
  domNode: Element,
  args: ParseChoiceInputArgs
): ReactElement {
  const { formData, isDisabled, onChange } = args;
  const isChecked = Boolean(
    formData && formData[domNode.attribs.name] === domNode.attribs.value
  );
  const props: any = attributesToProps(domNode.attribs);
  const isFormControlled = Boolean(onChange);
  if (isFormControlled) {
    props.checked = Boolean(isChecked);
    props.onChange = onChange;
  } else {
    props.defaultChecked = isChecked;
  }
  return <input disabled={isDisabled} {...props} />;
}
