import { SerializedPolicyBatchResponse } from "src/types/models";

import { StaticConversation } from "./models";

export type TaskSettings = {
  uuid: string;
  public_id: string;
  public_name: string;
  type: StaticTaskType;
  prolific_redirect_url: string;
  button_explanation: string;
  instructions: string;
  markdown_instruction?: string;
  ratings_per_time: number;
  show_both_options_are_good: boolean;
  ask_explanation: boolean;
  use_adhoc_assignment_strategy: boolean;
  policies: SerializedPolicyBatchResponse;
};

export type TaskData = TaskSettings & {
  convos: StaticConversation[];
};

export enum StaticTaskType {
  TEXT = 1,
  AUDIO = 2,
}

export enum TaskState {
  NEED_CONFIDENTIALITY_AGREEMENT,
  NOT_STARTED,
  STARTING,
  IN_PROGRESS,
  FINISHED,
  ERROR,
  LOADING_NEXT_CONVERSATION,
}
