import { CheckIcon, XCircleIcon } from "@heroicons/react/solid";
import classNames from "src/utils/class-names";

interface StatItem {
  name: string;
  isWinner?: boolean;
  count?: number;
}

export default function StaticLabelStats({
  total,
  stats,
}: {
  total?: number;
  stats: StatItem[];
}) {
  return (
    <div>
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        Number of Ratings in Total: {total}
      </h3>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.count}
                <span className="ml-2 text-sm font-medium text-gray-500">
                  out of {total}
                </span>
              </div>

              <div
                className={classNames(
                  item.isWinner
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800",
                  "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                )}>
                {item.isWinner ? (
                  <CheckIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}

                <span className="sr-only">
                  {" "}
                  {item.isWinner ? "Increased" : "Decreased"} by{" "}
                </span>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
