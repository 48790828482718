import { CheckCircleIcon } from "@heroicons/react/solid";
import ActionBox from "src/components/controls/action-box";
import ProlificRedirectUrl from "src/components/prolific/redirect-url";

type Props = {
  prolificRedirectUrl: string;
};

export default function CompleteAction(props: Props) {
  const { prolificRedirectUrl } = props;

  return (
    <ActionBox
      icon={
        <CheckCircleIcon
          className="w-5 h-5 text-green-400"
          aria-hidden="true"
        />
      }
      title="Task Completed 🏆"
      description="Thank you for participating in our study. Please click the link below to go back to Prolific."
      callToAction={<ProlificRedirectUrl url={prolificRedirectUrl} />}
    />
  );
}
