import { useMemo } from "react";
import { withApiClient } from "src/api";
import { useApiClient } from "src/api/client";

import * as quiz from "./quiz";

export default function useApi() {
  const apiClient = useApiClient();
  return useMemo(
    () => ({
      quiz: withApiClient(quiz, apiClient),
    }),
    [apiClient]
  );
}
