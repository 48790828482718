import { Element, attributesToProps } from "html-react-parser";
import { ChangeEventHandler, ReactElement } from "react";

import { FormData } from "./types";

type ParseTextInputArgs = {
  formData: FormData;
  isDisabled: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export default function parseTextInput(
  domNode: Element,
  args: ParseTextInputArgs
): ReactElement {
  const { isDisabled, onChange, formData } = args;
  const props: any = attributesToProps(domNode.attribs);
  const value = formData?.[domNode.attribs.name]?.toString() || "";
  if (onChange) {
    props.value = value;
    props.onChange = onChange;
  } else {
    props.defaultValue = value;
  }
  return domNode.name === "textarea" ? (
    <input disabled={isDisabled} {...props} />
  ) : (
    <textarea disabled={isDisabled} {...props} />
  );
}
