import { useParams } from "react-router-dom";

import { Conversation } from "../components/conversation";

type Params = {
  conversationId: string;
};

export default function Preview() {
  const { conversationId } = useParams<Params>();

  if (!conversationId) return null;
  const id = Number.parseInt(conversationId);

  return <Conversation conversationId={id} />;
}
