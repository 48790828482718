import {
  ActivityState,
  Dialogue,
  DialogueActivity,
  DialogueState,
} from "src/types/models";
import { createWithEqualityFn } from "zustand/traditional";

interface DialoguesStateSlice {
  dialogues: Dialogue[];
  addDialogue: (dialogue: Dialogue) => void;
  setDialogues: (dialogues: Dialogue[]) => void;
  currentActivity: DialogueActivity | null;
  setCurrentActivity: (activity: DialogueActivity) => void;
  currentDialogue: Dialogue | null;
  currentDialogueIndex: number;
  setCurrentDialogueIndex: (currentDialogueIndex: number) => void;
  dialogueActivitiesStates: { [id: number]: ActivityState };
  setDialogueActivitiesStates: (dialogueActivitiesStates: {
    [id: number]: ActivityState;
  }) => void;
  setDialogueCompleted: (id: number) => void;
  isCurrentDialogueCompleted: () => boolean;
  isAllDialoguesCompleted: (numDialoguesToComplete?: number) => boolean;
}

const useDialoguesStore = createWithEqualityFn<DialoguesStateSlice>()(
  (set, get) => ({
    dialogues: [],
    dialogueActivitiesStates: {},
    addDialogue: (dialogue: Dialogue) => {
      if (get().dialogues.find((d) => d.id === dialogue.id)) {
        return;
      }
      set((state) => {
        const updatedDialogues = [...state.dialogues, dialogue];
        const newDialogueIndex = updatedDialogues.length - 1;
        return {
          ...state,
          dialogues: updatedDialogues,
          currentDialogueIndex: newDialogueIndex,
          currentDialogue: dialogue,
        };
      });
    },
    setDialogues: (dialogues: Dialogue[]) => set((state) => ({ dialogues })),
    currentActivity: null,
    setCurrentActivity: (currentActivity: DialogueActivity) =>
      set((state) => ({ currentActivity })),
    currentDialogueIndex: 0,
    currentDialogue: null,
    setCurrentDialogueIndex(currentDialogueIndex: number) {
      set((state) => ({
        currentDialogueIndex: currentDialogueIndex,
        currentDialogue: state.dialogues[currentDialogueIndex],
      }));
    },
    setDialogueActivitiesStates: (dialogueActivitiesStates: {
      [id: number]: ActivityState;
    }) => set((state) => ({ dialogueActivitiesStates })),
    setDialogueCompleted(dialogueId: number) {
      set((state) => {
        const updatedDialogues = state.dialogues.map((dialogue) => {
          return dialogue.id === dialogueId
            ? { ...dialogue, state: DialogueState.COMPLETED }
            : dialogue;
        });
        return {
          dialogues: updatedDialogues,
          currentDialogue: updatedDialogues[state.currentDialogueIndex],
          dialogueActivitiesStates: {
            ...state.dialogueActivitiesStates,
            [dialogueId]: ActivityState.COMPLETED,
          },
        };
      });
    },
    isCurrentDialogueCompleted: () =>
      get().currentDialogue?.state === DialogueState.COMPLETED,
    isAllDialoguesCompleted: (numDialoguesToComplete?: number) => {
      const { dialogues } = get();
      if (dialogues.length === 0) {
        return false;
      }
      if (numDialoguesToComplete && dialogues.length < numDialoguesToComplete) {
        return false;
      }
      for (const dialogue of dialogues) {
        if (dialogue.state !== DialogueState.COMPLETED) {
          return false;
        }
      }
      return true;
    },
  }),
  Object.is
);

export default useDialoguesStore;
