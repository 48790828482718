import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/solid";
import { useMemo } from "react";
import NewlineSafeReactMarkdown from "src/components/text/newline-safe-react-markdown";
import useEmojiReaction from "src/hooks/use-emoji-reaction";
import { Message } from "src/types/models";
import classNames from "src/utils/class-names";
import resolveStaticUrl from "src/utils/static-url";

interface BotMessageProp {
  message: Message;
  showReaction?: boolean;
  isAnnotated?: boolean;
}

export default function BotMessage({
  message,
  showReaction = true,
  isAnnotated = false,
}: BotMessageProp) {
  const [emojiReaction, setEmojiReaction] = useEmojiReaction(message);
  const isThumbUp = useMemo(() => emojiReaction === "👍", [emojiReaction]);
  const isThumbDown = useMemo(() => emojiReaction === "👎", [emojiReaction]);
  return (
    <div className="flex items-center justify-start flex-1">
      <img
        className="inline-block w-8 h-8 rounded-full"
        src={resolveStaticUrl("img/bot_icon.png")}
        alt="bot-icon"
      />
      <div className="ml-2">
        <div
          className={classNames(
            "px-4 py-2 text-sm font-medium rounded-lg ",
            isAnnotated
              ? "bg-green-100 group-hover:text-green-900"
              : "bg-gray-100 group-hover:text-gray-900"
          )}>
          <NewlineSafeReactMarkdown>{message.text}</NewlineSafeReactMarkdown>
        </div>
      </div>
      {showReaction && (
        <span className="flex flex-row">
          <ThumbUpIcon
            className={classNames(
              "w-5 h-5 ml-1 hover:cursor-pointer",
              isThumbUp
                ? "text-blue-300"
                : "text-gray-400 opacity-20 hover:text-blue-300 hover:opacity-100"
            )}
            onClick={() => setEmojiReaction("👍")}
          />
          <ThumbDownIcon
            className={classNames(
              "w-5 h-5 ml-1 hover:cursor-pointer",
              isThumbDown
                ? "text-red-300"
                : "text-gray-400 opacity-20 hover:text-red-300 hover:opacity-100"
            )}
            onClick={() => setEmojiReaction("👎")}
          />
        </span>
      )}
    </div>
  );
}
