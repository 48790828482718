import { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import OptionText from "src/components/side-by-side/option-text";
import AnnotationFormContainer from "src/features/annotation-tasks/components/annotation-form-container";
import { parseForm } from "src/libs/forms";
import { BotResponseOption } from "src/types/models";
import { shuffleArray } from "src/utils/array";
import { shallow } from "zustand/shallow";

import useInteractiveSideBySideStore from "../../hooks/use-interactive-sxs-store";
import { SelectOptionHandler } from "../../types";
import SxsActions from "../controls/sxs-actions";

interface Props {
  handleSelectOption: SelectOptionHandler;
  handleRefreshOptions: () => Promise<void>;
  comparisonScaleEnabled: boolean;
}

export default function SxsOptions({
  handleSelectOption,
  handleRefreshOptions,
  comparisonScaleEnabled,
}: Props) {
  const searchParams = useSearchParams()[0];
  const showModelNames = Boolean(
    searchParams.get("showModelNames") || searchParams.get("debug")
  );
  const [options, hasEnoughOptions, taskData] = useInteractiveSideBySideStore(
    (state) => [state.options, state.hasEnoughOptions, state.taskData],
    shallow
  );
  const annotationForm =
    taskData?.annotation_forms && taskData?.annotation_forms.length > 0
      ? taskData?.annotation_forms[0]
      : null;
  const annotationFormRef = useRef<HTMLFormElement>(null);
  const shuffledOptions = useMemo(() => shuffleArray(options), [options]);

  if (!hasEnoughOptions()) {
    return null;
  }

  return (
    <div className="flex flex-col flex-1 my-4 gap-y-4">
      <div className="grid grid-cols-1 mt-4 gap-y-4 md:grid-cols-2 md:gap-x-4">
        {shuffledOptions.map((option: BotResponseOption, index: number) => (
          <OptionText
            text={option.text}
            modelName={showModelNames ? option.model : null}
            option={taskData!.model_options[index]}
            key={`sxs-option-${index}`}
          />
        ))}
      </div>
      <div className="w-full p-5 my-4 overflow-y-auto text-sm text-gray-900 rounded-lg bg-gray-50">
        {annotationForm && (
          <AnnotationFormContainer isSaving={null}>
            <form className="annotation-form" ref={annotationFormRef}>
              <div className="mb-4 text-md">{annotationForm.description}</div>

              {parseForm(annotationForm.value)}
            </form>
          </AnnotationFormContainer>
        )}
        <SxsActions
          comparisonScaleEnabled={comparisonScaleEnabled}
          comparisonScaleOption={taskData?.comparison_scale_option}
          shuffledOptions={shuffledOptions}
          select={(args) => {
            if (annotationFormRef && annotationFormRef.current) {
              const formData = new FormData(annotationFormRef.current);
              const annotationData = Object.fromEntries(formData);
              args.annotation_data = annotationData;
            }
            handleSelectOption(args);
          }}
          handleRefreshOptions={handleRefreshOptions}
        />
      </div>
    </div>
  );
}
