import {
  InteractiveConversation,
  InteractiveConversationState,
  SingleScenarioConfig,
  TaskData,
  TaskState,
} from "src/features/interactive-sxs-tasks/types";
import { filterBotMessages } from "src/libs/messages";
import { Scenario } from "src/types/models";

type ScenarioSelector = { type: "selector"; config?: SingleScenarioConfig };
type ScenarioWithInstruction = Scenario & {
  type: "scenario";
  instruction?: string;
  options_heading?: string;
};

export type MaybeScenarioOrSelector =
  | ScenarioSelector
  | ScenarioWithInstruction
  | undefined;

/**
 * Given a turn in the conversation, return the scenario that should be used for that turn onwards
 * or "SELECT_NEW" if a new scenario should be selected.
 */
export function getScenarioAtTurn(
  turn: number,
  conversation: InteractiveConversation
): MaybeScenarioOrSelector {
  const {
    scenario_checkpoints: scenarioCheckpoints,
    multiple_scenarios_config: multipleScenarioConfigs,
    scenario,
  } = conversation;
  const scenarioConfigs = multipleScenarioConfigs?.single_scenario_configs;
  const isSingleScenario = !scenarioConfigs || scenarioConfigs.length === 0;

  if (isSingleScenario) {
    if (turn > 0) {
      return;
    }
    return scenario ? { ...scenario, type: "scenario" } : { type: "selector" };
  }

  if (!scenarioCheckpoints || scenarioCheckpoints.length === 0) {
    return {
      type: "selector",
      config: scenarioConfigs[0],
    };
  }

  const currentCheckpointIndex = scenarioCheckpoints.findIndex(
    (c) => c.turn === turn
  );
  if (currentCheckpointIndex >= 0) {
    const currentCheckpoint = scenarioCheckpoints[currentCheckpointIndex];
    const currentConfig = scenarioConfigs[currentCheckpointIndex];
    return {
      ...currentCheckpoint.scenario,
      instruction: currentConfig.instruction,
      options_heading: currentConfig.options_heading,
      type: "scenario",
    };
  }

  const lastCheckpoint = scenarioCheckpoints[scenarioCheckpoints.length - 1];
  const lastConfig = scenarioConfigs[scenarioCheckpoints.length - 1]!;
  const nextConfig = scenarioConfigs[scenarioCheckpoints.length];

  if (nextConfig) {
    const nextScenarioTurn = lastCheckpoint.turn + lastConfig.num_ai_turns * 2; // need to take into account user turns
    if (nextScenarioTurn === turn) {
      return {
        type: "selector",
        config: nextConfig,
      };
    }
  }
}

/**
 * Given some task data, return the inital state of the task.
 */
export function getTaskState(taskData: TaskData | null): TaskState {
  if (taskData === null) {
    return TaskState.NEED_CONFIDENTIALITY_AGREEMENT;
  }
  const currentConversation = taskData.conversations.find(
    (conversation) =>
      conversation.state !== InteractiveConversationState.COMPLETED
  );
  if (!currentConversation) {
    return TaskState.FINISHED;
  }

  if (canCompleteConversation(currentConversation)) {
    return TaskState.CONVERSATION_COMPLETED;
  }

  if (taskData.multiple_scenarios_config) {
    // select scenario for multiple scenarios
    if (currentConversation.scenario_checkpoints.length === 0) {
      return TaskState.SELECT_SCENARIO;
    }
  } else {
    // select scenario for single scennario
    if (taskData.require_scenario && !currentConversation.scenario) {
      return TaskState.SELECT_SCENARIO;
    }
  }

  return TaskState.CONVERSATION_IN_PROGRESS;
}

export function getRandomNumber(min: number, max: number): number {
  return Math.floor(min + Math.random() * (max - min));
}

export function canCompleteConversation(
  currentConversation: InteractiveConversation
) {
  if (currentConversation.multiple_scenarios_config) {
    const numTurns =
      currentConversation.multiple_scenarios_config.single_scenario_configs.reduce(
        (acc, config) => acc + config.num_ai_turns,
        0
      );
    return filterBotMessages(currentConversation.history).length >= numTurns;
  }

  return (
    filterBotMessages(currentConversation.history).length >=
    currentConversation.num_ai_turns!
  );
}
