import { useState } from "react";
import {
  SerializedCompletePolicyDetail,
  SerializedPolicyTopic,
  SerializedPolicyDetailExample,
  SerializedPolicyBatchResponse,
} from "src/types/models";
import classNames from "src/utils/class-names";

interface PolicyDetailProps {
  detail: SerializedCompletePolicyDetail;
}

function PolicyDetail({ detail: { detail, examples } }: PolicyDetailProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <>
      <li className="text-sm text-gray-700 font-light mb-2">{detail.detail}</li>
      {examples.length > 0 && (
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 text-sm font-regular border rounded-md shadow-sm text-gray hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 "
          onClick={() => setIsCollapsed((c) => !c)}>
          {isCollapsed ? "Show Examples" : "Hide Examples"}
        </button>
      )}

      <div
        className={classNames(
          "flex flex-col gap-2 py-2",
          isCollapsed ? "hidden" : "flex"
        )}>
        {examples.map(
          ({ example, turns, completions }: SerializedPolicyDetailExample) => (
            <div className="p-4 border rounded-md" key={example.id}>
              <div className="flex flex-col gap-2">
                <div>
                  <div className="text-xs text-gray-500 font-semibold mb-1">
                    CONTEXT
                  </div>
                  {turns.map((turn) => (
                    <p
                      className="text-sm text-gray-700 font-light mb-1 ml-2"
                      key={turn.id}>
                      {turn.type}: {turn.text}
                    </p>
                  ))}
                </div>
                {completions.map((completion) => (
                  <div key={completion.id}>
                    <div className="text-xs text-gray-500 font-semibold mb-1">
                      {completion.quality} RESPONSE
                    </div>
                    <div className="text-sm text-gray-700 font-light mb-1 ml-2">
                      {completion.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}

interface PolicyDisplayProps {
  topic: SerializedPolicyTopic;
  details: SerializedCompletePolicyDetail[];
}

function PolicyDisplay({ topic, details }: PolicyDisplayProps) {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-col font-semibold text-gray text-lg">
        {topic.topic.toUpperCase()}
      </div>
      <div className="flex flex-col gap-4">
        <ol>
          {details.map((detail: SerializedCompletePolicyDetail) => (
            <PolicyDetail detail={detail} key={detail.detail.id} />
          ))}
        </ol>
      </div>
    </div>
  );
}

interface PoliciesDisplayProps {
  policies: SerializedPolicyBatchResponse;
}

export function PoliciesDisplay({ policies }: PoliciesDisplayProps) {
  return (
    <div className="flex flex-col gap-4 overflow-scroll max-h-96">
      {policies.map(({ topic, details }) => (
        <PolicyDisplay key={topic.id} topic={topic} details={details} />
      ))}
    </div>
  );
}
