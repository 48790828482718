import { Scenario } from "src/types/models";
import classNames from "src/utils/class-names";

export enum BubbleColor {
  INDIGO,
  YELLOW,
}

interface Props {
  scenario: Scenario;
  allowReassign?: boolean;
  reassignScenario?: () => unknown;
  color?: BubbleColor;
}

const BUBBLE_COLOR_CLASSES = {
  [BubbleColor.INDIGO]: "text-indigo-900 bg-indigo-100",
  [BubbleColor.YELLOW]: "text-yellow-900 bg-yellow-50",
};

export default function ScenarioNameHeader({
  scenario,
  allowReassign,
  reassignScenario,
  color = BubbleColor.INDIGO,
}: Props) {
  return (
    <div
      className={classNames(
        "p-4 rounded-md justify-between flex prose max-w-none",
        BUBBLE_COLOR_CLASSES[color]
      )}>
      <span>
        <strong>Scenario:</strong> {scenario.name}
      </span>
      {allowReassign && (
        <button
          className="ml-2 text-indigo-600 hover:text-indigo-900"
          onClick={reassignScenario}>
          Reassign
        </button>
      )}
    </div>
  );
}
