import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Modal from "src/components/ui/modal";

type Props = {
  active: boolean;
  initial?: string;
  onChange: (note: string) => void;
  onCancel: () => void;
};

export const BookmarkEditor = ({
  active,
  initial,
  onChange,
  onCancel,
}: Props) => {
  const [note, setNote] = useState(initial ?? "");

  if (!active) return null;

  return (
    <Modal
      title="Create a new bookmark"
      submitButtonText="Create bookmark"
      onSubmit={() => onChange(note)}
      onClose={onCancel}>
      <TextareaAutosize
        value={note}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={(e) => setNote(e.target.value)}
        onFocus={(e) => e.target.select()}
        placeholder="Optional: Add a note for your bookmark"
        autoFocus
        minRows={3}
      />
    </Modal>
  );
};

export default BookmarkEditor;
