import { DotsCircleHorizontalIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import DialoguesNavigationHeader from "src/components/dialogue/dialogues-navigation-header";
import TaskInstruction from "src/components/task/task-instruction";
import PageHeader from "src/components/ui/page-header";
import useDialoguesStore from "src/hooks/use-dialogues-store";
import useMessages from "src/hooks/use-messages";
import {
  ActivityState,
  Message,
  SerializedPolicyBatchResponse,
  Task,
} from "src/types/models";
import { shallow } from "zustand/shallow";

import {
  useAnnotationData,
  useAnnotationForms,
  useNextDialogueHandler,
} from "../hooks/use-annotation";
import useTargetSentencesStore from "../hooks/use-target-sentences";
import { isMessageAnnotated } from "../utils";
import AnnotatableBotMessage from "./annotatable-bot-message";
import AnnotatableBotMessageBySentence from "./annotatable-bot-message-by-sentence";
import AnnotationAction from "./annotation-action";
import DefaultStaticAnnotationForm from "./default-static-annotation-form";
import DefaultStaticDialogueContext from "./default-static-dialogue-context";
import RetrievalStaticAnnotationForm from "./retrieval-static-annotaion-form";
import RetrievalStaticDialogueContext from "./retrieval-static-dialogue-context";

interface AnnotationTaskProps {
  task: Task;
  policies: SerializedPolicyBatchResponse;
}

export default function StaticAnnotationTask({
  task,
  policies,
}: AnnotationTaskProps) {
  const [dialogues, currentDialogue, currentActivity, currentDialogueIndex] =
    useDialoguesStore(
      (state) => [
        state.dialogues,
        state.currentDialogue,
        state.currentActivity,
        state.currentDialogueIndex,
      ],
      shallow
    );
  const { context, format } = currentDialogue!.static_context_data!;
  const isRetrieval = format === "retrieval";
  const staticContext = useMemo(() => JSON.parse(context), [context]);
  const currentDialogueId = currentDialogue!.id;
  const messages = useMessages(currentDialogueId)[0];

  // annotation forms
  const { messageAnnotationForm, messagesAnnotationFormRequiredFields } =
    useAnnotationForms(task);

  // annotation data
  const {
    messagesAnnotationData,
    messageAnnotationInputChangeHandler,
    isSavingAnnotationData,
  } = useAnnotationData(task, currentDialogue!);

  // dialogue handlers
  const { nextDialogueHandler, completeDialogueHandler } =
    useNextDialogueHandler(task);

  // scrolling & selecting message
  const [hasScrolled, setHasScrolled] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [selectedMessageRef, setSelectedMessageRef] =
    useState<HTMLDivElement | null>();

  const [
    targetSentences,
    setTargetSentences,
    currentTargetSentenceIndex,
    setCurrentTargetSentenceIndex,
  ] = useTargetSentencesStore(
    (state) => [
      state.targetSentences,
      state.setTargetSentences,
      state.currentTargetSentenceIndex,
      state.setCurrentTargetSentenceIndex,
    ],
    shallow
  );

  // user's progress
  let isDialogueCompleted = false;
  if (selectedMessage) {
    if (isRetrieval) {
      // loop through all target sentences
      // check if the selected message is annotated for that target sentence
      for (const sentence of targetSentences) {
        if (
          !isMessageAnnotated(
            messagesAnnotationData[selectedMessage.id]?.[sentence],
            messagesAnnotationFormRequiredFields
          )
        ) {
          isDialogueCompleted = false;
          break;
        }
        isDialogueCompleted = true;
      }
    } else {
      isDialogueCompleted = isMessageAnnotated(
        messagesAnnotationData[selectedMessage.id],
        messagesAnnotationFormRequiredFields
      );
    }
  }
  const isLastDialogue = currentDialogueIndex === dialogues.length - 1;

  useEffect(() => {
    if (isRetrieval) {
      // initialize target sentences
      const targetSentences = staticContext.target_sentences;
      setTargetSentences(targetSentences);
      setCurrentTargetSentenceIndex(0);
    }
  }, [
    staticContext.target_sentences,
    isRetrieval,
    setTargetSentences,
    setCurrentTargetSentenceIndex,
  ]);

  // set selected message and scroll to the selected message
  useEffect(() => {
    if (messages.length > 0) {
      setSelectedMessage(messages[0]);
    }
    if (!hasScrolled && selectedMessageRef) {
      selectedMessageRef?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setHasScrolled(true);
    }
  }, [messages, hasScrolled, selectedMessageRef]);

  useEffect(() => {
    if (
      isDialogueCompleted &&
      currentActivity &&
      currentActivity?.state !== ActivityState.COMPLETED
    ) {
      completeDialogueHandler();
    }
  }, [isDialogueCompleted, currentActivity, completeDialogueHandler]);

  let annotationAction = null;

  if (isDialogueCompleted) {
    let callToAction = null;
    let title = "Almost there 🚀🚀";
    let description = null;
    let icon = (
      <DotsCircleHorizontalIcon
        className="w-5 h-5 text-indigo-500"
        aria-hidden="true"
      />
    );
    if (isDialogueCompleted) {
      callToAction = isLastDialogue ? (
        <a
          href={task.prolific_redirect_url}
          className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Go back to Prolific
        </a>
      ) : (
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium bg-green-400 border border-transparent rounded-md shadow-sm text-gray hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={nextDialogueHandler}>
          Next Conversation
        </button>
      );
      title = isLastDialogue
        ? "Last Conversation Completed 🏆"
        : "Conversation Completed 🏆";
      description = isLastDialogue
        ? "Thank you for participating in our study. Please click the link below to go back to Prolific."
        : "Please click the button below to advance to the next conversation. Or you can continue adjusting labels for this conversation, if necessary.";
      icon = (
        <CheckCircleIcon
          className="w-5 h-5 text-green-500"
          aria-hidden="true"
        />
      );
    }
    annotationAction = (
      <AnnotationAction
        isSavingData={isSavingAnnotationData}
        icon={icon}
        numMessagesWithLabels={isDialogueCompleted ? 1 : 0}
        totalMessages={1}
        title={title}
        description={description}
        callToAction={callToAction}
        dialogueForm={null}
      />
    );
  } else {
    annotationAction = (
      <AnnotationAction
        isSavingData={isSavingAnnotationData}
        icon={
          <DotsCircleHorizontalIcon
            className="w-5 h-5 text-indigo-500"
            aria-hidden="true"
          />
        }
        numMessagesWithLabels={isDialogueCompleted ? 1 : 0}
        totalMessages={1}
        title="In Progress 🚀"
        description={"Please label the last message in this conversation."}
        callToAction={null}
      />
    );
  }

  let messageForm = null;
  if (selectedMessage && messageAnnotationForm) {
    if (isRetrieval) {
      const currentSentence = targetSentences[currentTargetSentenceIndex];
      messageForm = (
        <RetrievalStaticAnnotationForm
          selectedMessage={selectedMessage}
          formContent={messageAnnotationForm}
          formData={
            messagesAnnotationData[selectedMessage.id]?.[currentSentence]
          }
          requiredFields={messagesAnnotationFormRequiredFields}
          onChange={messageAnnotationInputChangeHandler(
            selectedMessage.id,
            currentSentence
          )}
          targetSentences={staticContext.target_sentences}
          searchQuery={staticContext.search_query}
          searchResults={staticContext.search_results}
        />
      );
    } else {
      messageForm = (
        <DefaultStaticAnnotationForm
          selectedMessage={selectedMessage}
          formContent={messageAnnotationForm}
          formData={messagesAnnotationData[selectedMessage.id]}
          onChange={messageAnnotationInputChangeHandler(selectedMessage.id)}
        />
      );
    }
  }

  const messageFormContainerStyle: CSSProperties = selectedMessageRef
    ? {
        position: "relative",
        top: Math.max(selectedMessageRef.offsetTop - 100, 0),
      }
    : {};

  return (
    <>
      <PageHeader
        heading={task.public_name}
        subHeading="Conversation Labels"
        publicId={task.public_id}
      />
      <main className="px-4 pb-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <TaskInstruction
          instruction={task.instruction}
          markdownInstruction={task.markdown_instruction}
          policies={policies}
        />
        <div className="grid w-full grid-cols-2 gap-4 mb-4">
          <div className="w-full mx-auto mt-4">
            <DialoguesNavigationHeader
              currentDialogueIndex={currentDialogueIndex}
              numDialogues={dialogues.length}
            />
            <div className="relative flex flex-col flex-1 p-5 overflow-y-hidden">
              <div className="px-4 overflow-y-auto" id="messages">
                {isRetrieval ? (
                  <>
                    <RetrievalStaticDialogueContext
                      staticContext={staticContext}
                    />
                    {selectedMessage && (
                      <AnnotatableBotMessageBySentence
                        message={selectedMessage}
                        ref={setSelectedMessageRef}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <DefaultStaticDialogueContext
                      staticContext={staticContext}
                    />

                    {/* Annotatable bot message */}
                    {selectedMessage && (
                      <AnnotatableBotMessage
                        message={selectedMessage}
                        key={selectedMessage.id}
                        isAnnotated={isMessageAnnotated(
                          messagesAnnotationData[selectedMessage.id],
                          messagesAnnotationFormRequiredFields
                        )}
                        isSelected={true}
                        handleClick={(e) => setSelectedMessage(selectedMessage)}
                        ref={setSelectedMessageRef}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="w-full mt-4 text-sm">
            <div style={messageFormContainerStyle}>
              {annotationAction}
              {messageForm}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
