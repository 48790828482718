import { useCallback } from "react";
import { TaskState } from "src/features/interactive-sxs-tasks/types";
import { Scenario } from "src/types/models";

import useApi from "../api";
import useInteractiveSideBySideStore from "./use-interactive-sxs-store";

export default function useSelectScenario() {
  const api = useApi();
  const [getCurrentConversation, selectScenario, setTaskState, setHistory] =
    useInteractiveSideBySideStore((state) => [
      state.getCurrentConversation,
      state.selectScenario,
      state.setTaskState,
      state.setHistory,
    ]);

  const currentConversation = getCurrentConversation();

  const handleSelectScenario = useCallback(
    async (selectedScenario: Scenario, turn: number) => {
      if (!currentConversation) {
        return;
      }
      const history = await api.scenarios.select(
        currentConversation.id,
        selectedScenario,
        turn
      );
      selectScenario(selectedScenario, turn);
      setTaskState(TaskState.CONVERSATION_IN_PROGRESS);
      if (history.length > 0) {
        setHistory(history);
      }
    },
    [api, currentConversation, selectScenario, setTaskState, setHistory]
  );

  return handleSelectScenario;
}
