import { useParams } from "react-router-dom";
import ConfidentialityAgreementModal from "src/components/modal/confidentiality-agreement-modal";
import StartTaskError from "src/components/task/start-task-error";
import TaskCompleteNotice from "src/components/task/task-complete-notice";
import LoadingScreen from "src/components/ui/loading-screen";
import useInteractiveSideBySideStore from "src/features/interactive-sxs-tasks/hooks/use-interactive-sxs-store";
import useStartTask from "src/features/interactive-sxs-tasks/hooks/use-start-task";
import { TaskState } from "src/features/interactive-sxs-tasks/types";
import { shallow } from "zustand/shallow";

import TaskContent from "../components/task-content";

export default function InteractiveSxsTask() {
  const taskId = useParams().taskId!;

  const [startError, taskState, taskData, setTaskState] =
    useInteractiveSideBySideStore(
      (state) => [
        state.startError,
        state.taskState,
        state.taskData,
        state.setTaskState,
      ],
      shallow
    );

  // start the task
  useStartTask();

  switch (taskState) {
    case TaskState.NEED_CONFIDENTIALITY_AGREEMENT:
      return (
        <ConfidentialityAgreementModal
          onAgree={() => setTaskState(TaskState.NOT_STARTED)}
        />
      );
    case TaskState.ERROR:
      return <StartTaskError error={startError} taskId={taskId} />;
    case TaskState.STARTING:
      return <LoadingScreen />;
    case TaskState.FINISHED:
      return (
        <TaskCompleteNotice
          prolificRedirectUrl={taskData!.prolific_redirect_url}
        />
      );
    default:
      return <TaskContent />;
  }
}
