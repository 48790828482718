import { MouseEventHandler, useCallback, useState } from "react";

type Props = {
  onSubmit: (explanation: string) => void;
};

export default function Explanation(props: Props) {
  const { onSubmit } = props;
  const [explanation, setExplanation] = useState("");

  const handleSubmit: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (explanation === "") return;
      onSubmit(explanation);
    },
    [onSubmit, explanation]
  );

  return (
    <div className="mt-4">
      <label
        htmlFor="explanation"
        className="block text-sm font-medium text-gray-700">
        Please explain briefly why you made that selection.
      </label>
      <div className="flex mt-1 rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <input
            type="text"
            name="explanation"
            id="explanation"
            className="block w-full border-gray-300 rounded-none rounded-l-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="e.g. funny, informative, helpful, other message was repetative"
            value={explanation}
            onChange={(e) => setExplanation(e.target.value)}
          />
        </div>
        <button
          type="button"
          onClick={handleSubmit}
          className="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
          <span>Send</span>
        </button>
      </div>
    </div>
  );
}
