import { User } from "src/types/models";
import { create } from "zustand";

interface SessionStateSlice {
  user: User | null;
  setUser: (user: User) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const useSessionStore = create<SessionStateSlice>()((set, get) => ({
  user: null,
  setUser: (user: User) => set((state) => ({ user })),
  isLoading: true,
  setIsLoading: (isLoading: boolean) => set((state) => ({ isLoading })),
}));

export default useSessionStore;
