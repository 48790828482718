import NewlineSafeReactMarkdown from "src/components/text/newline-safe-react-markdown";
import classNames from "src/utils/class-names";

export const StaticPreviewEntryMessage = ({ text }: { text: string }) => (
  <div className="flex items-center justify-end flex-1 my-2">
    <p className="px-4 py-2 text-sm font-medium rounded-lg bg-blue-500 text-white">
      {text}
    </p>
  </div>
);

export const StaticPreviewReply = ({ text }: { text: string }) => {
  return (
    <div className="flex flex-row max-w-[80%]">
      <div className="bg-gray-100 text-sm font-medium rounded-tr rounded-br text-gray">
        <div className="px-4 py-2">
          <NewlineSafeReactMarkdown>{text}</NewlineSafeReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export const StaticPreviewReplyOption = ({
  option,
  isWinner,
  optionText,
}: {
  option: string;
  optionText: string;
  isWinner: boolean;
}) => {
  return (
    <div className="flex flex-row max-w-[80%]">
      <span
        className={classNames(
          isWinner ? "bg-green-200" : "bg-indigo-100",

          "inline-flex items-center rounded-tl rounded-bl px-2 py-0.5 text-xs font-medium text-indigo-800"
        )}>
        {option}
      </span>
      <div
        className={classNames(
          isWinner ? "bg-green-100" : "bg-gray-100",
          "text-sm font-medium rounded-tr rounded-br text-gray"
        )}>
        <span
          className={classNames(
            isWinner ? "bg-green-200" : "bg-indigo-100",
            "inline-block px-4 py-1 rounded-br text-xs font-medium text-indigo-800"
          )}>
          Option {option}
        </span>
        <NewlineSafeReactMarkdown>{optionText}</NewlineSafeReactMarkdown>
      </div>
    </div>
  );
};
