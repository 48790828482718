import { ApiClient } from "src/api";

import { TaskSettings } from "../types";
import { APIStaticConversation } from "./types";

type StartTaskData = TaskSettings & {
  convos: APIStaticConversation[];
};

export function start(
  apiClient: ApiClient,
  taskId: string
): Promise<StartTaskData> {
  return apiClient.post("/static-tasks/start/", { task_id: taskId });
}
