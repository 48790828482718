import React, { ReactElement } from "react";
import classNames from "src/utils/class-names";

import { ModelOptionLabel } from "../../types";

type Props = {
  label: ModelOptionLabel;
  isSelected: boolean;
  onClick: () => void;
};

export default function OptionTabSwitcher(props: Props): ReactElement {
  const { label, isSelected, onClick } = props;
  return (
    <button
      key={label}
      className={classNames(
        isSelected
          ? "border-indigo-500 text-indigo-600"
          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
        "whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium"
      )}
      onClick={onClick}>
      Option {label}
    </button>
  );
}
