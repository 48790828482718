export default function OptionButton({
  onClick,
  text,
}: {
  onClick: () => void;
  text: string;
}) {
  return (
    <button
      type="button"
      className="inline-flex items-center justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-400 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={onClick}>
      {text}
    </button>
  );
}
