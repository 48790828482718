import { useParams } from "react-router-dom";
import ConfidentialityAgreementModal from "src/components/modal/confidentiality-agreement-modal";
import StartTaskError from "src/components/task/start-task-error";
import { shallow } from "zustand/shallow";

import TaskContent from "../components/task-content";
import useStartTask from "../hooks/use-start-task";
import useStaticSxsStore from "../hooks/use-static-sxs-store";
import { TaskState } from "../types";

export default function StaticSxsTask() {
  const { taskId } = useParams();

  const [taskData, taskState, setTaskState] = useStaticSxsStore(
    (state) => [state.taskData, state.taskState, state.setTaskState],
    shallow
  );

  const { startTaskError } = useStartTask();

  switch (taskState) {
    case TaskState.NEED_CONFIDENTIALITY_AGREEMENT:
      return (
        <ConfidentialityAgreementModal
          onAgree={() => setTaskState(TaskState.NOT_STARTED)}
        />
      );
    case TaskState.ERROR:
      return <StartTaskError error={startTaskError} taskId={taskId} />;
    default:
      return taskData === null ? null : <TaskContent />;
  }
}
