import { useEffect, useState } from "react";
import { useApiClient } from "src/api";

export default function useFetch<TData, TError = unknown>(
  urlPath: string,
  queryParams?: Record<string, string>
): {
  data: TData | undefined;
  isLoading: boolean;
  error: TError | undefined;
};

// This overload is required to assert that data is never undefined if initialData is provided
export default function useFetch<TData, TError = unknown>(
  urlPath: string,
  queryParams: Record<string, string> | undefined,
  initialData: TData
): {
  data: TData;
  isLoading: boolean;
  error: TError | undefined;
};

export default function useFetch<TData, TError>(
  urlPath: string,
  queryParams?: Record<string, string>,
  initialData?: TData
) {
  const apiClient = useApiClient();
  const [data, setData] = useState<TData | undefined>(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<TError>();

  let url = urlPath;
  if (queryParams) {
    url += `?${new URLSearchParams(queryParams)}`;
  }

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      const fetchedData = await apiClient.get(url, {
        signal: abortController.signal,
      });
      setData(fetchedData);
    };

    setIsLoading(true);
    fetchData()
      .catch((e) => {
        if (!abortController.signal.aborted) {
          setError(e);
        }
      })
      .finally(() => setIsLoading(false));

    return () => {
      abortController.abort();
    };
  }, [apiClient, url]);

  return { data, isLoading, error };
}
