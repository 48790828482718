import { CheckIcon } from "@heroicons/react/solid";

interface BothOptionsAreGoodButtonProps {
  onClick: () => void;
  text: string;
}

export default function BothOptionsAreGoodButton({
  onClick,
  text,
}: BothOptionsAreGoodButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex items-center justify-center flex-1 py-2 ml-3 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md shadow-sm hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
      <CheckIcon className="w-4 h-4 mr-2 text-green-900" />
      <span>{text}</span>
    </button>
  );
}
