import { Route, Routes } from "react-router-dom";

import StaticSxsTask from "./static-sxs-task";

export const StaticSxsTaskRoutes = () => {
  return (
    <Routes>
      <Route path=":taskId" element={<StaticSxsTask />} />
    </Routes>
  );
};
