import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { AcademicCapIcon, UserCircleIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import useSessionStore from "src/features/auth/hooks/use-session-store";
import classNames from "src/utils/class-names";

export default function NavBar() {
  const user = useSessionStore((state) => state.user);
  const userNavigation: { name: string; to: string }[] = [];
  if (user?.is_professor || user?.is_staff) {
    userNavigation.push({
      name: `ID: ${user.email}`,
      to: "#",
    });
  }

  if (user?.is_staff) {
    userNavigation.push({
      name: "Bookmarks",
      to: `/app/bookmarks/${user.email}`,
    });
  }

  userNavigation.push({ name: "Logout", to: "/auth/logout/" });
  const IconComponent = user?.is_professor ? AcademicCapIcon : UserCircleIcon;

  return (
    <Disclosure as="nav" className="bg-gray-50">
      {({ open }) => (
        <>
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-end h-16 border-b border-gray-200">
              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md bg-gray-50 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              {/* Actions section */}
              <div className="hidden lg:block lg:ml-4">
                <div className="flex items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0 ml-3">
                    <div>
                      <Menu.Button className="flex text-sm text-white rounded-full bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500">
                        <span className="sr-only">Open user menu</span>
                        <IconComponent
                          className="block w-8 h-8 text-indigo-600"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }: { active: boolean }) => (
                              <a
                                href={item.to}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-sm text-gray-700"
                                )}>
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="border-b border-gray-200 bg-gray-50 lg:hidden">
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="px-2 mt-3 space-y-1">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.to}
                    className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100">
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
