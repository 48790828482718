import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TaskState } from "src/features/interactive-sxs-tasks/types";
import { shallow } from "zustand/shallow";

import useApi from "../api";
import useInteractiveSxsStore from "./use-interactive-sxs-store";

export default function useStartTask() {
  const taskId = useParams().taskId!;
  const api = useApi();
  const setStartError = useInteractiveSxsStore((state) => state.setStartError);
  const [taskState, setTaskState] = useInteractiveSxsStore(
    (state) => [state.taskState, state.setTaskState],
    shallow
  );
  const setTaskData = useInteractiveSxsStore((state) => state.setTaskData);

  const startTask = useCallback(async () => {
    setTaskState(TaskState.STARTING);
    try {
      const taskDataResponse = await api.interactiveTasks.start(taskId);
      setTaskData(taskDataResponse);
    } catch (e: any) {
      setTaskState(TaskState.ERROR);
      setStartError(e);
    }
  }, [api, setStartError, setTaskData, setTaskState, taskId]);

  useEffect(() => {
    if (taskState !== TaskState.NOT_STARTED) {
      return undefined;
    }
    const abortController = new AbortController();
    startTask().catch(console.error);
    return () => {
      abortController.abort();
    };
  }, [taskState, startTask]);
}
