import React, { useCallback, useEffect, useRef, useState } from "react";
import { useApiClient } from "src/api";
import DialogueCollectionActionBox from "src/components/dialogue/dialogue-collection-action-box";
import DialogueFeedbackForm from "src/components/dialogue/dialogue-feedback-form";
import DialoguesNavigationHeader from "src/components/dialogue/dialogues-navigation-header";
import MessageForm from "src/components/messages/message-form";
import Messages from "src/components/messages/messages";
import ScenarioNameHeader from "src/components/scenario/scenario-name-header";
import ScenariosSelectionListBox from "src/components/scenario/scenarios-selection-list-box";
import TaskInstruction from "src/components/task/task-instruction";
import ErrorMessage from "src/components/text/error-message";
import PageHeader from "src/components/ui/page-header";
import AnnotationFormContainer from "src/features/annotation-tasks/components/annotation-form-container";
import {
  useAnnotationData,
  useAnnotationForms,
} from "src/features/annotation-tasks/hooks/use-annotation";
import { isMessageAnnotated } from "src/features/annotation-tasks/utils";
import { chat } from "src/features/human-to-bot-tasks/api/chat";
import useDialoguesStore from "src/hooks/use-dialogues-store";
import useEndDialogue from "src/hooks/use-end-dialogue";
import useMessages from "src/hooks/use-messages";
import { parseForm } from "src/libs/forms";
import { filterBotMessages } from "src/libs/messages";
import {
  Message,
  MessageType,
  Scenario,
  SerializedPolicyBatchResponse,
  Task,
} from "src/types/models";
import { shallow } from "zustand/shallow";

import { canCompleteDialogue } from "../utils";
import SearchResultsDisplay from "./search-results-display";

interface HumanToBotCollectionTaskProps {
  task: Task;
  initialScenario: Scenario | null;
  policies?: SerializedPolicyBatchResponse;
}

enum UserState {
  SELECTING_SCENARIO,
  WRITING_CHAT_MESSAGE,
  WAITING_FOR_BOT_RESPONSE,
  ANNOTATING_BOT_RESPONSE,
  REQUESTING_NEW_DIALOGUE,
}

export default function HumanToBotCollectionTask({
  task,
  initialScenario,
  policies,
}: HumanToBotCollectionTaskProps) {
  const apiClient = useApiClient();
  const [hasReachedGlobalDialogueLimit, setHasReachedGlobalDialogueLimit] =
    useState(false);
  const [
    dialogues,
    setDialogues,
    isCurrentDialogueCompleted,
    isAllDialoguesCompleted,
    currentDialogue,
    currentDialogueIndex,
    setCurrentDialogueIndex,
  ] = useDialoguesStore(
    (state) => [
      state.dialogues,
      state.setDialogues,
      state.isCurrentDialogueCompleted,
      state.isAllDialoguesCompleted,
      state.currentDialogue,
      state.currentDialogueIndex,
      state.setCurrentDialogueIndex,
    ],
    shallow
  );
  const [messages, setMessages] = useMessages(currentDialogue!.id);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [userState, setUserState] = useState<UserState>(
    initialScenario || !task.require_scenario
      ? UserState.WRITING_CHAT_MESSAGE
      : UserState.SELECTING_SCENARIO
  );
  const [scenario, setScenario] = useState<Scenario | null>(initialScenario);
  const [lastInferenceState, setLastInferenceState] = useState<any>(null);
  const { messageAnnotationForm, messagesAnnotationFormRequiredFields } =
    useAnnotationForms(task);
  const {
    messagesAnnotationData,
    isSavingAnnotationData,
    messageAnnotationInputChangeHandler,
  } = useAnnotationData(task, currentDialogue!);

  const selectScenario = async (scenario: Scenario) => {
    await apiClient.post("/scenarios/select/", {
      scenario_id: scenario.id,
      task_id: task!.id,
      dialogue_id: currentDialogue!.id,
    });
    setScenario(scenario);
    setUserState(UserState.WRITING_CHAT_MESSAGE);
  };

  const reassignScenario = async () => {
    const response = await apiClient.post("/scenarios/reassign/", {
      task_id: task!.id,
      dialogue_id: currentDialogue!.id,
    });

    setScenario(response);
    setUserState(UserState.WRITING_CHAT_MESSAGE);
  };

  const handleNewDialogue = useCallback(
    async (e: React.MouseEvent) => {
      if (userState === UserState.REQUESTING_NEW_DIALOGUE) {
        return;
      }
      setUserState(UserState.REQUESTING_NEW_DIALOGUE);
      await apiClient
        .post("/dialogues/", {
          task_id: task.id,
          scenario_id: task.automatically_select_scenario ? null : scenario?.id,
        })
        .then(({ dialogue, scenario }) => {
          const newDialogueIndex = dialogues.length;
          setDialogues([...dialogues, dialogue]);
          setMessages([]);
          setCurrentDialogueIndex(newDialogueIndex);

          if (scenario && task.automatically_select_scenario) {
            setScenario(scenario);
            setUserState(UserState.WRITING_CHAT_MESSAGE);
          } else {
            setScenario(null);
            setUserState(
              task.require_scenario
                ? UserState.SELECTING_SCENARIO
                : UserState.WRITING_CHAT_MESSAGE
            );
          }
        })
        .catch((error) => {
          setHasReachedGlobalDialogueLimit(true);
        });
    },
    [
      apiClient,
      dialogues,
      setDialogues,
      setMessages,
      setCurrentDialogueIndex,
      scenario,
      userState,
      task.id,
      task.require_scenario,
      task.automatically_select_scenario,
    ]
  );

  const handleEndDialogue = useEndDialogue();

  const messagesRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!messageAnnotationForm) {
      messagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, messageAnnotationForm]);

  const handleSubmitMessage = async (message: string) => {
    if (message.length === 0) {
      return;
    }
    // temporarily ID used as react key. Will be overwritten when data comes back from server
    const tmpId =
      messages.length > 0
        ? Math.max(...messages.map((message) => message.id)) + 1
        : 1;
    const newMessages: Message[] = [
      {
        id: tmpId,
        text: message,
        type: MessageType.USER_MESSAGE,
      },
      {
        id: tmpId + 1,
        text: "...",
        type: MessageType.BOT_MESSAGE,
        is_typing_placeholder: true,
      },
    ];
    setMessages([...messages, ...newMessages]);
    setUserState(UserState.WAITING_FOR_BOT_RESPONSE);
    const response = await chat(
      apiClient,
      {
        dialogue_id: currentDialogue!.id,
        text: message,
      },
      setErrorMessage,
      lastInferenceState
    );
    setLastInferenceState(response.inference_state);
    setMessages([...messages, ...response.messages]);
    setUserState(
      messageAnnotationForm
        ? UserState.ANNOTATING_BOT_RESPONSE
        : UserState.WRITING_CHAT_MESSAGE
    );
    setErrorMessage(null);
  };

  const isDialogueCompleted = isCurrentDialogueCompleted();
  const isTaskCompleted =
    hasReachedGlobalDialogueLimit ||
    isAllDialoguesCompleted(task.max_dialogues_to_collect_per_teacher);
  const botMessages = filterBotMessages(messages);
  const canCompleteCurrentDialogue = canCompleteDialogue(
    isDialogueCompleted,
    botMessages,
    task.min_num_ai_messages_to_collect_per_dialogue
  );

  let annotationForm = null;
  if (userState === UserState.ANNOTATING_BOT_RESPONSE) {
    const botMessage = messages[messages.length - 1];
    const currentMessageAnnotationData = messagesAnnotationData[botMessage.id];
    const canContinue = isMessageAnnotated(
      currentMessageAnnotationData,
      messagesAnnotationFormRequiredFields
    );

    annotationForm = (
      <div className="w-full p-5 my-4 overflow-y-auto text-sm text-gray-900 rounded-lg bg-yellow-50">
        <AnnotationFormContainer isSaving={isSavingAnnotationData}>
          <form
            className="mt-4 annotation-form"
            id={`message-form-${botMessage.id}`}
            key={`message-form-${botMessage.id}`}
            data-message-id={botMessage.id}>
            <div className="mb-4 text-md">
              {messageAnnotationForm!.description}
            </div>
            {parseForm(messageAnnotationForm!.value, {
              formData: messagesAnnotationData[botMessage.id],
              onChange: messageAnnotationInputChangeHandler(botMessage.id),
            })}
          </form>
        </AnnotationFormContainer>
        {canContinue && (
          <div className="flex justify-center">
            <button
              type="button"
              className="mt-4 w-1/2 px-4 py-2 text-sm font-medium bg-indigo-400 border border-transparent rounded-md shadow-sm text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={(e) => setUserState(UserState.WRITING_CHAT_MESSAGE)}>
              Continue the conversation
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <PageHeader
        heading={task.public_name}
        subHeading="Conversation Collection"
        publicId={task.public_id}
      />
      <main className="max-w-3xl px-4 pb-12 mx-auto sm:px-6 lg:px-8">
        <TaskInstruction
          markdownInstruction={task.markdown_instruction}
          instruction={task.instruction}
          policies={policies}
        />

        <div className="grid w-full max-w-3xl gap-4 mx-auto my-4 sm:grid-cols-1">
          {!scenario &&
            task.require_scenario &&
            !task.automatically_select_scenario && (
              <div className="mt-10">
                <ScenariosSelectionListBox
                  selectScenario={selectScenario}
                  taskUUID={task.uuid}
                />
              </div>
            )}
          {scenario && (
            <div className="mt-10">
              <ScenarioNameHeader
                scenario={scenario}
                allowReassign={
                  task.automatically_select_scenario && messages.length === 0
                }
                reassignScenario={reassignScenario}
              />
            </div>
          )}

          <div className="w-full mb-6">
            <div className="flex items-center justify-between">
              <DialoguesNavigationHeader
                currentDialogueIndex={currentDialogueIndex}
                numDialogues={task.max_dialogues_to_collect_per_teacher}
                hideDialogueProgressMarker={!!task.max_dialogues_to_collect}
              />
            </div>

            <div className="flex flex-col flex-1 p-5 mx-auto overflow-y-hidden bg-white border shadow-lg">
              <div className="px-4 overflow-y-auto" id="messages">
                <Messages
                  messages={messages}
                  shouldShowReaction={(message) =>
                    !message.is_typing_placeholder
                  }
                  messagesAnnotationFormRequiredFields={
                    messagesAnnotationFormRequiredFields
                  }
                  messagesAnnotationData={messagesAnnotationData}
                />
                {annotationForm}
                {task.display_search_results && (
                  <SearchResultsDisplay
                    buttonText={task.display_search_results}
                    displayText={
                      messages[messages.length - 1]?.extras?.[0]
                        ?.search_results_to_display
                    }
                  />
                )}
                <div ref={messagesRef}></div>
                {errorMessage && <ErrorMessage message={errorMessage} />}
              </div>
              {!isDialogueCompleted && (
                <div className="mt-4">
                  <MessageForm
                    handleSubmitMessage={handleSubmitMessage}
                    disabled={userState !== UserState.WRITING_CHAT_MESSAGE}
                    key={`${currentDialogue!.id}-message-form`}
                  />
                </div>
              )}
              {canCompleteCurrentDialogue && (
                <div className="flex items-center justify-end pt-2 mt-4 text-sm text-gray-500 border-t-2 border-gray-100 space-between">
                  <span className="flex justify-end">
                    <button
                      className="px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      onClick={() => handleEndDialogue()}>
                      End Conversation
                    </button>
                  </span>
                </div>
              )}
            </div>
            {isDialogueCompleted && (
              <>
                <DialogueFeedbackForm
                  currentDialogue={currentDialogue!}
                  key={`feedback-${currentDialogue!.id}`}
                />
                <DialogueCollectionActionBox
                  isTaskCompleted={isTaskCompleted}
                  currentDialogueIndex={currentDialogueIndex}
                  numDialogues={task.max_dialogues_to_collect_per_teacher}
                  hideProgressTracker={!!task.max_dialogues_to_collect}
                  handleNewDialogue={handleNewDialogue}
                  isRequestingNewDialogue={
                    userState === UserState.REQUESTING_NEW_DIALOGUE
                  }
                  prolificRedirectUrl={task.prolific_redirect_url}
                />
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
}
