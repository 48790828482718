import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "src/hooks/use-fetch";

interface DialogueNavigationProps {
  dialogueId: string;
}

interface NavigationInfo {
  previous_dialogue_id: number | null;
  next_dialogue_id: number | null;
}

/**
 * Component provides controls to go back and forth between different dialogue pages in the same task.
 */
export default function DialoguesNavigation({
  dialogueId,
}: DialogueNavigationProps) {
  const navigate = useNavigate();
  const { data: navigationInfo } = useFetch<NavigationInfo>(
    `/dialogues/${dialogueId}/navigation/`
  );

  const navigateToPrevious = useCallback(() => {
    if (navigationInfo?.previous_dialogue_id) {
      navigate(
        `/app/dialogues/preview/${navigationInfo.previous_dialogue_id}/`,
        {
          replace: true,
        }
      );
    }
  }, [navigate, navigationInfo]);

  const navigateToNext = useCallback(() => {
    if (navigationInfo?.next_dialogue_id) {
      navigate(`/app/dialogues/preview/${navigationInfo?.next_dialogue_id}/`, {
        replace: true,
      });
    }
  }, [navigate, navigationInfo]);

  useEffect(() => {
    if (!navigationInfo) {
      return;
    }

    const handleKeyboardNavigation = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        navigateToPrevious();
      } else if (event.key === "ArrowRight") {
        navigateToNext();
      }
    };

    document.addEventListener("keydown", handleKeyboardNavigation);
    return () =>
      document.removeEventListener("keydown", handleKeyboardNavigation);
  }, [navigationInfo, navigateToPrevious, navigateToNext]);

  return (
    <nav className="flex items-center justify-between max-w-3xl px-4 mx-auto sm:px-0">
      <div className="flex flex-1 w-0 -mt-px">
        {navigationInfo?.previous_dialogue_id && (
          <button
            className="inline-flex items-center pt-4 pr-1 text-sm font-medium text-gray-500 hover:text-gray-700"
            onClick={navigateToPrevious}>
            <ArrowNarrowLeftIcon
              className="w-5 h-5 mr-3 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </button>
        )}
      </div>
      <div className="flex justify-end flex-1 w-0 -mt-px">
        {navigationInfo?.next_dialogue_id && (
          <button
            className="inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 hover:text-gray-700"
            onClick={navigateToNext}>
            Next
            <ArrowNarrowRightIcon
              className="w-5 h-5 ml-3 text-gray-400"
              aria-hidden="true"
            />
          </button>
        )}
      </div>
    </nav>
  );
}
