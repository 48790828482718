import { ChoicesQuestion } from "../types";

type Props = {
  content: ChoicesQuestion;
};

export default function Question(props: Props) {
  const { content } = props;
  return (
    <div
      className="text-base"
      dangerouslySetInnerHTML={{ __html: content.question }}></div>
  );
}
