type Props = {
  onClick: () => Promise<void>;
};

export default function NextConversationButton(props: Props) {
  const { onClick } = props;
  return (
    <button
      type="button"
      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={onClick}>
      Continue
    </button>
  );
}
