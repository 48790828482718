import { DebouncedFunc } from "lodash";

export enum AnnotationType {
  DIALOGUE = 1,
  MESSAGE = 2,
}

export interface AnnotationForm {
  id: number;
  name: number;
  description: string;
  type: AnnotationType;
  value: string;
  reactElement: string | JSX.Element | JSX.Element[];
}

export type LocalAnnotationData = Record<string, any> | null;

export interface AnnotationDataSaver {
  saveLocal: (annotationData: LocalAnnotationData) => void;
  saveRemote: DebouncedFunc<
    (
      annotationData: LocalAnnotationData,
      annotationType: AnnotationType,
      targetId: number
    ) => Promise<void>
  >;
}

export interface Annotation {
  task_id: number;
  data: LocalAnnotationData;
  target_message_id?: number;
  target_dialogue_id?: number;
}
