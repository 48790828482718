import { ChangeEventHandler, useState } from "react";
import { LocalAnnotationData } from "src/features/annotation-tasks/types";
import { ModelOptionLabel } from "src/features/interactive-sxs-tasks/types";

import OptionTab from "./option-tab";
import OptionTabSwitcher from "./option-tab-switcher";
import { RetrievalOption } from "./types";

type Props = {
  options: RetrievalOption[];
  retrievalAnnotationData: Record<ModelOptionLabel, LocalAnnotationData>;
  getOnChangeHandler: (label: ModelOptionLabel) => ChangeEventHandler;
};

export default function OptionTabs(props: Props) {
  const { options, retrievalAnnotationData, getOnChangeHandler } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentOption = options[currentIndex];

  return (
    <div className="flex flex-col items-stretch">
      <div className="border-b border-gray-200">
        <nav className="flex space-x-4" aria-label="Tabs">
          {options.map(({ label }, index) => (
            <OptionTabSwitcher
              key={label}
              label={label}
              isSelected={currentIndex === index}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </nav>
      </div>
      <div className="w-full p-5 my-4 overflow-y-auto text-sm text-gray-900 rounded-lg bg-gray-50">
        <OptionTab
          option={currentOption}
          annotationData={retrievalAnnotationData[currentOption.label] || {}}
          onChange={getOnChangeHandler(currentOption.label)}
        />
      </div>
    </div>
  );
}
