export default function SideBySideActionContainer({
  children,
  text = "Select the highest quality option",
}: {
  children: React.ReactNode;
  text?: string;
}) {
  return (
    <div className="pt-5 space-y-4">
      <h3
        className="font-bold leading-6 text-center text-gray-900 text-md"
        dangerouslySetInnerHTML={{
          __html: text,
        }}></h3>
      {children}
    </div>
  );
}
