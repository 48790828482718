import { create } from "zustand";

interface TargetSentencesStateSlice {
  targetSentences: string[];
  currentTargetSentenceIndex: number;
  setTargetSentences: (targetSentences: string[]) => void;
  setCurrentTargetSentenceIndex: (currentTargetSentenceIndex: number) => void;
  nextTargetSentence: () => string;
  prevTargetSentence: () => string;
  isFirstTargetSentence: () => boolean;
  isLastTargetSentence: () => boolean;
}

const useTargetSentencesStore = create<TargetSentencesStateSlice>(
  (set, get) => ({
    targetSentences: [],
    currentTargetSentenceIndex: 0,
    setTargetSentences: (targetSentences: string[]) =>
      set((state) => ({ targetSentences })),
    setCurrentTargetSentenceIndex(currentTargetSentenceIndex: number) {
      set((state) => ({ currentTargetSentenceIndex }));
    },
    nextTargetSentence() {
      const { targetSentences, currentTargetSentenceIndex } = get();
      const nextTargetSentenceIndex = Math.min(
        currentTargetSentenceIndex + 1,
        targetSentences.length - 1
      );
      set((state) => ({ currentTargetSentenceIndex: nextTargetSentenceIndex }));
      return targetSentences[nextTargetSentenceIndex];
    },
    prevTargetSentence() {
      const { targetSentences, currentTargetSentenceIndex } = get();
      const prevTargetSentenceIndex = Math.max(
        currentTargetSentenceIndex - 1,
        0
      );
      set((state) => ({ currentTargetSentenceIndex: prevTargetSentenceIndex }));
      return targetSentences[prevTargetSentenceIndex];
    },
    isFirstTargetSentence() {
      return get().currentTargetSentenceIndex === 0;
    },
    isLastTargetSentence() {
      const { targetSentences, currentTargetSentenceIndex } = get();
      return currentTargetSentenceIndex === targetSentences.length - 1;
    },
  })
);

export default useTargetSentencesStore;
