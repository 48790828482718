import { useCallback } from "react";

import useInteractiveSxsStore from "./use-interactive-sxs-store";
import useSendMessage from "./use-send-message";

export default function useRefreshOptions() {
  const sendMessage = useSendMessage();

  const [
    getCurrentConversation,
    logCurrentRefreshAttempt,
    setOptions,
    setIsLoadingOptions,
  ] = useInteractiveSxsStore((state) => [
    state.getCurrentConversation,
    state.logCurrentRefreshAttempt,
    state.setOptions,
    state.setIsLoadingOptions,
  ]);

  const currentConversation = getCurrentConversation();
  const refresh = useCallback(async () => {
    if (!currentConversation) {
      return;
    }
    const history = currentConversation.history;
    logCurrentRefreshAttempt(); // log current refresh attempt before refreshing again
    setOptions([]);
    setIsLoadingOptions(true);
    const lastUserText = history[history.length - 1].text;
    await sendMessage?.(history, lastUserText, false);
  }, [
    currentConversation,
    logCurrentRefreshAttempt,
    setOptions,
    setIsLoadingOptions,
    sendMessage,
  ]);

  return refresh;
}
