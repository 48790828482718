import {
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";

import { FormData } from "./types";

type ParseDivArgs = {
  formData?: FormData;
};

export default function parseDiv(
  domNode: Element,
  options: HTMLReactParserOptions,
  args: ParseDivArgs
) {
  const { formData } = args;
  const props = attributesToProps(domNode.attribs);
  if (domNode.attribs.when !== undefined) {
    const [labelName, expectedValue] = domNode.attribs.when.split("=");
    const currentValue = formData?.[labelName];
    return (
      <div
        className={currentValue === expectedValue ? "" : "hidden"}
        {...props}>
        {domToReact(domNode.children, options)}
      </div>
    );
  }
  return domNode;
}
