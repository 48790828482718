import { BookmarkIcon as OutlineBookmarkIcon } from "@heroicons/react/outline";
import { BookmarkIcon as SolidBookmarkIcon } from "@heroicons/react/solid";
import Tippy from "@tippyjs/react";
import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useApiClient } from "src/api";
import Modal from "src/components/ui/modal";
import useMediaQuery from "src/hooks/use-media-query";
import { Bookmark, Message } from "src/types/models";
import classNames from "src/utils/class-names";

interface Props {
  message: Message;
  initialBookmark: Bookmark | undefined;
  isHovered: boolean;
  onBookmarkCreation?: () => void;
}

export default function MessageBookmark({
  message,
  initialBookmark,
  isHovered,
  onBookmarkCreation,
}: Props) {
  const apiClient = useApiClient();
  const [initialized, setInitialized] = useState(false);
  const [bookmark, setBookmark] = useState<Bookmark>();
  const [showAddBookmarkModal, setShowAddBookmarkModal] = useState(false);
  const [bookmarkModalText, setBookmarkModalText] = useState("");
  const isSmallScreen = useMediaQuery("(max-width: 640px)");

  if (!initialized && initialBookmark) {
    setBookmark(initialBookmark);
    setBookmarkModalText(initialBookmark.note);
    setInitialized(true);
  }

  if (bookmark) {
    const bookmarkButton = (
      <button
        className="text-red-500 ml-4"
        onClick={async () => {
          await apiClient.delete(`/bookmarks/${bookmark.id}`);
          setBookmark(undefined);
        }}>
        <SolidBookmarkIcon className="w-6 h-6" />
      </button>
    );

    if (bookmark.note) {
      return (
        <Tippy
          content={<span className="whitespace-pre-wrap">{bookmark.note}</span>}
          visible={isHovered}
          placement={isSmallScreen ? "top" : "right"}>
          {bookmarkButton}
        </Tippy>
      );
    } else {
      return bookmarkButton;
    }
  } else {
    return (
      <>
        <button
          className={classNames(
            "text-red-500 ml-4",
            isHovered ? "" : "invisible"
          )}
          onClick={() => setShowAddBookmarkModal(true)}>
          <OutlineBookmarkIcon className="w-6 h-6" />
        </button>
        {showAddBookmarkModal && (
          <Modal
            title="Create a new bookmark"
            submitButtonText="Create bookmark"
            onSubmit={async () => {
              const newBookmark = await apiClient.post("/bookmarks", {
                message_id: message.id,
                note: bookmarkModalText,
              });
              setBookmark(newBookmark);
              onBookmarkCreation?.();
            }}
            onClose={() => setShowAddBookmarkModal(false)}>
            <TextareaAutosize
              value={bookmarkModalText}
              // Prevent keyboard actions like right/left arrow from bubbling up
              onKeyDown={(e) => e.stopPropagation()}
              onChange={(e) => setBookmarkModalText(e.target.value)}
              onFocus={(e) => e.target.select()}
              placeholder="Optional: Add a note for your bookmark"
              autoFocus
              minRows={3}
            />
          </Modal>
        )}
      </>
    );
  }
}
