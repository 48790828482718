import { useCallback } from "react";
import { BotResponseOption, Message, MessageType } from "src/types/models";
import { shallow } from "zustand/shallow";

import useApi from "../api";
import { ModelOptionLabel, TaskData, TaskState } from "../types";
import useInteractiveSideBySideStore from "./use-interactive-sxs-store";

const MIN_MESSAGES_TO_BE_COMPENSATED_ON_ERROR = 6;

type SendMessageHandler = (
  history: Message[],
  text: string | null,
  isNew?: boolean
) => Promise<void>;

async function getModelResponses(
  api: ReturnType<typeof useApi>,
  taskData: TaskData,
  preflightId: number,
  text: string | null,
  onRecoverableErrorHandler: (error: string | null) => void,
  onUnrecoverableErrorHandler: () => void,
  state?: any | undefined
): Promise<BotResponseOption[]> {
  if (taskData.single_model_mode) {
    // in single model mode, we only have one model option, which is model A
    const responses = await api.interactiveTasks.chat(
      "A",
      preflightId,
      text,
      onRecoverableErrorHandler,
      onUnrecoverableErrorHandler,
      state
    );
    return responses.map((modelResponse, index) => ({
      text: modelResponse.text,
      extras: modelResponse.extras,
      state: modelResponse.state,
      model: index === 0 ? "A" : "B",
    }));
  }

  return Promise.all(
    taskData.model_options.map(async (model: ModelOptionLabel) => {
      const modelResponse = (
        await api.interactiveTasks.chat(
          model,
          preflightId,
          text,
          onRecoverableErrorHandler,
          onUnrecoverableErrorHandler,
          state
        )
      )[0];
      return {
        text: modelResponse.text,
        extras: modelResponse.extras,
        state: modelResponse.state,
        model,
      };
    })
  );
}

export default function useSendMessage(): SendMessageHandler {
  const api = useApi();
  const [taskData, getCurrentConversation] = useInteractiveSideBySideStore(
    (state) => [state.taskData, state.getCurrentConversation],
    shallow
  );
  const [
    setChatError,
    setTaskState,
    setIsLoadingOptions,
    setHistory,
    setPreflightId,
    setOptions,
  ] = useInteractiveSideBySideStore(
    (state) => [
      state.setChatError,
      state.setTaskState,
      state.setIsLoadingOptions,
      state.setHistory,
      state.setPreflightId,
      state.setOptions,
    ],
    shallow
  );
  const currentConversation = getCurrentConversation();

  const sendMessage = useCallback(
    async (history: Message[], text: string | null, isNew: boolean = true) => {
      if (!taskData || !currentConversation) {
        return;
      }
      const newHistory = [...history];
      if (text && isNew) {
        newHistory.push({
          id: history.length,
          type: MessageType.USER_MESSAGE,
          ts: Date.now() / 1000,
          text,
        });
      }
      const onUnrecoverableErrorHandler = () => {
        // if user has completed at least 3 messages, compensate them
        if (history.length >= MIN_MESSAGES_TO_BE_COMPENSATED_ON_ERROR) {
          setChatError(
            "We are sorry, there appears to be a technical error. Please contact the administrator through Prolific. We would still like to compensate you for your time so please use the button below to mark the study as completed."
          );
          setTaskState(TaskState.FINISHED);
        } else {
          setChatError(
            "We are sorry, there appears to be a technical error. Please contact the administrator through Prolific."
          );
        }
      };
      setIsLoadingOptions(true);
      setHistory(newHistory);

      // Get a preflight ticket to store the extras temporarily
      const newPreflightId = await api.interactiveTasks.preflight(
        currentConversation!.id,
        onUnrecoverableErrorHandler
      );
      setPreflightId(newPreflightId);

      const updatedOptions = await getModelResponses(
        api,
        taskData,
        newPreflightId,
        text,
        setChatError,
        onUnrecoverableErrorHandler,
        history[history.length - 1]?.state
      );
      setOptions(updatedOptions);
      setIsLoadingOptions(false);
    },
    [
      api,
      currentConversation,
      setChatError,
      setTaskState,
      setIsLoadingOptions,
      setHistory,
      setPreflightId,
      setOptions,
      taskData,
    ]
  );

  return sendMessage;
}
