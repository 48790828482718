import UserMessage from "src/components/messages/user-message";
import { LocalAnnotationData } from "src/features/annotation-tasks/types";
import { isMessageAnnotated } from "src/features/annotation-tasks/utils";
import { Message, MessageType } from "src/types/models";

import BotMessage from "./bot-message";

interface MessagesProp {
  messages: Message[];
  shouldShowReaction: (message: Message) => boolean;
  messagesAnnotationData?: { [messageId: number]: LocalAnnotationData };
  messagesAnnotationFormRequiredFields?: Record<string, boolean> | null;
}

export default function Messages({
  messages,
  shouldShowReaction,
  messagesAnnotationData,
  messagesAnnotationFormRequiredFields,
}: MessagesProp) {
  return (
    <div className="gap-y-2">
      {messages.map((message) => {
        const messageComponent =
          message.type === MessageType.BOT_MESSAGE ? (
            <BotMessage
              message={message}
              showReaction={shouldShowReaction(message)}
              isAnnotated={isMessageAnnotated(
                messagesAnnotationData?.[message.id],
                messagesAnnotationFormRequiredFields ?? null
              )}
            />
          ) : (
            <UserMessage message={message} />
          );
        return (
          <div key={message.id} className="my-4">
            {messageComponent}
          </div>
        );
      })}
    </div>
  );
}
