import { useState } from "react";
import SearchResults from "src/components/text/search-results";
import classNames from "src/utils/class-names";

export default function SearchResultsDisplay({
  displayText,
  buttonText,
}: {
  displayText?: string;
  buttonText?: string;
}) {
  const [displaySearchResuls, setSearchResults] = useState(false);
  if (!displayText) {
    return null;
  }

  return (
    <div className="mt-2">
      <button
        className="inline-flex justify-center p-2  text-xs font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm text-gray hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => setSearchResults(!displaySearchResuls)}>
        {buttonText}
      </button>
      <div
        className={classNames(
          "flex flex-row",
          displaySearchResuls ? "flex" : "hidden"
        )}>
        <SearchResults text={displayText} />
      </div>
    </div>
  );
}
