import { Route, Routes } from "react-router-dom";

import Bookmarks from "./bookmarks";

export const BookmarksRoutes = () => {
  return (
    <Routes>
      <Route path=":bookmarkedBy" element={<Bookmarks />}></Route>
    </Routes>
  );
};
