import classNames from "src/utils/class-names";

type Props = {
  onClick: () => void;
  text: string;
  explanation: string;
  isSelected: boolean;
  isChecked: boolean;
  isCorrect?: boolean;
  showExplanation?: boolean;
};

export default function Option(props: Props) {
  const {
    onClick,
    text,
    explanation,
    isSelected,
    isChecked,
    isCorrect,
    showExplanation,
  } = props;
  let bgColor = "bg-gray-50";
  if (isChecked) {
    if (isCorrect) {
      bgColor = "bg-green-50";
    } else if (isSelected) {
      bgColor = "bg-red-50";
    }
  } else {
    if (isSelected) {
      bgColor = "bg-yellow-50";
    }
  }

  return (
    <>
      <button
        className={classNames(
          "flex-1 text-sm rounded-md text-gray px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 text-left",
          bgColor
        )}
        disabled={isChecked}
        onClick={onClick}>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </button>
      {isChecked && showExplanation && (
        <div className="italic">{explanation}</div>
      )}
    </>
  );
}
