import { useState } from "react";
import Select from "react-select";
import classNames from "src/utils/class-names";
import resolveStaticUrl from "src/utils/static-url";

import BookmarkButton from "./bookmark-button";
import EntryMessage from "./entry-message";
import EntryReply from "./entry-reply";
import { Entry } from "./models";

type Props = {
  entry: Entry;
};

export const EntryDisplay = ({ entry }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [viewingRerollIndex, setViewingRerollIndex] = useState<number | null>(
    null
  );

  const isViewingReplies = viewingRerollIndex === null;

  const selectValues = [
    { value: null, label: "Final Replies" },
    ...entry.rerolls
      .map((r, i) => ({ value: i, label: `Reroll ${i + 1}` }))
      .reverse(),
  ];

  return (
    <div
      className="flex flex-row group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <div className="flex-1 flex flex-col gap-4">
        <EntryMessage text={entry.text} id={entry.id} />
        <div className="flex flex-row items-end gap-2">
          <img
            className="w-8 h-8 rounded-full"
            src={resolveStaticUrl("img/bot_icon.png")}
            alt="bot-icon"
          />

          <div className="flex flex-col gap-2">
            {isViewingReplies &&
              entry.replies.map((r) => <EntryReply key={r.label} reply={r} />)}

            {!isViewingReplies &&
              entry.rerolls[viewingRerollIndex].map((r) => (
                <EntryReply key={r.label} reply={r} />
              ))}

            {entry.rerolls.length > 0 && (
              <Select
                options={selectValues}
                value={selectValues.find((v) => v.value === viewingRerollIndex)}
                onChange={(option) =>
                  setViewingRerollIndex(option?.value ?? null)
                }
                isSearchable={false}
              />
            )}
          </div>
        </div>
      </div>
      <BookmarkButton
        conversationId={entry.conversationId}
        entryId={entry.id}
        bookmark={entry.bookmark}
        showNote={isHovered}
        className={classNames(
          "transition-opacity group-hover:opacity-100",
          entry.bookmark ? "opacity-100" : "opacity-0"
        )}
      />
    </div>
  );
};
