import { ApiClient } from "src/api";

import { APIStaticConversation } from "./types";

export function next(
  apiClient: ApiClient,
  taskUUID: string
): Promise<APIStaticConversation | null> {
  return apiClient.post("/static-tasks/next-conversation/", {
    task_id: taskUUID,
  });
}

export function skip(
  apiClient: ApiClient,
  conversationId: number
): Promise<APIStaticConversation | null> {
  return apiClient.post("/static-tasks/skip-conversation/", {
    conversation_id: conversationId,
  });
}
