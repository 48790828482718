import { useCallback, useRef } from "react";
import { LocalAnnotationData } from "src/features/annotation-tasks/types";
import { TaskState } from "src/features/interactive-sxs-tasks/types";
import { BotResponseOption, MessageType } from "src/types/models";
import { shallow } from "zustand/shallow";

import useApi from "../api";
import useInteractiveSideBySideStore from "./use-interactive-sxs-store";

type SelectArgs = {
  option: BotResponseOption | null;
  both_are_good?: boolean;
  both_are_bad?: boolean;
  alternative?: string;
  annotation_data?: LocalAnnotationData;
  comparison_scale_selected?: number;
};

export default function useSelect() {
  const isLoggingChoice = useRef<boolean>(false);
  const api = useApi();
  const [getCurrentConversation, setHistory] = useInteractiveSideBySideStore(
    (state) => [state.getCurrentConversation, state.setHistory],
    shallow
  );
  const [preflightId, getReplies] = useInteractiveSideBySideStore(
    (state) => [state.preflightId, state.getReplies],
    shallow
  );
  const [refreshHistory, setRefreshHistory] = useInteractiveSideBySideStore(
    (state) => [state.refreshHistory, state.setRefreshHistory],
    shallow
  );
  const [setOptions, setTaskState, canCompleteConversation] =
    useInteractiveSideBySideStore(
      (state) => [
        state.setOptions,
        state.setTaskState,
        state.canCompleteConversation,
      ],
      shallow
    );
  const currentConversation = getCurrentConversation();

  const select = useCallback(
    async ({
      option,
      both_are_good,
      both_are_bad,
      alternative,
      annotation_data,
      comparison_scale_selected,
    }: SelectArgs) => {
      if (!currentConversation || isLoggingChoice.current) {
        return;
      }
      isLoggingChoice.current = true;
      const history = currentConversation.history;
      await api.interactiveTasks.log({
        preflight_id: preflightId,
        text: history[history.length - 1].text,
        replies: getReplies(),
        selected: option ? option.model : null,
        alternative: alternative || null,
        both_are_good,
        both_are_bad,
        refresh_history: refreshHistory,
        annotation_data,
        comparison_scale_selected,
      });
      const entry = {
        id: history.length,
        text: option ? option.text : alternative!,
        type: MessageType.BOT_MESSAGE,
        ts: Date.now() / 1000, // TODO: record timestamp of message, not selection
        state: option ? option.state : null,
      };
      setHistory([...history, entry]);
      setOptions([]);
      setRefreshHistory([]);
      if (canCompleteConversation()) {
        setTaskState(TaskState.CONVERSATION_COMPLETED);
      }
      isLoggingChoice.current = false;
    },
    [
      api,
      canCompleteConversation,
      getReplies,
      currentConversation,
      preflightId,
      refreshHistory,
      setHistory,
      setOptions,
      setRefreshHistory,
      setTaskState,
    ]
  );
  return select;
}
