import { useCallback } from "react";

import useApi from "../api";
import useInteractiveSxsStore from "./use-interactive-sxs-store";
import useSendMessage from "./use-send-message";

export default function useUndo() {
  const api = useApi();
  const sendMessage = useSendMessage();
  const getCurrentConversation = useInteractiveSxsStore(
    (state) => state.getCurrentConversation
  );
  const currentConversation = getCurrentConversation();

  const undo = useCallback(async () => {
    const history = currentConversation?.history;
    if (!history || history.length < 2) {
      return;
    }
    await api.interactiveTasks.undo(currentConversation!.id);
    const lastUserText = history[history.length - 2].text;
    await sendMessage(history.slice(0, -1), lastUserText, false);
  }, [api, currentConversation, sendMessage]);
  return undo;
}
