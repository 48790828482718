import { BookmarkIcon as OutlineBookmarkIcon } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/outline";
import { BookmarkIcon as SolidBookmarkIcon } from "@heroicons/react/solid";
import Tippy from "@tippyjs/react";
import { useState } from "react";
import useMediaQuery from "src/hooks/use-media-query";
import classNames from "src/utils/class-names";

import BookmarkEditor from "./bookmark-editor";
import { useBookmarks } from "./data";
import { Bookmark } from "./models";

type Props = React.ComponentProps<"button"> & {
  conversationId: number;
  entryId: number;
  bookmark?: Bookmark;
  showNote?: boolean;
};

export const BookmarkButton = ({
  conversationId,
  entryId,
  bookmark,
  showNote,
  ...rest
}: Props) => {
  const { setBookmark, clearBookmark } = useBookmarks(conversationId, entryId);
  const isSmallScreen = useMediaQuery("(max-width: 640px)");
  const [editing, setEditing] = useState(false);

  return (
    <>
      <div className="flex flex-col justify-center gap-2">
        <button
          title="Set bookmark"
          className={classNames(rest.className ?? "", "text-red-500 ml-4")}
          onClick={() => setEditing(true)}>
          {bookmark ? (
            <Tippy
              className=""
              disabled={editing || !bookmark.note}
              visible={showNote}
              placement={isSmallScreen ? "top" : "right"}
              content={
                <span className="whitespace-pre-wrap">{bookmark?.note}</span>
              }>
              <SolidBookmarkIcon className="w-6 h-6" />
            </Tippy>
          ) : (
            <OutlineBookmarkIcon className="w-6 h-6" />
          )}
        </button>
        <button
          title="Clear bookmark"
          className={classNames(
            "text-gray-500 ml-4 opacity-0 transition-opacity",
            bookmark ? "group-hover:opacity-100" : ""
          )}
          disabled={!bookmark}
          onClick={async () => await clearBookmark()}>
          <TrashIcon className="w-6 h-6" />
        </button>
      </div>
      <BookmarkEditor
        active={editing}
        initial={bookmark?.note}
        onChange={async (note) => await setBookmark(note)}
        onCancel={() => setEditing(false)}
      />
    </>
  );
};

export default BookmarkButton;
