import {
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";
import { ChangeEventHandler } from "react";

import { FormData } from "./types";

type ParseSelectArgs = {
  formData: FormData;
  isDisabled: boolean;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
};

export default function parseSelect(
  domNode: Element,
  options: HTMLReactParserOptions,
  args: ParseSelectArgs
) {
  const { formData, isDisabled, onChange } = args;
  const props: any = attributesToProps(domNode.attribs);
  const isFormControlledComponent = Boolean(onChange);
  if (isFormControlledComponent) {
    props.selected = Boolean(
      formData && formData[domNode.attribs.name] === domNode.attribs.value
    );
  } else {
    props.defaultValue = formData && formData[domNode.attribs.name];
  }
  return (
    <select disabled={isDisabled} {...props}>
      {domToReact(domNode.children, options)}
    </select>
  );
}
