import NewlineSafeReactMarkdown from "src/components/text/newline-safe-react-markdown";

type Props = {
  instruction: string;
};

export default function ScenarioInstructio(props: Props) {
  const { instruction } = props;
  return (
    <div className="p-4 rounded-md text-sm bg-gray-50 prose max-w-none flex flex-col italic">
      <strong>Instruction:</strong>
      <NewlineSafeReactMarkdown className="text-sm">
        {instruction}
      </NewlineSafeReactMarkdown>
    </div>
  );
}
