import { Message } from "src/types/models";

interface UserMessageProp {
  message: Message;
}

export default function UserMessage({ message }: UserMessageProp) {
  return (
    <div className="flex items-center justify-end flex-1">
      <p className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg group-hover:text-white whitespace-pre-wrap">
        {message.text}
      </p>
    </div>
  );
}
