import BotMessage from "src/components/messages/bot-message";
import UserMessage from "src/components/messages/user-message";
import { MessageType } from "src/types/models";

type Turn = {
  type: "Human" | "AI";
  text: string;
};
type Props = {
  staticContext: {
    previous_turns: Turn[];
    target_sentences: string[];
  };
};

export default function RetrievalStaticDialogueContext(props: Props) {
  const { staticContext } = props;
  const { previous_turns } = staticContext;
  return (
    <div>
      {previous_turns.map((turn: Turn, index: number) => {
        const content =
          turn.type === "Human" ? (
            <UserMessage
              message={{
                id: index,
                text: turn.text,
                type: MessageType.USER_MESSAGE,
              }}
            />
          ) : (
            <BotMessage
              message={{
                id: index,
                text: turn.text,
                type: MessageType.BOT_MESSAGE,
              }}
              showReaction={false}
            />
          );
        return (
          <div key={`static-message-${index}`} className="my-4">
            {content}
          </div>
        );
      })}
    </div>
  );
}
