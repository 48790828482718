import { useCallback } from "react";
import { shallow } from "zustand/shallow";

import useApi from "../api";
import useInteractiveSxsStore from "./use-interactive-sxs-store";

type NextConversationHandler = () => Promise<void>;

export default function useNextConversation(): NextConversationHandler {
  const api = useApi();
  const [taskData, setTaskData] = useInteractiveSxsStore(
    (state) => [state.taskData, state.setTaskData],
    shallow
  );
  const [getCurrentConversation, endConversation, isLastConversation] =
    useInteractiveSxsStore(
      (state) => [
        state.getCurrentConversation,
        state.endConversation,
        state.isLastConversation,
      ],
      shallow
    );
  const currentConversation = getCurrentConversation();

  const handleNextConversation = useCallback(async () => {
    if (!taskData || !currentConversation) {
      return;
    }
    await api.conversations.end(currentConversation.id);
    endConversation(currentConversation.id);

    // if the AI starts the chat, we will have to call /start again to get the next conversation
    // TODO: this is a bit of a hack, we should probably have a separate endpoint for this
    if (
      !taskData.ai_starts_chat ||
      isLastConversation(currentConversation.id)
    ) {
      return;
    }

    const taskDataResponse = await api.interactiveTasks.start(taskData.uuid);
    setTaskData(taskDataResponse);
  }, [
    taskData,
    currentConversation,
    api,
    endConversation,
    setTaskData,
    isLastConversation,
  ]);

  return handleNextConversation;
}
