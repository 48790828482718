import { Message } from "src/types/models";

/**
 * Return whether the user can complete the dialogue
 * based on the number of bot messages collected.
 */
export function canCompleteDialogue(
  isDialogueCompleted: boolean,
  botMessages: Message[],
  minNumAIMessagesToCollect?: number
) {
  // If the dialogue is already completed, the user cannot complete it again.
  if (isDialogueCompleted) {
    return false;
  }

  const numBotMessages = botMessages.length;
  return minNumAIMessagesToCollect
    ? numBotMessages >= minNumAIMessagesToCollect
    : botMessages.length >= 5;
}
