import { useState } from "react";
import NewlineSafeReactMarkdown from "src/components/text/newline-safe-react-markdown";

interface SideBySideOptionButtonProps {
  text: string;
  onClick: () => Promise<void>;
}

export default function SideBySideOptionButton({
  text,
  onClick,
}: SideBySideOptionButtonProps) {
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <button
      type="button"
      onClick={async () => {
        setIsDisabled(true);
        await onClick();
        setIsDisabled(false);
      }}
      disabled={isDisabled}
      className="flex items-start px-6 py-3 text-sm text-left text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <NewlineSafeReactMarkdown className="text-sm text-gray-700">
        {text}
      </NewlineSafeReactMarkdown>
    </button>
  );
}
