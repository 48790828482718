import { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useApiClient } from "src/api";
import DialoguesNavigation from "src/components/dialogue/dialogues-navigation";
import ScenarioNameHeader, {
  BubbleColor,
} from "src/components/scenario/scenario-name-header";
import PageHeader from "src/components/ui/page-header";
import useFetch from "src/hooks/use-fetch";
import useMessages from "src/hooks/use-messages";
import { Bookmark, MessagesSelection, Scenario } from "src/types/models";

import MessageWithBookmark from "../components/message-with-bookmark";

export default function Preview() {
  const { dialogueId } = useParams();
  const apiClient = useApiClient();
  const searchParams = useSearchParams()[0];
  const messageId = searchParams.has("message_id")
    ? parseInt(searchParams.get("message_id")!)
    : null;
  const [hasScrolled, setHasScrolled] = useState(false);
  const [selectedMessageRef, setSelectedMessageRef] =
    useState<HTMLDivElement | null>();
  const messages = useMessages(parseInt(dialogueId!), true)[0];
  const [messagesSelection, setMessagesSelection] = useState<MessagesSelection>(
    {}
  );
  const { data: scenario } = useFetch<Scenario | null>(
    `/dialogues/${dialogueId}/scenario/`
  );
  const { data: bookmarks } = useFetch<Bookmark[]>(
    `/dialogues/${dialogueId}/bookmarks/`
  );

  const bookmarkByMessageId = useMemo(() => {
    const result: Record<number, Bookmark> = {};
    if (bookmarks) {
      for (const bookmark of bookmarks) {
        result[bookmark.message.id] = bookmark;
      }
    }
    return result;
  }, [bookmarks]);

  useEffect(() => {
    const fetchMessagesSelection = async () => {
      await apiClient
        .get(
          `/tasks/beat-the-bot/messages-selection/?dialogue_id=${dialogueId!}`
        )
        .then((data) => {
          if (dialogueId && dialogueId in data) {
            setMessagesSelection(data[dialogueId]);
          }
        });
    };
    fetchMessagesSelection();
  }, [dialogueId, apiClient]);

  useEffect(() => {
    if (!hasScrolled && selectedMessageRef) {
      selectedMessageRef?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setHasScrolled(true);
    }
  }, [hasScrolled, selectedMessageRef]);

  if (!messages) {
    return null;
  }

  return (
    <>
      <PageHeader
        heading={`Conversation Preview - ${dialogueId}`}
        subHeading="Readonly"
      />
      {dialogueId && <DialoguesNavigation dialogueId={dialogueId} />}
      <main className="max-w-3xl px-4 pb-12 mx-auto sm:px-6 lg:px-8">
        {scenario && (
          <div className="mt-6">
            <ScenarioNameHeader
              scenario={scenario}
              color={BubbleColor.YELLOW}
            />
          </div>
        )}
        <div className="flex flex-col flex-1 py-4 mx-auto overflow-y-hidden bg-white">
          <div className="px-4 overflow-y-auto" id="messages">
            {messages.map((message) => (
              <MessageWithBookmark
                key={message.id}
                message={message}
                isSelected={message.id === messageId}
                messageSelection={messagesSelection[message.id]}
                ref={message.id === messageId ? setSelectedMessageRef : null}
                initialBookmark={bookmarkByMessageId[message.id]}
              />
            ))}
          </div>
        </div>
      </main>
    </>
  );
}
