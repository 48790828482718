import classNames from "src/utils/class-names";

type Props = {
  isEnabled: boolean;
  onClick: () => void;
};

export default function Check(props: Props) {
  const { onClick, isEnabled } = props;
  return (
    <button
      type="button"
      disabled={!isEnabled}
      className={classNames(
        "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm",
        isEnabled
          ? "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          : "cursor-not-allowed bg-gray-100 text-black"
      )}
      onClick={onClick}>
      Check
    </button>
  );
}
