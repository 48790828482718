import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { shallow } from "zustand/shallow";

import useApi from "../api";
import { StaticConversation, TaskState } from "../types";
import useStaticSxsStore from "./use-static-sxs-store";

export default function useStartTask() {
  const taskId = useParams().taskId!;
  const api = useApi();
  const [startTaskError, setStartTaskError] = useState<string | null>(null);
  const [taskState, setTaskState, setTaskData] = useStaticSxsStore(
    (state) => [state.taskState, state.setTaskState, state.setTaskData],
    shallow
  );
  useEffect(() => {
    if (taskState !== TaskState.NOT_STARTED) {
      return;
    }

    const startTask = async () => {
      setTaskState(TaskState.STARTING);
      try {
        const taskData = await api.tasks.start(taskId!);
        const convos = taskData.convos.map((c) => new StaticConversation(c));
        if (!convos.length) {
          setTaskState(TaskState.ERROR);
          setStartTaskError("No conversations found");
          return;
        }
        const newTaskData = {
          ...taskData,
          convos,
        };
        setTaskData(newTaskData);
      } catch (e: any) {
        console.log(e);
        setTaskState(TaskState.ERROR);
        setStartTaskError(e);
      }
    };
    startTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskState]);

  return { startTaskError };
}
