import classNames from "src/utils/class-names";

import { ComparisonScaleOptions } from "../comparison-scale/types";

export default function ButtonScale({
  comparisonScaleOption,
  onClick,
  selectedValue,
}: {
  comparisonScaleOption: ComparisonScaleOptions;
  onClick: (index: number) => void;
  selectedValue?: number;
}) {
  const halfway = Math.floor(comparisonScaleOption / 2);
  const scaleSupportsZero = comparisonScaleOption % 2 === 1;
  const length = scaleSupportsZero
    ? comparisonScaleOption
    : comparisonScaleOption + 1;
  const buttons = Array.from({ length }, (_, index) => {
    const value = index - halfway;
    return {
      value,
      text: value === 0 ? "Neither" : value > 0 ? "B" : "A",
    };
  }).filter((button) => {
    if (scaleSupportsZero) {
      return true;
    }
    return button.value !== 0;
  });

  if (!buttons.length) {
    throw new Error(
      `Comparison scale option ${comparisonScaleOption} is not supported for buttons`
    );
  }
  const basePadding = comparisonScaleOption > 6 ? 4 : 6;
  const borderButtonStyles = `text-xs px-${basePadding * 2}`;

  return (
    <div className="w-full flex text-xs justify-center">
      <span className="isolate inline-flex rounded-md shadow-sm">
        {buttons.map((button, index) => {
          const isFirstButton = index === 0;
          const isLastButton = index === buttons.length - 1;
          const { text, value } = button;
          return (
            <button
              className={classNames(
                `relative inline-flex items-center text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10 px-${basePadding} py-2.5`,
                isFirstButton ? "rounded-l-md text-lg" : borderButtonStyles,
                isLastButton ? "rounded-r-md text-lg" : borderButtonStyles,
                selectedValue === value
                  ? "bg-blue-500"
                  : "bg-white hover:bg-gray-50"
              )}
              onClick={() => onClick(value)}
              key={index}>
              {text}
            </button>
          );
        })}
      </span>
    </div>
  );
}
