import React from "react";

const AnnotatableMessage = ({
  children,
  isAnnotated,
  isSelected,
}: {
  children: React.ReactNode;
  isAnnotated: boolean;
  isSelected: boolean;
}) => {
  if (isSelected) {
    if (isAnnotated) {
      return (
        <div className="px-4 py-2 text-sm font-medium bg-green-400 rounded-lg text-gray group-hover:text-gray-900">
          {children}
        </div>
      );
    } else {
      return (
        <div className="px-4 py-2 text-sm font-medium bg-yellow-200 rounded-lg text-gray group-hover:text-gray-900">
          {children}
        </div>
      );
    }
  } else if (isAnnotated) {
    return (
      <div className="px-4 py-2 text-sm font-medium bg-green-200 rounded-lg text-gray group-hover:text-gray-900">
        {children}
      </div>
    );
  } else {
    return (
      <div className="px-4 py-2 text-sm font-medium bg-gray-100 rounded-lg text-gray group-hover:text-gray-900">
        {children}
      </div>
    );
  }
};

export default AnnotatableMessage;
