import { useState } from "react";

interface DialogueNavigationHeaderProps {
  currentDialogueIndex: number;
  numDialogues: number | null;
  skipDialogueHandler?: () => Promise<unknown>;
  hideDialogueProgressMarker?: boolean;
}

export default function DialoguesNavigationHeader({
  currentDialogueIndex,
  numDialogues,
  skipDialogueHandler,
  hideDialogueProgressMarker,
}: DialogueNavigationHeaderProps) {
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <div className="py-6 text-xl text-gray-900">
      Conversation
      {hideDialogueProgressMarker && ` #${currentDialogueIndex + 1}`}{" "}
      {numDialogues && (
        <>
          {!hideDialogueProgressMarker && (
            <span className="inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-indigo-100 text-indigo-800">
              {currentDialogueIndex + 1} / {numDialogues}
            </span>
          )}
          {skipDialogueHandler && (
            <button
              type="button"
              disabled={isDisabled}
              onClick={async () => {
                setIsDisabled(true);
                await skipDialogueHandler();
                setIsDisabled(false);
              }}
              className="inline-flex items-center mx-2 px-3 py-0.5 rounded-lg text-sm font-medium text-indigo-800 bg-indigo-100 border border-transparent hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              Skip
            </button>
          )}
        </>
      )}
    </div>
  );
}
