import TaskInstruction from "src/components/task/task-instruction";

import useInteractiveSideBySideStore from "../hooks/use-interactive-sxs-store";
import ConversationBox from "./conversation/conversation-box";

export default function TaskContent() {
  const taskData = useInteractiveSideBySideStore((state) => state.taskData);

  if (!taskData) {
    return null;
  }

  return (
    <main className="max-w-7xl px-4 pb-12 mx-auto sm:px-6 lg:px-8">
      <TaskInstruction
        instruction={taskData.instructions}
        markdownInstruction={taskData.markdown_instruction}
        policies={taskData.policies}
      />
      <div className="grid w-full gap-4 mx-auto my-4 sm:grid-cols-1">
        <ConversationBox />
      </div>
    </main>
  );
}
