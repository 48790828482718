import { useState } from "react";
import { useApiClient } from "src/api";
import { Message } from "src/types/models";

export default function useEmojiReaction(
  message: Message
): [string | null, (emoji: string | null) => void] {
  const apiClient = useApiClient();
  const [emojiReaction, setLocalEmojiReaction] = useState<string | null>(
    message.emoji_reaction || null
  );
  const setEmojiReaction = async (emoji: string | null) => {
    await apiClient.post(`/messages/${message.id}/reaction/`, { emoji });
    setLocalEmojiReaction(emoji);
  };

  return [emojiReaction, setEmojiReaction];
}
