import { useMemo } from "react";
import { withApiClient } from "src/api";
import { useApiClient } from "src/api";

import * as conversations from "./conversations";
import * as ratings from "./ratings";
import * as tasks from "./tasks";

export default function useApi() {
  const apiClient = useApiClient();
  return useMemo(
    () => ({
      conversations: withApiClient(conversations, apiClient),
      ratings: withApiClient(ratings, apiClient),
      tasks: withApiClient(tasks, apiClient),
    }),
    [apiClient]
  );
}
