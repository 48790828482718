import ScenarioNameHeader, {
  BubbleColor,
} from "src/components/scenario/scenario-name-header";
import PageHeader from "src/components/ui/page-header";

import { useConversation } from "./data";
import { EntryDisplay } from "./entry-display";
import { Entry } from "./models";

type Props = {
  conversationId: number;
};

export const Conversation = ({ conversationId }: Props) => {
  const { convo, isLoading, isError } = useConversation(conversationId);

  if (isLoading) return <div>loading...</div>;

  if (isError) return <div>error</div>;

  if (!convo) return <div>no conversation found</div>;

  return (
    <Wrapper>
      {convo.entries.map((e: Entry, index: number) => {
        const scenario = convo.scenarioCheckpoints[index * 2];
        return (
          <>
            {scenario && (
              <ScenarioNameHeader
                scenario={scenario}
                color={BubbleColor.YELLOW}
              />
            )}
            <EntryDisplay key={e.id} entry={e} />
          </>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <>
    <PageHeader heading={`Conversation Preview`} subHeading="Readonly" />
    <main className="max-w-3xl mx-auto px-4 pb-8">
      <div className="flex flex-col gap-8 mx-auto">{children}</div>
    </main>
  </>
);
