import ScenarioInstruction from "src/components/scenario/scenario-instruction";
import ScenarioNameHeader, {
  BubbleColor,
} from "src/components/scenario/scenario-name-header";
import ScenariosSelectionListBox from "src/components/scenario/scenarios-selection-list-box";
import { Scenario } from "src/types/models";

import { MaybeScenarioOrSelector } from "../../utils";

type Props = {
  taskUUID: string;
  scenario: MaybeScenarioOrSelector;
  handleSelectScenario: (selectedScenario: Scenario) => Promise<void>;
};

export default function ScenarioContainer(props: Props) {
  const { taskUUID, scenario, handleSelectScenario } = props;

  if (!scenario) return null;

  switch (scenario.type) {
    case "selector":
      return (
        <ScenariosSelectionListBox
          taskUUID={taskUUID}
          selectScenario={handleSelectScenario}
          limitTagIds={scenario.config?.scenario_tags}
        />
      );
    default:
      return (
        <div className="flex flex-col gap-y-4">
          <ScenarioNameHeader scenario={scenario} color={BubbleColor.YELLOW} />
          {scenario.instruction && (
            <ScenarioInstruction instruction={scenario.instruction} />
          )}
        </div>
      );
  }
}
