import { ApiClient } from "src/api";
import { Message, MessageType } from "src/types/models";

import { ApiStaticConversation } from "./types";

export async function get(
  apiClient: ApiClient,
  conversationId: number
): Promise<ApiStaticConversation> {
  const url = `/static-conversations/${conversationId}/`;
  const response = await apiClient.get(url);
  const context = JSON.parse(response.context);
  let messages: Message[];
  if (response.format === "json") {
    messages = context.map((message: any, index: number) => ({
      id: index,
      type:
        message.type === "AI"
          ? MessageType.BOT_MESSAGE
          : MessageType.USER_MESSAGE,
      text: message.text,
    }));
  } else {
    messages = context.map((message: any, index: number) => ({
      id: index,
      type:
        index % 2 === 0 ? MessageType.BOT_MESSAGE : MessageType.USER_MESSAGE,
      text: message,
    }));
  }
  return {
    ...response,
    context: messages,
  };
}
