import { BookmarkIcon, CheckIcon } from "@heroicons/react/outline";
import React from "react";
import SavingSpinner from "src/components/ui/saving-spinner";

export default function AnnotationFormContainer({
  isSaving,
  children,
}: {
  isSaving: boolean | null;
  children: React.ReactNode;
}) {
  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <BookmarkIcon
            className="w-5 h-5 text-indigo-500"
            aria-hidden="true"
          />
        </div>
        <div className="flex-1 ml-3">
          <h3 className="text-lg font-bold">Message Labels</h3>
        </div>
        <div className="flex items-center justify-end">
          {isSaving && <SavingSpinner />}
          {isSaving === false && (
            <>
              <CheckIcon
                className="w-4 h-4 text-indigo-500"
                aria-hidden="true"
              />
              <span className="text-xs text-gray-700">Saved</span>
            </>
          )}
        </div>
      </div>
      <div className="px-4">{children}</div>
    </div>
  );
}
