import { partial } from "lodash";

import { ApiClient } from "./client";

type ApiClientRemoved<T> = T extends (
  apiClient: ApiClient,
  ...args: infer P
) => infer Q
  ? (...args: P) => Q
  : never;

export function withApiClient<T extends { [key: string]: any }>(
  module: T,
  apiClient: ApiClient
): { [K in keyof T]: ApiClientRemoved<T[K]> } {
  const result = {} as { [K in keyof T]: ApiClientRemoved<T[K]> };
  Object.keys(module).forEach((key) => {
    result[key as keyof T] = partial(module[key], apiClient) as any;
  });
  return result;
}

const NUM_RETRY_ATTEMPTS = 5;

export function getRetryOptions(
  setErrorMessage: (message: string | null) => void
) {
  return {
    retryDelay: function (
      attempt: number,
      error: string | null,
      response: Response
    ) {
      return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
    },
    retryOn: function (
      attempt: number,
      error: string | null,
      response: Response
    ) {
      if (attempt >= NUM_RETRY_ATTEMPTS) {
        return false;
      }

      if (error !== null || response.status >= 400) {
        setErrorMessage(
          `Technical error. Retrying... Attempt ${
            attempt + 1
          } / ${NUM_RETRY_ATTEMPTS} ...`
        );
        return true;
      }
    },
  };
}
