import NewlineSafeReactMarkdown from "src/components/text/newline-safe-react-markdown";
import classNames from "src/utils/class-names";

import { Reply } from "./models";

type Props = {
  reply: Reply;
};

export const EntryReply = ({ reply }: Props) => {
  return (
    <div className="flex flex-row max-w-[80%]">
      <span
        className={classNames(
          reply.selected ? "bg-green-200" : "bg-indigo-100",

          "inline-flex items-center rounded-tl rounded-bl px-2 py-0.5 text-xs font-medium text-indigo-800"
        )}>
        {reply.label}
      </span>
      <div
        className={classNames(
          reply.selected ? "bg-green-100" : "bg-gray-100",
          "text-sm font-medium rounded-tr rounded-br text-gray"
        )}>
        <span
          className={classNames(
            reply.selected ? "bg-green-200" : "bg-indigo-100",
            "inline-block px-4 py-1 rounded-br text-xs font-medium text-indigo-800"
          )}>
          {reply.model}
        </span>
        <p className="px-4 py-2">
          <NewlineSafeReactMarkdown>{reply.text}</NewlineSafeReactMarkdown>
        </p>
      </div>
    </div>
  );
};

export default EntryReply;
