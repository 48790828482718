import { useCallback } from "react";
import { shallow } from "zustand/shallow";

import useApi from "../api";
import { StaticConversation, TaskState } from "../types";
import useStaticSxsStore from "./use-static-sxs-store";

export default function useSkipConversation() {
  const api = useApi();
  const [
    taskData,
    getCurrentConversationIndex,
    skipConversation,
    endConversation,
    setTaskState,
  ] = useStaticSxsStore(
    (state) => [
      state.taskData!,
      state.getCurrentConversationIndex,
      state.skipConversation,
      state.endConversation,
      state.setTaskState,
    ],
    shallow
  );
  const currentConversationIndex = getCurrentConversationIndex()!;
  const currentConversation = taskData.convos[currentConversationIndex];

  const handleSkipConversation = useCallback(async () => {
    setTaskState(TaskState.LOADING_NEXT_CONVERSATION);
    const replacement = await api.conversations.skip(currentConversation.id);
    if (replacement) {
      skipConversation(new StaticConversation(replacement));
    } else {
      endConversation(currentConversation.id);
    }
  }, [
    api,
    currentConversation,
    skipConversation,
    endConversation,
    setTaskState,
  ]);

  return {
    skipConversation: taskData.use_adhoc_assignment_strategy
      ? handleSkipConversation
      : undefined,
  };
}
