interface ActionBoxProps {
  icon?: JSX.Element;
  currentDialogueIndex?: number;
  numDialogues?: number;
  title: string;
  description: JSX.Element | string | null;
  callToAction?: JSX.Element | string | null;
}

// TODO (lim): Make this component dialogue-agnostic
export default function ActionBox({
  icon,
  currentDialogueIndex,
  numDialogues,
  title,
  description,
  callToAction,
  children,
}: React.PropsWithChildren<ActionBoxProps>) {
  const numCompletedDialogues =
    currentDialogueIndex !== undefined ? currentDialogueIndex + 1 : null;

  return (
    <div className="p-4 bg-gray-100 rounded-b-lg shadow-inner">
      <div className="flex">
        {icon && <div className="flex-shrink-0">{icon}</div>}
        <div className="flex-1 ml-3">
          <div className="flex justify-between flex-1">
            <h3 className="text-sm font-bold text-gray-800">{title}</h3>
          </div>
          <div className="mt-4">
            {Boolean(numCompletedDialogues) && Boolean(numDialogues) && (
              <>
                <p className="text-sm font-medium text-gray-900">
                  You have completed {numCompletedDialogues} out of{" "}
                  {numDialogues} conversations in this task.
                </p>
                <div className="mt-4" aria-hidden="true">
                  <div className="overflow-hidden bg-gray-200 rounded-full">
                    {numCompletedDialogues === numDialogues ? (
                      <div className="w-full h-2 bg-green-400 rounded-full" />
                    ) : (
                      <div
                        className="h-2 bg-indigo-600 rounded-full"
                        style={{
                          width: `${
                            (numCompletedDialogues! / numDialogues!) * 100
                          }%`,
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="mt-4 text-sm text-gray-700">{description}</div>
          <div className="mt-4 text-sm">{children}</div>
          {callToAction && <div className="mt-4">{callToAction}</div>}
        </div>
      </div>
    </div>
  );
}
