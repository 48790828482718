import classNames from "src/utils/class-names";

interface Props {
  pageSize: number;
  totalCount: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

// Adapted from: https://tailwindui.com/components/application-ui/navigation/pagination - Simple card footer
export default function SimplePaginationFooter({
  pageSize,
  totalCount,
  currentPage,
  setCurrentPage,
}: Props) {
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const startIndex = 1 + pageSize * (currentPage - 1);
  const endIndex = Math.min(totalCount, pageSize * currentPage);

  const previousPageDisabled = currentPage === 1;
  const nextPageDisabled = currentPage === totalPageCount;

  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination">
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{startIndex}</span> to{" "}
          <span className="font-medium">{endIndex}</span> of{" "}
          <span className="font-medium">{totalCount}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={previousPageDisabled}
          className={classNames(
            "relative inline-flex items-center rounded-md border bg-white px-4 py-2 text-sm font-medium",
            previousPageDisabled
              ? "border-gray-100 text-gray-400"
              : "border-gray-300 text-gray-700 hover:bg-gray-50"
          )}>
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={nextPageDisabled}
          className={classNames(
            "relative ml-3 inline-flex items-center rounded-md border bg-white px-4 py-2 text-sm font-medium",
            nextPageDisabled
              ? "border-gray-100 text-gray-400"
              : "border-gray-300 text-gray-700 hover:bg-gray-50"
          )}>
          Next
        </button>
      </div>
    </nav>
  );
}
