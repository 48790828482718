import { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import PageHeader from "src/components/ui/page-header";
import SimplePaginationFooter from "src/components/ui/simple-pagination-footer";
import useSessionStore from "src/features/auth/hooks/use-session-store";
import useDebouncedValue from "src/hooks/use-debounced-value";
import useFetch from "src/hooks/use-fetch";
import useUrlState from "src/hooks/use-url-state";
import { Bookmark, Page } from "src/types/models";

interface BookmarkUser {
  id: number;
  email: string;
  bookmark_count: number;
}

const PAGE_SIZE = 10;
const ALL_BOOKMARKS_VALUE = "all";

export default function Bookmarks() {
  const { search } = useLocation();
  const { bookmarkedBy } = useParams();

  const navigate = useNavigate();
  const user = useSessionStore((state) => state.user);
  const [searchText, setSearchText] = useUrlState("search");
  const [currentPage, setCurrentPage] = useState(1);

  // Don't query the server on every search keystroke by debouncing the input
  const onDebounceUpdate = useCallback(() => setCurrentPage(1), []);
  const debouncedSearch = useDebouncedValue(searchText, 300, onDebounceUpdate);

  const queryParams: Record<string, string> = {};
  if (bookmarkedBy && bookmarkedBy !== ALL_BOOKMARKS_VALUE) {
    queryParams.created_by = bookmarkedBy;
  }
  if (debouncedSearch) {
    queryParams.search = debouncedSearch;
  }
  queryParams.page = currentPage.toString();
  queryParams.page_size = PAGE_SIZE.toString();

  const { data: bookmarks } = useFetch<Page<Bookmark>>(
    "/bookmarks",
    queryParams
  );
  const { data: bookmarkUsers } = useFetch<BookmarkUser[]>("/bookmarks/users");

  if (!bookmarks) {
    return null;
  }

  const bookmarkedByOptions = [{ value: ALL_BOOKMARKS_VALUE, label: "Anyone" }];
  if (user) {
    bookmarkedByOptions.push({ value: user.email, label: user.email });
  }
  if (bookmarkUsers) {
    for (const bookmarkUser of bookmarkUsers) {
      if (user && bookmarkUser.email === user.email) {
        continue;
      }
      bookmarkedByOptions.push({
        value: bookmarkUser.email,
        label: bookmarkUser.email,
      });
    }
  }

  return (
    <>
      <PageHeader heading="Bookmarks" />
      <main className="max-w-5xl px-4 pb-12 mx-auto sm:px-6 lg:px-8">
        <div className="mt-6 mb-16 flex flex-col">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="flex flex-col sm:flex-row mb-8 gap-6 sm:gap-8">
                <div className="w-full sm:w-1/3">
                  <label
                    htmlFor="bookmarked-by"
                    className="block text-xs font-medium text-gray-500 mb-1">
                    Bookmarked by
                  </label>
                  <Select
                    options={bookmarkedByOptions}
                    value={bookmarkedByOptions.find(
                      (option) => option.value === bookmarkedBy
                    )}
                    onChange={(newValue) => {
                      setCurrentPage(1);
                      navigate(`/app/bookmarks/${newValue?.value}${search}`);
                    }}
                    classNamePrefix="react-select"
                    inputId="bookmarked-by"
                  />
                </div>
                <div className="w-full sm:w-1/4">
                  <label
                    htmlFor="search"
                    className="block text-xs font-medium text-gray-500 mb-1">
                    Search
                  </label>
                  <input
                    id="search"
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter a search term..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value || undefined)}
                  />
                </div>
              </div>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300 table-fixed">
                  <thead className="bg-gray-50 text-sm font-semibold text-gray-900">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 sm:pl-6 w-1/6">
                        Bookmarked by
                      </th>
                      <th scope="col" className="px-3 py-3.5 w-3/6 text-center">
                        Message
                      </th>
                      <th scope="col" className="px-3 py-3.5 w-2/6 text-center">
                        Note
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white text-sm text-gray-500">
                    {bookmarks.results.map((bookmark) => (
                      <tr
                        key={bookmark.id}
                        onClick={() =>
                          window.open(
                            `/app/dialogues/preview/${bookmark.message.dialogue_id}?message_id=${bookmark.message.id}`,
                            "_blank"
                          )
                        }
                        className="cursor-pointer hover:bg-gray-100">
                        <td className="whitespace-pre-wrap py-4 pl-4 pr-3 text-gray-700 font-medium">
                          {bookmark.created_by.email}
                        </td>
                        <td className="whitespace-pre-wrap px-3 py-4 align-top">
                          {bookmark.message.text}
                        </td>
                        <td className="whitespace-pre-wrap px-3 py-4 align-top">
                          {bookmark.note}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {bookmarks.count > PAGE_SIZE && (
          <SimplePaginationFooter
            pageSize={PAGE_SIZE}
            totalCount={bookmarks.count}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </main>
    </>
  );
}
