import { debounce } from "lodash";
import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useApiClient } from "src/api";
import useDialoguesStore from "src/hooks/use-dialogues-store";
import { updateRequiredFields } from "src/libs/forms";
import { ActivityState, Dialogue, Task } from "src/types/models";
import { shallow } from "zustand/shallow";

import {
  Annotation,
  AnnotationDataSaver,
  AnnotationForm,
  AnnotationType,
  LocalAnnotationData,
} from "../types";

function addRequiredField(
  setRequiredFields: React.Dispatch<
    React.SetStateAction<Record<string, boolean> | null>
  >
) {
  return (fieldName: string) => {
    setRequiredFields((currentRequiredFields) => {
      if (currentRequiredFields === null) {
        return {
          [fieldName]: true,
        };
      } else if (!(fieldName in currentRequiredFields)) {
        return {
          ...currentRequiredFields,
          [fieldName]: true,
        };
      }
      return currentRequiredFields;
    });
  };
}

/**
 * Handle change on an input field by automatically saving it to the API.
 * Also trigger a callback on successful save, useful to update states accordingly.
 */
export function getAnnotationChangeHandler(
  annotationType: AnnotationType,
  annotationDataSaver: AnnotationDataSaver,
  existingAnnotationData?: LocalAnnotationData,
  formTarget?: string
): ChangeEventHandler {
  return async (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>
  ) => {
    const form = e.target.form!;
    const extraData = existingAnnotationData?.emoji
      ? { emoji: existingAnnotationData.emoji }
      : {};
    const newAnnotationData = formTarget
      ? {
          ...existingAnnotationData,
          [formTarget]: { ...Object.fromEntries(new FormData(form)) },
          ...extraData,
        }
      : {
          ...Object.fromEntries(new FormData(form)),
          ...extraData,
        };
    const targetId =
      annotationType === AnnotationType.MESSAGE
        ? parseInt(form.dataset.messageId!)
        : parseInt(form.dataset.dialogueId!);
    annotationDataSaver.saveLocal(newAnnotationData);
    await annotationDataSaver.saveRemote(
      newAnnotationData,
      annotationType,
      targetId
    );
  };
}

export function useAnnotationForms(task: Task) {
  const isFormDisabled = false;
  const apiClient = useApiClient();
  const [messageAnnotationForm, setMessageAnnotationForm] =
    useState<AnnotationForm | null>(null);
  const [dialogueAnnotationForm, setDialogueAnnotationForm] =
    useState<AnnotationForm | null>(null);
  const [
    messagesAnnotationFormRequiredFields,
    setMessagesAnnotationFormRequiredFields,
  ] = useState<Record<string, boolean> | null>(null);
  const [humanMessageAnnotationForm, setHumanMessageAnnotationForm] =
    useState<AnnotationForm | null>(null);
  const [
    humanMessagesAnnotationFormRequiredFields,
    setHumanMessagesAnnotationFormRequiredFields,
  ] = useState<Record<string, boolean> | null>(null);

  useEffect(() => {
    const getAnnotationForms = async () => {
      const [results, humanAnnotationForm] = (await Promise.all([
        apiClient.get(`/tasks/${task.id}/annotation-forms/`),
        task.annotate_human_messages
          ? apiClient.get(`/tasks/${task.id}/human-message-annotation-form`)
          : null,
      ])) as [AnnotationForm[], AnnotationForm | null];
      if (humanAnnotationForm) {
        setHumanMessageAnnotationForm(humanAnnotationForm);
        updateRequiredFields(
          humanAnnotationForm.value,
          addRequiredField(setHumanMessagesAnnotationFormRequiredFields)
        );
      }

      results.forEach((form) => {
        if (form.type === AnnotationType.DIALOGUE) {
          setDialogueAnnotationForm(form);
        } else {
          setMessageAnnotationForm(form);
          updateRequiredFields(
            form.value,
            addRequiredField(setMessagesAnnotationFormRequiredFields)
          );
        }
      });
    };
    getAnnotationForms();
  }, [apiClient, task.id, task.annotate_human_messages, isFormDisabled]);

  return {
    messageAnnotationForm,
    dialogueAnnotationForm,
    humanMessageAnnotationForm,
    messagesAnnotationFormRequiredFields,
    setMessagesAnnotationFormRequiredFields,
    humanMessagesAnnotationFormRequiredFields,
    setHumanMessagesAnnotationFormRequiredFields,
  };
}

export function useAnnotationData(task: Task, currentDialogue: Dialogue) {
  const apiClient = useApiClient();
  const [messagesAnnotationData, setMessagesAnnotationData] = useState<{
    [messageId: number]: LocalAnnotationData;
  }>({});
  const [dialogueAnnotationData, setDialogueAnnotationData] =
    useState<LocalAnnotationData>(null);
  const [isSavingAnnotationData, setIsSavingAnnotationData] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    const getDialogueAnnotationData = async () => {
      const { results } = await apiClient.get(
        `/annotation-data/?task_id=${task.id}&target_dialogue_id=${
          currentDialogue!.id
        }`
      );
      const messagesAnnotation: { [messageId: number]: LocalAnnotationData } =
        {};
      results.forEach((annotation: Annotation) => {
        const isMessageAnnotation = annotation.target_message_id !== null;
        if (isMessageAnnotation) {
          messagesAnnotation[annotation.target_message_id!] = annotation.data;
        } else {
          setDialogueAnnotationData(annotation.data);
        }
        setMessagesAnnotationData(messagesAnnotation);
      });
    };
    getDialogueAnnotationData();
  }, [task, apiClient, currentDialogue]);

  const saveAnnotationData = useMemo(
    () =>
      debounce(
        async (
          data: LocalAnnotationData,
          annotationType: AnnotationType,
          target_id: number
        ) => {
          const payload: Annotation = { task_id: task.id, data };
          if (annotationType === AnnotationType.MESSAGE) {
            payload.target_message_id = target_id;
          } else {
            payload.target_dialogue_id = target_id;
          }
          setIsSavingAnnotationData(true);
          await apiClient.post("/annotation-data/", payload);
          setIsSavingAnnotationData(false);
        },
        500
      ),
    [task, apiClient, setIsSavingAnnotationData]
  );

  const setSingleMessageAnnotationData = useCallback(
    (messageId: number, data: LocalAnnotationData) => {
      setMessagesAnnotationData({
        ...messagesAnnotationData,
        [messageId]: data,
      });
    },
    [setMessagesAnnotationData, messagesAnnotationData]
  );

  const messageAnnotationInputChangeHandler = useCallback(
    (messageId: number, formTarget?: string) =>
      getAnnotationChangeHandler(
        AnnotationType.MESSAGE,
        {
          saveLocal: (data) => setSingleMessageAnnotationData(messageId, data),
          saveRemote: saveAnnotationData,
        },
        messagesAnnotationData[messageId],
        formTarget
      ),
    [saveAnnotationData, messagesAnnotationData, setSingleMessageAnnotationData]
  );

  const dialogueAnnotationInputChangeHandler = useMemo(
    () =>
      getAnnotationChangeHandler(AnnotationType.DIALOGUE, {
        saveLocal: setDialogueAnnotationData,
        saveRemote: saveAnnotationData,
      }),
    [saveAnnotationData]
  );

  return {
    dialogueAnnotationData,
    messagesAnnotationData,
    messageAnnotationInputChangeHandler,
    dialogueAnnotationInputChangeHandler,
    saveAnnotationData,
    isSavingAnnotationData,
  };
}

export function useNextDialogueHandler(task: Task) {
  const apiClient = useApiClient();
  const [
    currentActivity,
    currentDialogueIndex,
    setCurrentDialogueIndex,
    setCurrentActivity,
    addDialogue,
  ] = useDialoguesStore(
    (state) => [
      state.currentActivity,
      state.currentDialogueIndex,
      state.setCurrentDialogueIndex,
      state.setCurrentActivity,
      state.addDialogue,
    ],
    shallow
  );
  const completeDialogueHandler = useCallback(async () => {
    const { data } = await apiClient.post("/tasks/start", { task_id: task.id });
    if (data.dialogues) {
      for (const dialogue of data.dialogues) {
        addDialogue(dialogue as Dialogue);
      }
    }

    const newActivity = {
      ...currentActivity!,
      state: ActivityState.COMPLETED,
      // TODO: drop the time_spent column in the database. It's not used anymore.
      time_spent: 0,
    };
    return apiClient
      .patch(`/dialogue-activities/${currentActivity!.id}/`, newActivity)
      .then(() => {
        setCurrentActivity(newActivity);
      });
  }, [currentActivity, apiClient, setCurrentActivity, addDialogue, task]);

  const nextDialogueHandler = async () => {
    if (currentActivity?.state !== ActivityState.COMPLETED) {
      await completeDialogueHandler();
    }

    if (task.use_adhoc_assignment_strategy) {
      const { data } = await apiClient.post("/tasks/start", {
        task_id: task.id,
      });
      if (data.dialogues) {
        data.dialogues.map((d: Dialogue) => addDialogue(d));
      }
    }

    setCurrentDialogueIndex(currentDialogueIndex + 1);
  };

  const skipDialogueHandler = async () => {
    const newActivity = {
      ...currentActivity!,
      state: ActivityState.SKIPPED,
      time_spent: 0,
    };

    await apiClient.patch(
      `/dialogue-activities/${currentActivity!.id}/`,
      newActivity
    );

    if (task.use_adhoc_assignment_strategy) {
      const { data } = await apiClient.post("/tasks/start", {
        task_id: task.id,
      });
      if (data.dialogues) {
        data.dialogues.map((d: Dialogue) => addDialogue(d));
      }
    }

    setCurrentActivity(newActivity);
    setCurrentDialogueIndex(currentDialogueIndex + 1);
  };

  return { nextDialogueHandler, completeDialogueHandler, skipDialogueHandler };
}
