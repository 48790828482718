import { RefreshIcon } from "@heroicons/react/solid";

interface BothOptionsAreBadButtonProps {
  handleRefreshOptions: () => Promise<void>;
  numRefreshAttempts: number;
  maxRefreshAttempts: number;
  onLastClick: () => void;
  text: string;
}

export default function BothOptionsAreBadButton({
  handleRefreshOptions,
  numRefreshAttempts,
  maxRefreshAttempts,
  onLastClick,
  text,
}: BothOptionsAreBadButtonProps) {
  const isLastRefreshAttempt =
    maxRefreshAttempts === 0 || numRefreshAttempts === maxRefreshAttempts;
  return (
    <button
      type="button"
      onClick={() => {
        if (isLastRefreshAttempt) {
          onLastClick();
        } else {
          handleRefreshOptions();
        }
      }}
      className="flex items-center justify-center flex-1 py-2 ml-3 text-sm text-red-700 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
      <RefreshIcon className="w-4 h-4 mr-2 text-red-500" />
      <span className="flex flex-col items-start justify-center">
        <span>{text}</span>
        <span>
          {numRefreshAttempts > 0 && !isLastRefreshAttempt
            ? `(${numRefreshAttempts} / ${maxRefreshAttempts} attempts)`
            : ""}
        </span>
      </span>
    </button>
  );
}
