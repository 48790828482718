import { DocumentTextIcon, ScaleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import NewlineSafeReactMarkdown from "src/components/text/newline-safe-react-markdown";
import { PoliciesDisplay } from "src/features/annotation-tasks/components/policy-display";
import { SerializedPolicyBatchResponse } from "src/types/models";
import classNames from "src/utils/class-names";

export default function TaskInstruction({
  instruction,
  markdownInstruction,
  policies,
}: {
  instruction: string;
  markdownInstruction?: string;
  policies?: SerializedPolicyBatchResponse;
}) {
  const [activeTab, setActiveTab] = useState<"instructions" | "policies">(
    "instructions"
  );

  const shouldShowTabBar = policies && policies.length > 0;

  return (
    <div className="mx-auto divide-y-2 divide-gray-200">
      <dl className="mt-6 space-y-6">
        <div className="pt-6">
          {shouldShowTabBar && (
            <div className="flex gap-2 pb-4">
              <div
                className={classNames(
                  "flex flex-1 rounded-2xl p-4 border border-gray-100",
                  activeTab === "instructions"
                    ? "bg-gray-100"
                    : "cursor-pointer"
                )}
                onClick={() => setActiveTab("instructions")}>
                <div className="flex-shrink-0">
                  <DocumentTextIcon
                    className="w-5 h-5 text-indigo-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex-1 ml-3">
                  <h3 className="text-sm font-bold text-gray-800">
                    Task Instructions
                  </h3>
                </div>
              </div>
              <div
                className={classNames(
                  "flex flex-1 rounded-2xl p-4 border border-gray-100",
                  activeTab === "policies" ? "bg-gray-100" : "cursor-pointer"
                )}
                onClick={() => setActiveTab("policies")}>
                <div className="flex-shrink-0">
                  <ScaleIcon
                    className="w-5 h-5 text-indigo-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex-1 ml-3">
                  <h3 className="text-sm font-bold text-gray-800">Policies</h3>
                </div>
              </div>
            </div>
          )}

          <div className="rounded-2xl p-4 border border-gray-100">
            {activeTab === "policies" && policies && (
              <PoliciesDisplay policies={policies} />
            )}

            {activeTab === "instructions" && markdownInstruction && (
              <NewlineSafeReactMarkdown className="text-sm">
                {markdownInstruction}
              </NewlineSafeReactMarkdown>
            )}

            {activeTab === "instructions" && !markdownInstruction && (
              <div
                className="prose max-w-none pr-12 mt-8 text-sm"
                dangerouslySetInnerHTML={{ __html: instruction }}
              />
            )}
          </div>
        </div>
      </dl>
    </div>
  );
}
