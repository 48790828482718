import { shallow } from "zustand/shallow";

import useQuizStore from "../hooks/use-quiz-store";

export default function Header() {
  const [currentQuestionIndex, scores, getNumQuestion] = useQuizStore(
    (state) => [
      state.currentQuestionIndex,
      state.scores,
      state.getNumQuestions,
    ],
    shallow
  );
  const numQuestions = getNumQuestion();
  return (
    <div className="py-2 text-xl text-gray-900 flex items-center justify-between">
      <span className="flex items-center gap-x-4">
        Question {`${currentQuestionIndex + 1}`} / {numQuestions}
        {scores !== null && (
          <>
            <span className="inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-green-100 text-green-800">
              Correct: {scores.correct}
            </span>
            <span className="inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-red-100 text-red-800">
              Incorrect: {scores.incorrect}
            </span>
          </>
        )}
      </span>
    </div>
  );
}
