import { BotResponseOption, Message } from "src/types/models";

import { APIStaticConversation } from "../api/types";

export enum ConversationState {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
}

export class StaticConversation {
  id: number;
  context: Message[];
  firstOption: BotResponseOption;
  secondOption: BotResponseOption;
  state: ConversationState;
  extras?: any;

  constructor({
    id,
    context,
    option_a,
    option_b,
    state,
    extras,
  }: APIStaticConversation) {
    this.id = id;
    this.context = context;
    this.state = state;
    this.extras = extras ? JSON.parse(extras) : null;
    const swap = Math.random() > 0.5;
    this.firstOption = {
      model: swap ? "B" : "A",
      text: swap ? option_b : option_a,
    };
    this.secondOption = {
      model: swap ? "A" : "B",
      text: swap ? option_a : option_b,
    };
  }
}
