type BaseQuestion = {
  name: string;
  idx: number;
  question: string;
  type: string;
  multiple: number;
};

export type ChoicesQuestion = BaseQuestion & {
  option_1: string;
  is_correct_1: number;
  explanation_1: string;
  option_2?: string;
  is_correct_2?: number;
  explanation_2?: string;
  option_3?: string;
  is_correct_3?: number;
  explanation_3?: string;
  option_4?: string;
  is_correct_4?: number;
  explanation_4?: string;
  option_5?: string;
  is_correct_5?: number;
  explanation_5?: string;
  option_6?: string;
  is_correct_6?: number;
  explanation_6?: string;
};

export type QuizContent = {
  name: string;
  title: string;
  questions: ChoicesQuestion[];
};

export type Quiz = {
  content: QuizContent;
  type: "TRAINING" | "TESTING";
  show_explanation: boolean;
};

export type QuizScore = { correct: number; incorrect: number };

export enum AnswerCheckingState {
  NOT_CHECKED,
  CHECKING,
  CHECKED,
}
