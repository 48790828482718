type Props = {
  id?: number;
  text: string;
};

export const EntryMessage = ({ id, text }: Props) => (
  <div className="flex items-center justify-end flex-1 my-2">
    <p
      className="px-4 py-2 text-sm font-medium rounded-lg bg-blue-500 text-white"
      title={id ? `Interactive Message ID ${id}` : undefined}>
      {text}
    </p>
  </div>
);

export default EntryMessage;
