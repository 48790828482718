import { RewindIcon } from "@heroicons/react/solid";

type Props = {
  undo: () => void;
};

export default function UndoButton(props: Props) {
  const { undo } = props;
  return (
    <button
      type="button"
      className="mb-4 text-xs text-blue-500 underline hover:cursor-pointer"
      onClick={undo}>
      <RewindIcon className="inline h-6 w-6 pr-2" />
      Undo Last Choice
    </button>
  );
}
