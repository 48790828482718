import parse, { HTMLReactParserOptions, Element } from "html-react-parser";
import { ChangeEventHandler, ReactNode } from "react";

import parseChoiceInput from "./choice-input";
import parseDiv from "./div";
import parseSearchResults from "./search-results";
import parseSection from "./section";
import parseSelect from "./select";
import parseTextInput from "./text-input";
import { FormData } from "./types";

export type ParseArgs = {
  formData?: FormData;
  isDisabled?: boolean;
  onChange?: ChangeEventHandler;
  extras?: {
    searchResults?: string;
  };
};

/**
 * Parse raw HTML string of empty form into controlled React elements
 * with existing data merged in and an onChange handler attached.
 *
 * Example: Given the form string '<input type="text" name="foo" />',
 * the resultant react component
 * will be: <input type="text" name="foo" value={data.foo} onChange={handleChange} />
 */

export function parseForm(
  formString: string,
  parseArgs?: ParseArgs
): ReactNode {
  const parseInputArgs = {
    formData: {},
    isDisabled: false,
    ...parseArgs,
  };
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.attribs) {
        switch (domNode.name) {
          case "section":
            return parseSection(domNode, options);
          case "input":
            switch (domNode.attribs.type) {
              case "checkbox":
              case "radio":
                return parseChoiceInput(domNode, parseInputArgs);
              default:
                return parseTextInput(domNode, parseInputArgs);
            }
          case "textarea":
            return parseTextInput(domNode, parseInputArgs);
          case "select":
            return parseSelect(domNode, options, parseInputArgs);
          case "div":
            if (domNode.attribs.data === "search-results") {
              return parseSearchResults(parseArgs?.extras?.searchResults);
            }
            return parseDiv(domNode, options, parseInputArgs);
          default:
            break;
        }
        return domNode;
      }
    },
  };
  return parse(formString, options);
}
