import { useState } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";
import OptionButton from "src/components/side-by-side/option-button";
import SideBySideActionContainer from "src/components/side-by-side/side-by-side-action-container";
import { LocalAnnotationData } from "src/features/annotation-tasks/types";
import { BotResponseOption } from "src/types/models";
import { randomElement } from "src/utils/array";
import classNames from "src/utils/class-names";
import { shallow } from "zustand/shallow";

import useInteractiveSideBySideStore from "../../hooks/use-interactive-sxs-store";
import { ComparisonScale } from "../comparison-scale";
import { ComparisonScaleOptions } from "../comparison-scale/types";
import BothOptionsAreBadButton from "../sxs-options/both-are-bad-button";
import BothOptionsAreGoodButton from "../sxs-options/both-are-good-button";
import OptionButtonFullText from "../sxs-options/option-button-full-text";

interface Props {
  comparisonScaleEnabled?: boolean;
  comparisonScaleOption?: ComparisonScaleOptions;
  variant?: "normal" | "full-text";
  select: (args: {
    option: BotResponseOption | null;
    both_are_good: boolean;
    both_are_bad: boolean;
    alternative?: string;
    annotation_data?: LocalAnnotationData;
    comparison_scale_selected?: number;
  }) => void;
  handleRefreshOptions: () => Promise<void>;
  shuffledOptions: BotResponseOption[];
}

export default function SxsActions({
  shuffledOptions,
  select,
  handleRefreshOptions,
  comparisonScaleEnabled = false,
  comparisonScaleOption,
  variant = "normal",
}: Props) {
  const [alternative, setAlternative] = useState("");
  const [
    refreshHistory,
    taskData,
    hasEnoughOptions,
    getShouldShowBothOptionsAreBadButton,
    getShouldShowBothOptionsAreGoodButton,
    getShouldWriteAlternative,
    getMaxRefreshAttempts,
  ] = useInteractiveSideBySideStore(
    (state) => [
      state.refreshHistory,
      state.taskData,
      state.hasEnoughOptions,
      state.getShouldShowBothOptionsAreBadButton,
      state.getShouldShowBothOptionsAreGoodButton,
      state.getShouldWriteAlternative,
      state.getMaxRefreshAttempts,
    ],
    shallow
  );
  const numRefreshAttempts = refreshHistory.length;
  const shouldWriteAlternative = getShouldWriteAlternative();
  const showBothOptionsAreBad = getShouldShowBothOptionsAreBadButton();
  const showBothOptionsAreGood = getShouldShowBothOptionsAreGoodButton();

  if (!hasEnoughOptions()) {
    return null;
  }

  const submitAlternative = () => {
    select({
      option: null,
      both_are_good: false,
      both_are_bad: true,
      alternative,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === "Enter" && !e.shiftKey) {
      e.preventDefault();
      submitAlternative();
      return;
    }
  };

  return (
    <SideBySideActionContainer>
      {comparisonScaleEnabled && comparisonScaleOption !== undefined && (
        <ComparisonScale
          comparisonScaleOption={comparisonScaleOption}
          select={(option, comparisonScaleSelected) =>
            select({
              both_are_good: false,
              both_are_bad: false,
              option,
              comparison_scale_selected: comparisonScaleSelected,
            })
          }
        />
      )}
      <div
        className={classNames(
          "grid grid-cols-1 gap-y-4",
          variant === "full-text" ? "" : "md:grid-cols-2"
        )}>
        {!comparisonScaleEnabled &&
          taskData!.model_options.map((option, index) =>
            variant === "full-text" ? (
              <OptionButtonFullText
                text={shuffledOptions[index].text}
                label={option}
                key={`option-${index}`}
                onClick={() =>
                  select({
                    option: shuffledOptions[index],
                    both_are_good: false,
                    both_are_bad: false,
                  })
                }
              />
            ) : (
              <OptionButton
                text={option}
                key={`option-${index}`}
                onClick={() =>
                  select({
                    option: shuffledOptions[index],
                    both_are_good: false,
                    both_are_bad: false,
                  })
                }
              />
            )
          )}
        {showBothOptionsAreGood && (
          <BothOptionsAreGoodButton
            onClick={() =>
              select({
                option: randomElement(shuffledOptions),
                both_are_good: true,
                both_are_bad: false,
              })
            }
            text={shuffledOptions.length > 2 ? "All are good" : "Both are good"}
          />
        )}
        {showBothOptionsAreBad && (
          <BothOptionsAreBadButton
            handleRefreshOptions={handleRefreshOptions}
            numRefreshAttempts={numRefreshAttempts}
            maxRefreshAttempts={getMaxRefreshAttempts()}
            text={shuffledOptions.length > 2 ? "All are bad" : "Both are bad"}
            onLastClick={() =>
              select({
                option: randomElement(shuffledOptions),
                both_are_good: false,
                both_are_bad: true,
              })
            }
          />
        )}
      </div>
      {shouldWriteAlternative && (
        <div className="flex items-end justify-between p-4 mt-4 mb-2 space-x-4">
          <div className="flex-1 border-b border-gray-200 w-ful focus-within:border-indigo-600">
            <label htmlFor="alternative" className="sr-only">
              Add your comment
            </label>
            <ReactTextareaAutosize
              minRows={3}
              name="alternative"
              id="alternative"
              className="block w-full p-0 pb-2 bg-white border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
              placeholder="If all options are bad, please write an alternative response"
              defaultValue={alternative}
              onChange={(e) => setAlternative(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="flex-shrink-0">
            <button
              onClick={submitAlternative}
              disabled={alternative.length === 0}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              Submit
            </button>
          </div>
        </div>
      )}
    </SideBySideActionContainer>
  );
}
