import { useMemo } from "react";
import { withApiClient } from "src/api";
import { useApiClient } from "src/api";

import * as conversations from "./conversations";
import * as feedbackForms from "./feedback-forms";
import * as interactiveTasks from "./interactive-tasks";
import * as scenarios from "./scenarios";

export const NUM_RETRY_ATTEMPTS = 5;

export default function useApi() {
  const apiClient = useApiClient();
  return useMemo(
    () => ({
      conversations: withApiClient(conversations, apiClient),
      interactiveTasks: withApiClient(interactiveTasks, apiClient),
      scenarios: withApiClient(scenarios, apiClient),
      feedbackForms: withApiClient(feedbackForms, apiClient),
    }),
    [apiClient]
  );
}
