import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "src/index.css";
import reportWebVitals from "src/report-web-vitals";
import AppRoutes from "src/routes";
import "tippy.js/dist/tippy.css";

if (process.env.NODE_ENV !== "production") {
  window.settings = {
    STATIC_URL: process.env.REACT_APP_STATIC_URL!,
    LOGIN_URL: process.env.REACT_APP_LOGIN_URL!,
    PROLIFIC_LOGIN_URL: process.env.REACT_APP_PROLIFIC_LOGIN_URL!,
    LOGIN_REDIRECT_URL: process.env.REACT_APP_LOGIN_REDIRECT_URL!,
  };
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
