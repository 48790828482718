import { ApiClient } from "src/api";
import { FeedbackForm, FeedbackFormData } from "src/types/models";

export async function fetch(
  apiClient: ApiClient,
  conversationId: number
): Promise<{ form: FeedbackForm; data: FeedbackFormData }> {
  const { form, data } = await apiClient.get(
    `/interactive-tasks/feedback-form/?conversation_id=${conversationId}`
  );
  return { form, data };
}

export async function submit(
  apiClient: ApiClient,
  conversationId: number,
  feedbackFormData: FeedbackFormData
): Promise<void> {
  apiClient.post("/interactive-tasks/feedback-data/", {
    conversation_id: conversationId,
    data: feedbackFormData,
  });
}
