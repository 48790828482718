import UsersIcon from "@heroicons/react/outline/UsersIcon";
import { useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useApiClient } from "src/api";
import useSessionStore from "src/features/auth/hooks/use-session-store";
import { shallow } from "zustand/shallow";

export default function SetUserDetails() {
  const apiClient = useApiClient();
  const [user, setUser] = useSessionStore(
    (state) => [state.user!, state.setUser],
    shallow
  );
  const [firstName, setFirstName] = useState(user?.first_name || "");
  const searchParams = useSearchParams()[0];
  const location = useLocation() as { state?: { from: { pathname: string } } };
  const redirectUrl =
    searchParams.get("next") || location.state?.from?.pathname || "/";
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!firstName) {
      return;
    }
    const payload = { first_name: firstName };
    await apiClient.patch("/auth/user/", payload);
    setUser({ ...user, ...payload });
    navigate(redirectUrl);
  };

  return (
    <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
          Please confirm your details
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
          <form
            method="POST"
            onSubmit={handleSubmit}
            className="flex flex-col gap-y-4">
            <label
              htmlFor="firstname"
              className="block text-sm font-medium text-gray-700">
              Please type your first name below.
            </label>
            <span className="text-xs text-gray-700 italic">
              This will be given to the AI who will use it during the
              conversation if applicable.
            </span>
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <UsersIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                name="firstname"
                id="firstname"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="block w-full pl-10 border-gray-300 rounded-none rounded-l-md sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <button
              type="submit"
              className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
