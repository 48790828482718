import NewlineSafeReactMarkdown from "src/components/text/newline-safe-react-markdown";

interface OptionTextProps {
  text: string;
  option: string;
  modelName: string | null;
}

export default function OptionText({
  text,
  option,
  modelName,
}: OptionTextProps) {
  return (
    <div className="flex flex-col bg-white border-2 border-gray-100 rounded-2xl">
      <div className="relative flex-1 p-4 space-y-4">
        <div className="absolute top-0 inline-block px-4 py-1 text-sm text-white transform -translate-y-1/2 bg-indigo-400 rounded-full shadow-sm">
          {option}
        </div>
        {modelName && (
          <div className="flex items-center justify-end">
            <span className=" rounded bg-yellow-100 px-2 py-0.5 text-xs font-medium text-yellow-800">
              Model {modelName}
            </span>
          </div>
        )}
        <NewlineSafeReactMarkdown className="text-sm text-gray-700">
          {text}
        </NewlineSafeReactMarkdown>
      </div>
    </div>
  );
}
