import { useEffect, useState } from "react";
import PageHeader from "src/components/ui/page-header";
import { MessageType } from "src/types/models";

import useApi, { ApiStaticConversation } from "../api";
import {
  StaticPreviewEntryMessage,
  StaticPreviewReply,
  StaticPreviewReplyOption,
} from "./replies";
import StaticLabelStats from "./stats";

export const Conversation = ({
  conversationId,
}: {
  conversationId: number;
}) => {
  const api = useApi();
  const [conversation, setConversation] = useState<ApiStaticConversation>();
  useEffect(() => {
    const fetchData = async () => {
      const staticConversation = await api.staticConversations.get(
        conversationId
      );
      setConversation(staticConversation);
    };
    fetchData();
  }, [api, conversationId]);

  return (
    <>
      <PageHeader heading={`Conversation Preview`} subHeading="Readonly" />
      <main className="max-w-3xl mx-auto px-4 pb-8">
        <div className="flex flex-col gap-8 mx-auto">
          {conversation?.context.map((message, index) =>
            message.type === MessageType.BOT_MESSAGE ? (
              <StaticPreviewReply key={index} text={message.text} />
            ) : (
              <StaticPreviewEntryMessage key={index} text={message.text} />
            )
          )}

          <StaticPreviewReplyOption
            option="A"
            optionText={conversation?.option_a!}
            isWinner={
              conversation?.counts &&
              conversation?.counts["A"] > conversation?.counts["B"]
                ? true
                : false
            }
          />

          <StaticPreviewReplyOption
            option="B"
            optionText={conversation?.option_b!}
            isWinner={
              conversation?.counts &&
              conversation?.counts["B"] > conversation?.counts["A"]
                ? true
                : false
            }
          />

          <StaticLabelStats
            stats={[
              {
                name: "Option A",
                count: conversation?.counts && conversation?.counts["A"],
                isWinner:
                  conversation?.counts &&
                  conversation?.counts["A"] > conversation?.counts["B"],
              },
              {
                name: "Option B",
                count: conversation?.counts && conversation?.counts["B"],
                isWinner:
                  conversation?.counts &&
                  conversation?.counts["B"] > conversation?.counts["A"],
              },
              {
                name: "Both",
                count: conversation?.counts && conversation?.counts["both"],
                isWinner:
                  conversation?.counts &&
                  conversation?.counts["Both"] > conversation?.counts["A"] &&
                  conversation?.counts["Both"] > conversation?.counts["B"],
              },
            ]}
            total={conversation?.counts.total}
          />
        </div>
      </main>
    </>
  );
};
