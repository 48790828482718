import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { HTMLReactParserOptions, Element, domToReact } from "html-react-parser";
import { ReactElement } from "react";

export default function parseSection(
  domNode: Element,
  options: HTMLReactParserOptions
): ReactElement {
  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-100 rounded-sm hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
            <span>{domNode.attribs.title}</span>
            <ChevronUpIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } h-5 w-5 text-gray-500`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm bg-white">
            {domToReact(domNode.children, options)}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
