import React, { ForwardedRef, forwardRef, MouseEventHandler } from "react";
import { Message } from "src/types/models";
import resolveStaticUrl from "src/utils/static-url";
import { shallow } from "zustand/shallow";

import useTargetSentencesStore from "../hooks/use-target-sentences";

interface AnnotatableBotMessageProps {
  message: Message;
  handleClick?: MouseEventHandler<HTMLDivElement>;
}

const AnnotatableBotMessageBySentence = forwardRef(
  (props: AnnotatableBotMessageProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { message } = props;
    const [currentTargetSentenceIndex, targetSentences] =
      useTargetSentencesStore(
        (state) => [state.currentTargetSentenceIndex, state.targetSentences],
        shallow
      );
    const currentTargetSentence = targetSentences[currentTargetSentenceIndex];
    const splitRegex = new RegExp(
      `(${currentTargetSentence.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")})`
    );
    const parts = message.text.split(splitRegex).filter(Boolean);
    const messageText = parts.map((text, index) => {
      if (text.trim() === currentTargetSentence.trim()) {
        return (
          <span key={index} className="bg-[yellow]">
            {text}
          </span>
        );
      } else {
        // TODO: markdown?
        return text;
      }
    });

    return (
      <div
        className="relative flex items-center justify-start flex-1 my-4"
        ref={ref}>
        <img
          className="inline-block w-8 h-8 rounded-full"
          src={resolveStaticUrl("img/bot_icon.png")}
          alt="bot-icon"
        />
        <div className="ml-2">
          <div className="px-4 py-2 text-sm font-medium bg-gray-100 rounded-lg text-gray group-hover:text-gray-900 whitespace-pre-wrap">
            {messageText}
          </div>
        </div>
      </div>
    );
  }
);

export default AnnotatableBotMessageBySentence;
