import { useState } from "react";
import { BotResponseOption } from "src/types/models";
import { shallow } from "zustand/shallow";

import useInteractiveSideBySideStore from "../../hooks/use-interactive-sxs-store";
import ButtonScale from "../comparison-scale/button-scale";
import { ComparisonScaleOptions } from "../comparison-scale/types";

export function ComparisonScale({
  select,
  comparisonScaleOption,
}: {
  select: (
    option: BotResponseOption | null,
    comparisonScaleSelected: number
  ) => void;
  comparisonScaleOption: ComparisonScaleOptions;
}) {
  const [rangeVal, setRangeValue] = useState<number | undefined>(undefined);
  const [options] = useInteractiveSideBySideStore(
    (state) => [state.options],
    shallow
  );

  const selectedOptionIndex = rangeVal !== undefined && rangeVal > 0 ? 1 : 0;
  const option = options[selectedOptionIndex];

  return (
    <div className="flex flex-col flex-1 my-4 gap-y-4">
      <div className="w-full p-5 my-4 overflow-y-auto text-sm text-gray-900 rounded-lg bg-gray-50">
        {comparisonScaleOption === ComparisonScaleOptions.CONTINUOUS ? (
          <div>
            <input
              type="range"
              min={-50}
              max={50}
              defaultValue={0}
              onChange={(event) => setRangeValue(Number(event.target.value))}
              className="h-4 w-full cursor-pointer appearance-none"
            />
          </div>
        ) : (
          <>
            <ButtonScale
              comparisonScaleOption={comparisonScaleOption}
              selectedValue={rangeVal}
              onClick={(selectedNumber) => {
                setRangeValue(selectedNumber);
              }}
            />
          </>
        )}
        <div className="w-full flex justify-between mt-4">
          <div>A is much better</div>
          <div>B is much better</div>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          disabled={rangeVal === undefined}
          className="inline-flex items-center justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-400 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full"
          onClick={() => {
            select(option, rangeVal || 0);
          }}>
          {rangeVal !== undefined ? "Submit" : "Please select a value"}
        </button>
      </div>
    </div>
  );
}
