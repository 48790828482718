import { useCallback } from "react";
import { useApiClient } from "src/api";
import useDialoguesStore from "src/hooks/use-dialogues-store";
import { shallow } from "zustand/shallow";

export default function useEndDialogue(callback?: () => void) {
  const apiClient = useApiClient();
  const [currentDialogue, setDialogueCompleted] = useDialoguesStore(
    (state) => [state.currentDialogue!, state.setDialogueCompleted],
    shallow
  );

  const handleEndDialogue = useCallback(
    async (isUserTimeOut: boolean = false) => {
      if (!currentDialogue) {
        return;
      }
      await apiClient
        .post(`/dialogues/${currentDialogue.id}/end/`, {
          is_timeout: isUserTimeOut,
        })
        .then(() => {
          setDialogueCompleted(currentDialogue.id);
          if (callback) {
            callback();
          }
        });
    },
    [currentDialogue, setDialogueCompleted, apiClient, callback]
  );
  return handleEndDialogue;
}
