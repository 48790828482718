import { Outlet } from "react-router-dom";
import NavBar from "src/components/ui/nav-bar";

export default function AppLayout() {
  return (
    <div className="min-h-full">
      <NavBar />
      <Outlet />
    </div>
  );
}
