import { ApiClient, getRetryOptions } from "src/api";
import { Message } from "src/types/models";

interface MessagePayload {
  dialogue_id: number;
  text: string;
  existing_messages?: Message[];
}

export async function chat(
  apiClient: ApiClient,
  messagePayload: MessagePayload,
  setErrorMessage: (message: string | null) => void,
  inferenceState?: any
) {
  return apiClient
    .post(
      "/tasks/human-to-bot/chat/",
      { ...messagePayload, inference_state: inferenceState },
      {
        retry: getRetryOptions(setErrorMessage),
      }
    )
    .catch((error) => {
      setErrorMessage(
        "Something went wrong. Please try again by refreshing the page or contacting the administrator."
      );
    });
}
