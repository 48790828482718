import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useApiClient } from "src/api";
import { Message } from "src/types/models";

export default function useMessages(
  dialogueId: number,
  includeLabels: boolean = false
): [Message[], Dispatch<SetStateAction<Message[]>>] {
  const apiClient = useApiClient();
  const [messages, setMessages] = useState<Message[]>([]);
  const url = includeLabels
    ? `/messages/?dialogue_id=${dialogueId}&include_labels=true`
    : `/messages/?dialogue_id=${dialogueId}`;

  useEffect(() => {
    const getMessages = async () => {
      await apiClient.get(url).then(({ results }) => setMessages(results));
    };
    getMessages();
  }, [apiClient, dialogueId, url]);

  return [messages, setMessages];
}
