import { ApiClient } from "src/api";
import { Message, Scenario } from "src/types/models";

export async function select(
  apiClient: ApiClient,
  conversationId: number,
  scenario: Scenario,
  turn?: number
): Promise<Message[]> {
  return apiClient.post("/interactive-tasks/select-scenario/", {
    scenario_id: scenario.id,
    conversation_id: conversationId,
    turn,
  });
}
