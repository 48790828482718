import { Route, Routes } from "react-router-dom";

import Task from "./task";

export const TaskRoutes = () => {
  return (
    <Routes>
      <Route path=":taskId" element={<Task />} />
    </Routes>
  );
};
