/**
 * Return a random element from an array
 */
export function randomElement(array: any[]) {
  return array[Math.floor(Math.random() * array.length)];
}

/**
 * Return a shuffled copy of the input array
 * https://stackoverflow.com/a/46545530
 */
export function shuffleArray<T>(array: T[]): T[] {
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
}
