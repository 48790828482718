import fetchBuilder from "fetch-retry";
import Cookies from "js-cookie";
import { useMemo } from "react";

export interface ApiClient {
  get: (path: string, init?: RequestInit) => Promise<any>;
  post: (
    path: string,
    data?: Record<string, any>,
    options?: Record<string, any>
  ) => Promise<any>;
  patch: (
    path: string,
    data?: Record<string, any>,
    options?: Record<string, any>
  ) => Promise<any>;
  put: (
    path: string,
    data?: Record<string, any>,
    options?: Record<string, any>
  ) => Promise<any>;
  delete: (
    path: string,
    data?: Record<string, any>,
    options?: Record<string, any>
  ) => Promise<any>;
}

const API_ROOT = "/api";

export function useApiClient(): ApiClient {
  const csrfToken = useMemo(() => Cookies.get("csrftoken")!, []);

  return useMemo(() => {
    const createMutationMethod =
      (method: string) =>
      async (
        path: string,
        data?: Record<string, any>,
        options?: Record<string, any>
      ) => {
        const requestOptions: RequestInit = {
          method,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        };
        let fetcher: typeof fetch | ReturnType<typeof fetchBuilder> = fetch;
        if (data !== undefined) {
          requestOptions.body = JSON.stringify(data);
        }
        if (options?.retry !== undefined) {
          fetcher = fetchBuilder(fetch, options?.retry);
        }
        const response = await fetcher(API_ROOT + path, requestOptions);
        if (!response.ok) {
          // If the API return proper error messages as JSON, decode it and pass it along
          // to display user-friendly error message.
          // Otherwise, throw an Error with the response status text.
          if (response.headers.get("Content-Type") === "application/json") {
            return response.json().then((data) => Promise.reject(data));
          } else {
            throw new Error(response.statusText);
          }
        }
        return response.status === 204 ? null : response.json();
      };
    return {
      async get(path: string, init?: RequestInit) {
        const response = await fetch(API_ROOT + path, init);
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      },
      post: createMutationMethod("POST"),
      put: createMutationMethod("PUT"),
      patch: createMutationMethod("PATCH"),
      delete: createMutationMethod("DELETE"),
    };
  }, [csrfToken]);
}
