import { RefreshIcon } from "@heroicons/react/solid";
import { useCallback } from "react";
import ActionBox from "src/components/controls/action-box";
import FeedbackForm from "src/features/interactive-sxs-tasks/components/controls/feedback-form";
import { FeedbackFormData } from "src/types/models";

import useApi from "../../api";
import useInteractiveSideBySideStore from "../../hooks/use-interactive-sxs-store";
import useNextConversation from "../../hooks/use-next-conversation";
import NextConversationButton from "./next-conversation-button";

export default function NextConversationActionBox() {
  const api = useApi();
  const [getCurrentConversation, taskData] = useInteractiveSideBySideStore(
    (state) => [state.getCurrentConversation, state.taskData]
  );
  const handleNextConversation = useNextConversation();
  const currentConversation = getCurrentConversation()!;
  const fetchFeedbackForm = useCallback(
    () => api.feedbackForms.fetch(currentConversation.id),
    [api, currentConversation.id]
  );
  const submitFeedbackForm = useCallback(
    (data: FeedbackFormData) =>
      api.feedbackForms.submit(currentConversation.id, data),
    [api, currentConversation.id]
  );
  return (
    <div className="space-y-4">
      <FeedbackForm
        fetchFeedbackForm={fetchFeedbackForm}
        submitFeedbackForm={submitFeedbackForm}
      />
      <ActionBox
        icon={
          <RefreshIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
        }
        currentDialogueIndex={currentConversation!.index}
        numDialogues={taskData!.num_conversations}
        title="Conversation Completed 🚀"
        description="Please click the button below to continue."
        callToAction={
          <NextConversationButton onClick={handleNextConversation} />
        }
      />
    </div>
  );
}
