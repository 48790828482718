import { useMemo } from "react";
import { useApiClient } from "src/api";
import { withApiClient } from "src/api/utils";

import * as staticConversations from "./static-conversations";
export * from "./types";

export default function useApi() {
  const apiClient = useApiClient();
  return useMemo(
    () => ({
      staticConversations: withApiClient(staticConversations, apiClient),
    }),
    [apiClient]
  );
}
