import { ApiClient } from "src/api";

export function submit(
  apiClient: ApiClient,
  currentConversationId: number,
  selected: string,
  both: boolean,
  explanation: string | null
): Promise<void> {
  return apiClient.post("/static-tasks/log/", {
    conversation_id: currentConversationId,
    selected: selected,
    both,
    explanation,
  });
}
