import { BookmarkIcon } from "@heroicons/react/outline";
import { ChangeEventHandler } from "react";
import { parseForm, FormData } from "src/libs/forms";
import { Message } from "src/types/models";

import { AnnotationForm } from "../types";

type Props = {
  selectedMessage: Message;
  formContent: AnnotationForm;
  formData: FormData;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export default function DefaultStaticAnnotationForm(props: Props) {
  const { selectedMessage, formContent, formData, onChange } = props;
  return (
    formContent &&
    selectedMessage && (
      <div className="flex flex-col flex-1 w-full p-5 overflow-y-auto bg-white border rounded-b-lg shadow-sm">
        <div className="flex">
          <div className="flex-shrink-0">
            <BookmarkIcon
              className="w-5 h-5 text-indigo-500"
              aria-hidden="true"
            />
          </div>
          <div className="flex-1 ml-3">
            <h3 className="text-sm font-bold text-gray-800">Message Labels</h3>
          </div>
        </div>
        <div className="px-4">
          {
            <form
              className="mt-4 annotation-form"
              id={`message-form-${selectedMessage.id}`}
              key={`message-form-${selectedMessage.id}`}
              data-message-id={selectedMessage.id}>
              <div className="mb-4 font-bold text-md">
                {formContent.description}
              </div>
              {parseForm(formContent.value, {
                formData,
                onChange,
              })}
            </form>
          }
        </div>
      </div>
    )
  );
}
