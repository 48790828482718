import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";

export default function NewlineSafeReactMarkdown({
  children,
  className,
}: {
  children: string;
  className?: string;
}) {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={`${className} prose max-w-none prose-headings:mt-0`}
      components={{
        a({ children, ...props }) {
          const className = `underline ${props.className}`;
          return (
            <a {...props} className={className} target="_blank">
              {children}
            </a>
          );
        },
        // https://stackoverflow.com/questions/60332183/new-line-with-react-markdown/75343194#75343194
        p({ children, ...props }) {
          const className = `whitespace-pre-wrap ${props.className}`;
          return (
            <p {...props} className={className}>
              {children}
            </p>
          );
        },
        pre({ children, ...props }) {
          const className = `whitespace-pre-wrap ${props.className}`;
          return (
            <pre {...props} className={className}>
              {children}
            </pre>
          );
        },
      }}>
      {children}
    </ReactMarkdown>
  );
}
