import { ForwardedRef, forwardRef } from "react";
import { Message, MessagePreference, MessageType } from "src/types/models";
import classNames from "src/utils/class-names";
import resolveStaticUrl from "src/utils/static-url";

import MessageLabels from "./message-labels";

interface MessageProps {
  message: Message;
  isSelected: boolean;
  showId?: boolean;
}

const UserMessage = forwardRef(
  (props: MessageProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { message, isSelected, showId } = props;
    return (
      <div className="flex items-center justify-end flex-1 my-2" ref={ref}>
        <p
          title={showId ? `Message ID ${message.id}` : undefined}
          className={classNames(
            "px-4 py-2 text-sm font-medium  rounded-lg",
            isSelected ? "bg-yellow-200 text-black" : "bg-blue-500 text-white"
          )}>
          {message.text}
        </p>
      </div>
    );
  }
);

const BotMessage = forwardRef(
  (props: MessageProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { message, isSelected, showId } = props;
    return (
      <div className="flex flex-col w-full">
        <div className="flex items-center justify-start flex-1 my-4" ref={ref}>
          <img
            className="inline-block w-8 h-8 rounded-full"
            src={resolveStaticUrl("img/bot_icon.png")}
            alt="bot-icon"
          />
          <div className="ml-2">
            <p
              title={showId ? `Message ID ${message.id}` : undefined}
              className={classNames(
                "px-4 py-2 text-sm font-medium rounded-lg text-gray",
                isSelected ? "bg-yellow-200" : "bg-gray-100"
              )}>
              {message.text}
            </p>
          </div>
        </div>
        <MessageLabels message={message} />
      </div>
    );
  }
);

const BeatTheBotMessage = forwardRef(
  (
    props: MessageProps & { messageSelection: MessagePreference },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { message, messageSelection } = props;
    return (
      <div className="flex items-end justify-start flex-1 my-4" ref={ref}>
        <img
          className="inline-block w-8 h-8 rounded-full"
          src={resolveStaticUrl("img/bot_icon.png")}
          alt="bot-icon"
        />
        <div className="ml-2 max-w-[80%] space-y-4">
          <div className="space-y-2">
            <span className="inline-flex items-center w-36 rounded bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800">
              🤖 Bot Response
            </span>
            <p
              className={classNames(
                "px-4 py-2 text-sm font-medium rounded-lg text-gray",
                messageSelection === MessagePreference.BOT
                  ? "bg-green-100"
                  : "bg-gray-100"
              )}>
              {message.bot_response}
            </p>
          </div>
          <div className="space-y-2">
            <span className="inline-flex items-center w-36 rounded bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800">
              🧠 Human Response
            </span>
            <p
              className={classNames(
                "px-4 py-2 text-sm font-medium rounded-lg text-gray",
                messageSelection === MessagePreference.HUMAN
                  ? "bg-green-100"
                  : "bg-gray-100"
              )}>
              {message.text}
            </p>
            <p className="px-4 py-2 text-sm italic font-medium text-gray-500 bg-gray-100 rounded-lg text-gray">
              <span className="font-bold">Suggested response: </span>
              {message.bot_suggestion}
            </p>
          </div>
        </div>
      </div>
    );
  }
);

export interface Props {
  message: Message;
  isSelected: boolean;
  messageSelection?: MessagePreference;
}

const DialoguePreviewMessage = forwardRef(
  (
    { message, isSelected, messageSelection }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    if (
      message.type === MessageType.BOT_MESSAGE ||
      message.type === MessageType.HUMAN_BOT_MESSAGE
    ) {
      if (message.bot_response && messageSelection) {
        return (
          <BeatTheBotMessage
            message={message}
            messageSelection={messageSelection}
            isSelected={isSelected}
            ref={ref}
          />
        );
      } else {
        return (
          <BotMessage
            message={message}
            isSelected={isSelected}
            ref={ref}
            showId
          />
        );
      }
    } else {
      return (
        <UserMessage
          message={message}
          isSelected={isSelected}
          ref={ref}
          showId
        />
      );
    }
  }
);

export default DialoguePreviewMessage;
