import { ConversationState, TaskData, TaskState } from "./types";

export function getTaskState(taskData: TaskData | null): TaskState {
  if (taskData === null) {
    return TaskState.NEED_CONFIDENTIALITY_AGREEMENT;
  }

  const numCompletedConversations = taskData.convos.filter(
    (conversation) => conversation.state === ConversationState.COMPLETED
  ).length;

  return numCompletedConversations === taskData.ratings_per_time
    ? TaskState.FINISHED
    : TaskState.IN_PROGRESS;
}
