type Props = {
  heading: string;
};
export default function OptionsHeading(props: Props) {
  const { heading } = props;
  return (
    <div className="mt-4 text-sm text-gray-700">
      <span className="font-bold">{heading}</span>
    </div>
  );
}
