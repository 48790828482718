import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import { ChangeEventHandler } from "react";
import { AnnotationForm } from "src/features/annotation-tasks/types";
import { parseForm, FormData } from "src/libs/forms";
import { Message } from "src/types/models";
import classNames from "src/utils/class-names";
import { shallow } from "zustand/shallow";

import useTargetSentencesStore from "../hooks/use-target-sentences";
import { isMessageAnnotated } from "../utils";

type Props = {
  selectedMessage: Message;
  formContent: AnnotationForm;
  formData: FormData;
  requiredFields: Record<string, boolean> | null;
  onChange: ChangeEventHandler<HTMLInputElement>;
  targetSentences: string[];
  searchQuery?: string;
  searchResults?: string[];
};

function SectionTitle(props: { title: string }) {
  return <div className="font-bold text-md">{props.title}</div>;
}

export default function RetrievalStaticAnnotationForm(props: Props) {
  const {
    selectedMessage,
    formContent,
    formData,
    requiredFields,
    onChange,
    searchQuery,
    searchResults,
  } = props;
  const [
    nextTargetSentence,
    prevTargetSentence,
    isFirstTargetSentence,
    isLastTargetSentence,
  ] = useTargetSentencesStore(
    (state) => [
      state.nextTargetSentence,
      state.prevTargetSentence,
      state.isFirstTargetSentence,
      state.isLastTargetSentence,
    ],
    shallow
  );
  const isCurrentTargetSentenceAnnotated = isMessageAnnotated(
    formData,
    requiredFields
  );
  const canGoBack = !isFirstTargetSentence();
  const canGoForward =
    isCurrentTargetSentenceAnnotated && !isLastTargetSentence();
  const hasSearchResults =
    searchResults && searchQuery && searchResults.length > 0;
  return (
    <div className="flex flex-col flex-1 w-full p-5 overflow-y-auto bg-white border rounded-b-lg shadow-sm gap-y-4">
      {hasSearchResults && (
        <>
          <div className="font-bold text-lg">Corresponding Search Results:</div>
          <div className="flex flex-row">
            <SectionTitle title="Search Query:" />
            <div className="ml-2">{searchQuery}</div>
          </div>
          <div className="flex flex-col gap-y-2">
            <SectionTitle title="Search Results:" />
            {searchResults.map((result, index) => {
              return (
                <div key={`search-results-${index}`} className="flex flex-row">
                  <div className="text-md">{index + 1}.</div>
                  <div className="ml-2">{result}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {formContent && selectedMessage && (
        <form
          className="annotation-form"
          id={`message-form-${selectedMessage.id}`}
          key={`message-form-${selectedMessage.id}`}
          data-message-id={selectedMessage.id}>
          <SectionTitle title={formContent.description} />
          {parseForm(formContent.value, {
            formData,
            onChange,
          })}
        </form>
      )}
      <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
        <div className="-mt-px flex w-0 flex-1">
          <button
            onClick={() => {
              if (canGoBack) {
                prevTargetSentence();
              }
            }}
            className={classNames(
              "inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium  hover:border-gray-300 hover:text-gray-700",
              canGoBack
                ? "hover:cursor-pointer text-gray-500"
                : "hover:cursor-not-allowed text-gray-300"
            )}>
            <ArrowLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous Claim
          </button>
        </div>

        <div className="-mt-px flex w-0 flex-1 justify-end">
          <span
            onClick={() => {
              if (canGoForward) {
                nextTargetSentence();
              }
            }}
            className={classNames(
              "inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium hover:border-gray-300 hover:text-gray-700",
              canGoForward
                ? "hover:cursor-pointer text-gray-500"
                : "hover:cursor-not-allowed text-gray-300"
            )}>
            Next Claim
            <ArrowRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </div>
      </nav>
    </div>
  );
}
