import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LocalAnnotationData } from "src/features/annotation-tasks/types";
import {
  ModelOptionLabel,
  SelectOptionHandler,
} from "src/features/interactive-sxs-tasks/types";
import { updateRequiredFields } from "src/libs/forms";
import { BotResponseOption } from "src/types/models";
import { shuffleArray } from "src/utils/array";
import { shallow } from "zustand/shallow";

import useInteractiveSideBySideStore from "../../hooks/use-interactive-sxs-store";
import SxsActions from "../controls/sxs-actions";
import OptionTabs from "../retrieval/option-tabs";
import { getSearchResultsToDisplay } from "../retrieval/utils";

interface Props {
  handleSelectOption: SelectOptionHandler;
  handleRefreshOptions: () => Promise<void>;
}

// if true, the field is always required without any condition
type RequiredCondition = string | true;

function addRequiredField(
  label: ModelOptionLabel,
  setRequiredFields: Dispatch<
    SetStateAction<Record<ModelOptionLabel, Record<string, RequiredCondition>>>
  >
) {
  return (fieldName: string, condition?: string) => {
    setRequiredFields((prev) => {
      if (prev[label]?.[fieldName]) {
        return prev;
      }
      return {
        ...prev,
        [label]: {
          ...prev[label],
          [fieldName]: condition ?? true,
        },
      };
    });
  };
}

export default function RetrievalOptions(props: Props) {
  const { handleSelectOption, handleRefreshOptions } = props;
  const [options, hasEnoughOptions, taskData] = useInteractiveSideBySideStore(
    (state) => [state.options, state.hasEnoughOptions, state.taskData],
    shallow
  );
  const [retrievalAnnotationData, setRetrievalAnnotationData] = useState(
    {} as Record<ModelOptionLabel, LocalAnnotationData>
  );
  const [requiredFields, setRequiredFields] = useState(
    {} as Record<ModelOptionLabel, Record<string, RequiredCondition>>
  );
  const getOnChangeHandler = useCallback(
    (label: ModelOptionLabel) =>
      async (
        e: ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      ) => {
        const form = e.target.form!;
        const newAnnotationData = Object.fromEntries(new FormData(form));
        setRetrievalAnnotationData((prev) => ({
          ...prev,
          [label]: newAnnotationData,
        }));
      },
    []
  );
  const shuffledOptions = useMemo(() => shuffleArray(options), [options]);
  const optionsDisplayWithRetrieval = useMemo(
    () =>
      shuffledOptions.map((option: BotResponseOption, index) => {
        const label = taskData!.model_options[index];
        const searchResults = getSearchResultsToDisplay(option);
        const allQuestions = taskData!.retrieval_annotation_form!;
        const retrievalAnnotationForm = searchResults
          ? allQuestions.questions_for_response_with_search_results
          : allQuestions.questions_for_response_without_search_results;
        updateRequiredFields(
          retrievalAnnotationForm,
          addRequiredField(label, setRequiredFields)
        );
        return {
          label,
          text: option.text,
          annotationForm: retrievalAnnotationForm,
          searchResults,
        };
      }),
    [shuffledOptions, taskData]
  );
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    let newCanSubmit = true;
    for (const [label, fields] of Object.entries(requiredFields)) {
      const currentLabelAnnotation =
        retrievalAnnotationData[label as ModelOptionLabel];
      for (const [fieldName, requiredCondition] of Object.entries(fields)) {
        if (typeof requiredCondition === "string") {
          const [conditionField, conditionValue] = requiredCondition.split("=");
          if (
            currentLabelAnnotation?.[conditionField] === conditionValue &&
            currentLabelAnnotation?.[fieldName] === undefined
          ) {
            newCanSubmit = false;
            break;
          }
        } else if (
          requiredCondition === true &&
          currentLabelAnnotation?.[fieldName] === undefined
        ) {
          newCanSubmit = false;
          break;
        }
      }
    }
    if (newCanSubmit !== canSubmit) {
      setCanSubmit(newCanSubmit);
    }
  }, [requiredFields, retrievalAnnotationData, taskData, canSubmit]);

  if (!hasEnoughOptions()) {
    return null;
  }

  return (
    <div className="flex flex-col flex-1 my-4 gap-y-4">
      <div className="grid grid-cols-1 mt-4 gap-y-4 md:gap-x-4">
        <OptionTabs
          options={optionsDisplayWithRetrieval}
          retrievalAnnotationData={retrievalAnnotationData}
          getOnChangeHandler={getOnChangeHandler}
        />
      </div>
      {canSubmit && (
        <SxsActions
          shuffledOptions={shuffledOptions}
          select={(args) => {
            args.annotation_data = retrievalAnnotationData;
            handleSelectOption(args);
          }}
          handleRefreshOptions={handleRefreshOptions}
          variant="full-text"
        />
      )}
    </div>
  );
}
