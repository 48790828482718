import { Route, Routes } from "react-router-dom";

import InteractiveSxsTask from "./interactive-sxs-task";

export const InteractiveSxsTaskRoutes = () => {
  return (
    <Routes>
      <Route path=":taskId" element={<InteractiveSxsTask />} />
    </Routes>
  );
};
