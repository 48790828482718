import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useApiClient } from "src/api";
import useSessionStore from "src/features/auth/hooks/use-session-store";
import { shallow } from "zustand/shallow";

const DEFAULT_LOGIN_URL = "/auth/login/";

export function RequireAuthProvider({
  loginUrl = DEFAULT_LOGIN_URL,
  children,
}: {
  loginUrl?: string;
  children: JSX.Element;
}) {
  const [user, setUser] = useSessionStore(
    (state) => [state.user, state.setUser],
    shallow
  );
  const [isLoading, setIsLoading] = useSessionStore(
    (state) => [state.isLoading, state.setIsLoading],
    shallow
  );
  const apiClient = useApiClient();
  const location = useLocation();

  useEffect(() => {
    if (user === null && isLoading) {
      const fetchUser = async () => {
        await apiClient.get("/auth/user/").then((user) => {
          if (user.pk !== null) {
            setUser(user);
          }
          setIsLoading(false);
        });
      };
      fetchUser();
    }
  }, [user, isLoading, apiClient, setUser, setIsLoading]);

  if (isLoading) {
    return null;
  }

  if (user === null) {
    return <Navigate to={loginUrl} state={{ from: location }} replace />;
  }

  return children;
}
