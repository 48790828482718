import NewlineSafeReactMarkdown from "src/components/text/newline-safe-react-markdown";

type Props = {
  onClick: () => void;
  label: string;
  text: string;
};

export default function OptionButtonFullText(props: Props) {
  const { onClick, text, label } = props;
  return (
    <div className="flex">
      <span className="bg-indigo-100 inline-flex items-center rounded-tl rounded-bl px-2 py-0.5 text-xs text-indigo-800">
        {label}
      </span>
      <button
        className="flex-1 bg-gray-50 text-sm rounded-tr rounded-br text-gray px-4 py-2 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
        onClick={onClick}>
        <NewlineSafeReactMarkdown className="text-left">
          {text}
        </NewlineSafeReactMarkdown>
      </button>
    </div>
  );
}
