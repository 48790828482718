import {
  CheckCircleIcon,
  DotsCircleHorizontalIcon,
  XIcon,
} from "@heroicons/react/solid";
import React from "react";
import ActionBox from "src/components/controls/action-box";

interface DialogueCollectionActionBoxProps {
  isTaskCompleted: boolean;
  currentDialogueIndex: number;
  numDialogues: number;
  prolificRedirectUrl: string;
  isUserTimeOut?: boolean;
  isRequestingNewDialogue?: boolean;
  handleNewDialogue?: (e: React.MouseEvent) => void;
  hideProgressTracker?: boolean;
}

export default function DialogueCollectionActionBox({
  isTaskCompleted,
  currentDialogueIndex,
  numDialogues,
  prolificRedirectUrl,
  isUserTimeOut,
  isRequestingNewDialogue,
  handleNewDialogue,
  hideProgressTracker,
  children,
}: React.PropsWithChildren<DialogueCollectionActionBoxProps>) {
  if (isUserTimeOut) {
    return (
      <ActionBox
        icon={<XIcon className="w-5 h-5 text-red-400" aria-hidden="true" />}
        numDialogues={hideProgressTracker ? undefined : numDialogues}
        currentDialogueIndex={currentDialogueIndex}
        title="Timed out!"
        description="You have been timed out due to inactivity."
      />
    );
  }

  if (isTaskCompleted) {
    return (
      <ActionBox
        icon={
          <CheckCircleIcon
            className="w-5 h-5 text-green-400"
            aria-hidden="true"
          />
        }
        numDialogues={hideProgressTracker ? undefined : numDialogues}
        currentDialogueIndex={currentDialogueIndex}
        title="Last Conversation Completed 🏆"
        description="Thank you for participating in our study. Please click the link below to go back to Prolific."
        callToAction={
          <a
            href={prolificRedirectUrl}
            className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Go back to Prolific
          </a>
        }>
        {children}
      </ActionBox>
    );
  }
  return (
    <ActionBox
      icon={
        <DotsCircleHorizontalIcon
          className="w-5 h-5 text-indigo-500"
          aria-hidden="true"
        />
      }
      numDialogues={hideProgressTracker ? undefined : numDialogues}
      currentDialogueIndex={currentDialogueIndex}
      title="Conversation Completed 🏆"
      description="Please click the button bellow to start the new conversation"
      callToAction={
        handleNewDialogue ? (
          <button
            className="inline-flex justify-center w-full px-4 py-2 my-4 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm text-gray hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={isRequestingNewDialogue}
            onClick={handleNewDialogue}>
            New Conversation
          </button>
        ) : null
      }>
      {children}
    </ActionBox>
  );
}
