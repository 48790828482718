import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiClient } from "src/api";
import ConfidentialityAgreementModal from "src/components/modal/confidentiality-agreement-modal";
import StartTaskError from "src/components/task/start-task-error";
import {
  AnnotationTask,
  StaticAnnotationTask,
} from "src/features/annotation-tasks";
import { isAnnotationTask } from "src/features/annotation-tasks/utils";
import HumanToBotCollectionTask from "src/features/human-to-bot-tasks";
import QuizPage, { Quiz, QuizScore } from "src/features/quizzes";
import useDialoguesStore from "src/hooks/use-dialogues-store";
import {
  ActivityState,
  DialogueActivityType,
  DialogueState,
  Scenario,
  SerializedPolicyBatchResponse,
  Task,
  TaskType,
} from "src/types/models";
import { shallow } from "zustand/shallow";

enum StartTaskState {
  NEED_CONFIDENTIALITY_AGREEMENT,
  NOT_STARTED,
  STARTING,
  IN_PROGRESS,
  ERROR,
}

type StartTaskQuizData = Quiz & {
  next_question_index: number;
  scores: QuizScore | null;
};

export default function TaskPage() {
  const apiClient = useApiClient();
  const { taskId } = useParams();
  const [task, setTask] = useState<Task | null>(null);
  const [startTaskError, setStartTaskError] = useState<string | null>(null);
  const [startTaskState, setStartTaskState] = useState<StartTaskState>(
    StartTaskState.NEED_CONFIDENTIALITY_AGREEMENT
  );
  const [scenario, setScenario] = useState<Scenario | null>(null);
  const [quiz, setQuiz] = useState<StartTaskQuizData | null>(null);
  const [policies, setPolicies] = useState<SerializedPolicyBatchResponse>([]);
  const [
    currentDialogue,
    setCurrentDialogueIndex,
    setDialogues,
    setCurrentActivity,
    setDialogueActivitiesStates,
  ] = useDialoguesStore(
    (state) => [
      state.currentDialogue,
      state.setCurrentDialogueIndex,
      state.setDialogues,
      state.setCurrentActivity,
      state.setDialogueActivitiesStates,
    ],
    shallow
  );

  useEffect(() => {
    if (startTaskState !== StartTaskState.NOT_STARTED) {
      return;
    }
    const startTask = async () => {
      setStartTaskState(StartTaskState.STARTING);
      try {
        const { task, data } = await apiClient.post("/tasks/start/", {
          task_id: taskId,
        });
        const {
          dialogues,
          dialogue_activities_states,
          scenario,
          quiz,
          policies,
        } = data;
        setTask(task);
        setDialogues(dialogues);
        setDialogueActivitiesStates(dialogue_activities_states);
        setScenario(scenario);
        setPolicies(policies);
        let currentDialogueIndex = 0;
        for (const dialogue of dialogues) {
          const isDialougeCompleted = isAnnotationTask(task)
            ? dialogue_activities_states[dialogue.id] ===
              ActivityState.COMPLETED
            : dialogue.state === DialogueState.COMPLETED;
          if (isDialougeCompleted) {
            currentDialogueIndex++;
          } else {
            break;
          }
        }
        if (quiz) {
          setQuiz(quiz);
        }
        setCurrentDialogueIndex(
          Math.min(currentDialogueIndex, dialogues.length - 1)
        );
        setStartTaskState(StartTaskState.IN_PROGRESS);
      } catch (e: any) {
        setStartTaskState(StartTaskState.ERROR);
        setStartTaskError(e);
      }
    };
    startTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTaskState]);

  useEffect(() => {
    if (task && currentDialogue) {
      const createActivity = async () => {
        await apiClient
          .post("/dialogue-activities/", {
            task: task.id,
            dialogue: currentDialogue.id,
            type: isAnnotationTask(task)
              ? DialogueActivityType.ANNOTATION
              : DialogueActivityType.COLLECTION,
          })
          .then(setCurrentActivity);
      };
      createActivity();
    }
  }, [task, currentDialogue, setCurrentActivity, apiClient]);

  if (startTaskState === StartTaskState.NEED_CONFIDENTIALITY_AGREEMENT) {
    return (
      <ConfidentialityAgreementModal
        onAgree={() => {
          setStartTaskState(StartTaskState.NOT_STARTED);
        }}
      />
    );
  }

  if (startTaskState === StartTaskState.ERROR) {
    return <StartTaskError error={startTaskError!} taskId={taskId!} />;
  }

  if (!task) {
    return null;
  }

  switch (task.type) {
    case TaskType.ANNOTATION:
    case TaskType.STATIC_ANNOTATION:
      const Component =
        task.type === TaskType.ANNOTATION
          ? AnnotationTask
          : StaticAnnotationTask;
      return currentDialogue ? (
        <Component
          task={task}
          key={`${task.id}-${currentDialogue!.id}`}
          policies={policies}
        />
      ) : (
        <StartTaskError
          error="All conversations have been fully assigned for labelling."
          resolution={
            task.prolific_redirect_url
              ? "Please return the study in Prolific."
              : "Please try again later."
          }
        />
      );
    case TaskType.HUMAN_TO_BOT_COLLECTION:
      return (
        <HumanToBotCollectionTask
          task={task}
          initialScenario={scenario}
          key={`${task.id}`}
          policies={policies}
        />
      );
    case TaskType.QUIZ:
      if (!quiz) {
        throw new Error("Quiz is not set");
      }
      return (
        <QuizPage
          task={task}
          quiz={quiz}
          nextQuestionIndex={quiz.next_question_index}
          initialQuizScores={quiz.scores}
        />
      );
    default:
      return null;
  }
}
