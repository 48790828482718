import { useCallback, useState } from "react";
import { BotResponseOption, MessageType } from "src/types/models";
import { shallow } from "zustand/shallow";

import useApi from "../api";
import { StaticConversation, TaskState } from "../types";
import useStaticSxsStore from "./use-static-sxs-store";

export default function useSelectOption() {
  const api = useApi();
  const [currentChoice, setCurrentChoice] = useState<{
    option: BotResponseOption;
    both: boolean;
  } | null>(null);
  const [showExplanation, setShowExplanation] = useState<boolean>(false);
  const [
    taskData,
    getCurrentConversationIndex,
    addMessage,
    addConversation,
    endConversation,
    setTaskState,
  ] = useStaticSxsStore(
    (state) => [
      state.taskData!,
      state.getCurrentConversationIndex,
      state.addMessage,
      state.addConversation,
      state.endConversation,
      state.setTaskState,
    ],
    shallow
  );
  const currentConversationIndex = getCurrentConversationIndex()!;
  const currentConversation = taskData.convos[currentConversationIndex];

  const advance = useCallback(async () => {
    if (taskData.use_adhoc_assignment_strategy) {
      setTaskState(TaskState.LOADING_NEXT_CONVERSATION);
      const nextConversation = await api.conversations.next(taskData.uuid);
      if (nextConversation) {
        addConversation(new StaticConversation(nextConversation));
      }
    }
    endConversation(currentConversation.id);
  }, [
    setTaskState,
    addConversation,
    endConversation,
    currentConversation,
    taskData,
    api,
  ]);

  const selectOption = useCallback(
    async (option: BotResponseOption, both: boolean) => {
      if (taskData.ask_explanation) {
        setCurrentChoice({ option, both });
        addMessage({
          text: option.text,
          type: MessageType.BOT_MESSAGE,
          id: currentConversation.context.length,
        });
        setShowExplanation(true);
      } else {
        const selected = both ? "both" : option.model;
        await api.ratings.submit(currentConversation.id, selected, both, null);
        await advance();
      }
    },
    [taskData, currentConversation, setCurrentChoice, addMessage, advance, api]
  );

  const submitExplanation = useCallback(
    async (explanation: string) => {
      const choice = currentChoice!;
      const selected = choice.both ? "both" : choice.option.model;
      await api.ratings.submit(
        currentConversation.id,
        selected,
        choice.both,
        explanation
      );
      setShowExplanation(false);
      setCurrentChoice(null);
      await advance();
    },
    [currentChoice, currentConversation, advance, api]
  );

  return { selectOption, submitExplanation, showExplanation };
}
