type Props = {
  url: string;
};

export default function ProlificRedirectUrl(props: Props) {
  const { url } = props;
  return (
    <a
      href={url}
      className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      Go back to Prolific
    </a>
  );
}
