import { useApiClient } from "src/api";
import useSWR, { useSWRConfig } from "swr";

import {
  APIEntry,
  APIConversation,
  Entry,
  Reply,
  Conversation,
  ReplyModelInfo,
} from "./models";

const apiEntryToEntry =
  (modelInfo: ReplyModelInfo) =>
  (entry: APIEntry): Entry => {
    let replies: Reply[] = [];
    const rerolls = entry.rerolls.map((r) => [
      { label: "A", text: r.replyA, model: modelInfo.replyA, selected: false },
      { label: "B", text: r.replyB, model: modelInfo.replyB, selected: false },
      { label: "C", text: r.replyC, model: modelInfo.replyC, selected: false },
      { label: "D", text: r.replyD, model: modelInfo.replyD, selected: false },
    ]);

    if (entry.alternative) {
      replies = [
        {
          label: "✍️",
          text: entry.alternative,
          model: modelInfo.replyA,
          selected: true,
        },
      ];
      // If there's an alternative text, the entries are also, functionally, an ignored reroll.
      rerolls.push([
        {
          label: "A",
          text: entry.replyA,
          model: modelInfo.replyA,
          selected: false,
        },
        {
          label: "B",
          text: entry.replyB,
          model: modelInfo.replyB,
          selected: false,
        },
        {
          label: "C",
          text: entry.replyC,
          model: modelInfo.replyC,
          selected: false,
        },
        {
          label: "D",
          text: entry.replyD,
          model: modelInfo.replyD,
          selected: false,
        },
      ]);
    } else {
      replies = [
        {
          label: "A",
          text: entry.replyA,
          model: modelInfo.replyA,
          selected: false,
        },
        {
          label: "B",
          text: entry.replyB,
          model: modelInfo.replyB,
          selected: false,
        },
        {
          label: "C",
          text: entry.replyC,
          model: modelInfo.replyC,
          selected: false,
        },
        {
          label: "D",
          text: entry.replyD,
          model: modelInfo.replyD,
          selected: false,
        },
      ];
    }

    return {
      id: entry.id,
      conversationId: entry.conversation_id,
      text: entry.text,
      bookmark: entry.bookmark.length > 0 ? entry.bookmark[0] : undefined,
      replies: replies
        .filter((r) => r.text)
        .map((r) => ({
          ...r,
          selected: r.selected || entry.selected === r.label,
        })),

      rerolls: rerolls.map((r) => r.filter((e) => e.text)),
    };
  };

export const useConversation = (conversationId: number) => {
  const apiClient = useApiClient();
  const key = `/isxs/conversation/${conversationId}/`;
  const { data, error } = useSWR<APIConversation>(key, apiClient.get, {
    revalidateOnFocus: false,
  });

  if (error) {
    console.error(error);
  }

  const modelInfo = data?.model_info ?? defaultModelInfo;
  const convo: Conversation = {
    modelInfo,
    entries: data?.entries?.map(apiEntryToEntry(modelInfo)) ?? [],
    scenarioCheckpoints: data?.scenario_checkpoints ?? {},
  };

  return {
    convo,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useBookmarks = (conversationId: number, entryId: number) => {
  const apiClient = useApiClient();
  const { mutate } = useSWRConfig();
  const url = `/isxs/conversation/${conversationId}/entries/${entryId}/bookmark`;
  const setBookmark = async (note?: string) => {
    await apiClient.post(url, { note: note ?? "" });
    await mutate(`/isxs/conversation/${conversationId}/`);
  };
  const clearBookmark = async () => {
    await apiClient.delete(url);
    await mutate(`/isxs/conversation/${conversationId}/`);
  };
  return { setBookmark, clearBookmark };
};

const defaultModelInfo: ReplyModelInfo = {
  replyA: "",
  replyB: "",
  replyC: "",
  replyD: "",
};
