import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useApiClient } from "src/api";
import { Scenario } from "src/types/models";
import classNames from "src/utils/class-names";

type Props = {
  selectScenario: (scenario: Scenario) => Promise<void>;
  taskUUID?: string;
  limitTagIds?: number[];
};

export default function ScenariosSelectionListBox({
  selectScenario,
  taskUUID,
  limitTagIds,
}: Props) {
  const apiClient = useApiClient();
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const [selected, setSelected] = useState<Scenario | null>(null);

  useEffect(() => {
    const fetchScenarios = async () => {
      const queryParams = [];
      if (taskUUID) {
        queryParams.push(`task_id=${taskUUID}`);
      }
      if (limitTagIds) {
        for (const tagId of limitTagIds) {
          queryParams.push(`limit_tag_id=${tagId}`);
        }
      }
      const url =
        "/scenarios/" +
        (queryParams.length > 0 ? `?${queryParams.join("&")}` : "");
      const results = await apiClient.get(url);
      setScenarios(results);
      setSelected(results[0]);
    };
    fetchScenarios();
  }, [apiClient, taskUUID, limitTagIds]);

  return (
    <div className="pt-6 mt-6 space-y-6">
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-xl font-medium text-gray-900">
              Please select a scenario to continue
            </Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                {selected && (
                  <span className="block truncate">{selected.name}</span>
                )}
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  {open ? (
                    <ChevronUpIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronDownIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Listbox.Options className="absolute bottom-[100%] z-10 w-full px-0 py-1 mt-1 overflow-auto text-base bg-gray-50 rounded-md shadow-xl max-h-500 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {scenarios.map((scenario) => (
                    <Listbox.Option
                      key={scenario.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-indigo-500" : "text-gray-900",
                          "cursor-default select-none p-4 text-sm"
                        )
                      }
                      value={scenario}>
                      {({ selected, active }) => (
                        <div className="flex flex-col">
                          <div className="flex justify-between">
                            <p
                              className={
                                selected ? "font-semibold" : "font-normal"
                              }>
                              {scenario.name}
                              {scenario.topic && (
                                <span className="ml-2 inline-flex items-center rounded-md bg-purple-100 px-2.5 py-0.5 text-sm font-medium text-purple-800">
                                  {scenario.topic.name}
                                </span>
                              )}
                            </p>
                            {selected ? (
                              <span
                                className={
                                  active ? "text-white" : "text-indigo-500"
                                }>
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <button
        className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        disabled={!selected}
        onClick={() => selectScenario(selected!)}>
        Submit
      </button>
    </div>
  );
}
