import { CheckIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { parseForm } from "src/libs/forms";
import {
  FeedbackForm as FeedbackFormType,
  FeedbackFormData,
} from "src/types/models";

interface FeedbackFormProps {
  fetchFeedbackForm: () => Promise<{
    form: FeedbackFormType;
    data: FeedbackFormData;
  }>;
  submitFeedbackForm: (feedbackFormData: FeedbackFormData) => Promise<void>;
}

export default function FeedbackForm({
  fetchFeedbackForm,
  submitFeedbackForm,
}: FeedbackFormProps) {
  const [feedbackForm, setFeedbackForm] = useState<FeedbackFormType | null>(
    null
  );
  const [feedbackData, setFeedbackData] = useState<FeedbackFormData | null>(
    null
  );
  const isFormSubmitted = !!feedbackData;

  useEffect(() => {
    if (!feedbackForm) {
      const getFeedbackForm = async () => {
        const { form, data } = await fetchFeedbackForm();
        setFeedbackForm(form);
        setFeedbackData(data);
      };
      getFeedbackForm();
    }
  }, [fetchFeedbackForm, feedbackForm]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = Object.fromEntries(new FormData(form));
    await submitFeedbackForm(formData);
    setFeedbackData(formData);
  };

  if (!feedbackForm) {
    return null;
  }

  return (
    <div className="p-4 mt-4 bg-gray-100 rounded-sm shadow-sm">
      <div className="flex justify-between flex-1">
        <div className="text-xl text-gray-900">Feedback</div>
        {isFormSubmitted && (
          <span className="flex items-center" role="status">
            <CheckIcon className="w-4 h-4 text-indigo-500" aria-hidden="true" />
            <span className="text-xs text-gray-700">Saved</span>
          </span>
        )}
      </div>
      <form className="flex flex-col mt-4 text-sm" onSubmit={handleSubmit}>
        <fieldset disabled={isFormSubmitted}>
          {parseForm(feedbackForm.value, {
            formData: feedbackData,
            isDisabled: isFormSubmitted,
          })}
          {!isFormSubmitted && (
            <div className="flex mt-4">
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Submit
              </button>
            </div>
          )}
        </fieldset>
      </form>
    </div>
  );
}
